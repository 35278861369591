import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useQuery } from '@apollo/client'
import {
  Form,
  Input,
  DatePicker,
  Checkbox,
  Radio,
  Alert,
  Typography,
  Card
} from 'antd'
import FormContainer from '../../../FormContainer'
import FormItem from 'antd/lib/form/FormItem'
import Title from 'antd/lib/typography/Title'
import { PRINT_FORM3457H } from '../../../../operations/mutations/forms/3457H'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3457H_VERSION } from '../../../../operations/queries/forms/3457H'
import FormCollaboration from '../../../FormCollaboration'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import StudentFormActions from '../StudentFormActions'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId,
      membersNotDiscussed: [
        formFieldValues.membersNotDiscussed1 || '',
        formFieldValues.membersNotDiscussed2 || '',
        formFieldValues.membersNotDiscussed3 || '',
        formFieldValues.membersNotDiscussed4 || ''
      ],
      membersDiscussed: [
        formFieldValues.membersDiscussed1 || '',
        formFieldValues.membersDiscussed2 || '',
        formFieldValues.membersDiscussed3 || '',
        formFieldValues.membersDiscussed4 || ''
      ]
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'communicationMode':
        update[fieldName] = fieldValue.split(',')
        break
      case 'parentSignatureDate':
      case 'meetingDateAndTime':
      case 'formDate':
      case 'authSignatureDate':
        update[fieldName] = dayjs(fieldValue)
        break
      case 'agreeMemberNotDiscussed':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setAgreeMemberNotDiscussed(update[fieldName])
        break
      case 'agreeMemberDiscussed':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setAgreeMemberDiscussed(update[fieldName])
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const communicationModes = [
    { label: 'Met in person', value: 'in-person' },
    { label: 'Spoke on the phone', value: 'telephone' },
    { label: 'Exchanged e-mails', value: 'email' },
    { label: 'Exchanged faxes', value: 'faxes' }
  ]

  const membersNotDiscussed = [
    { label: 'Member Name and Area 1', name: 'membersNotDiscussed1' },
    { label: 'Member Name and Area 2', name: 'membersNotDiscussed2' },
    { label: 'Member Name and Area 3', name: 'membersNotDiscussed3' },
    { label: 'Member Name and Area 4', name: 'membersNotDiscussed4' }
  ]

  const membersDiscussed = [
    { label: 'Member Name and Area 1', name: 'membersDiscussed1' },
    { label: 'Member Name and Area 2', name: 'membersDiscussed2' },
    { label: 'Member Name and Area 3', name: 'membersDiscussed3' },
    { label: 'Member Name and Area 4', name: 'membersDiscussed4' }
  ]

  const [agreeMemberNotDiscussed, setAgreeMemberNotDiscussed] = useState(
    data?.agreeMemberNotDiscussed
  )
  const [agreeMemberDiscussed, setAgreeMemberDiscussed] = useState(
    data?.agreeMemberDiscussed
  )

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457H'
            name='studentForm3457H'
            initialValues={data}
            layout='vertical'
            preserve
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              PARENT/GUARDIAN EXCUSAL OF AN INDIVIDUALIZED EDUCATION PROGRAM
              TEAM MEMBER
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>
            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />

              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentParent1SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1}
                error={itemErrors.parent1}
              />
              <StudentParent2SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2}
                error={itemErrors.parent2}
              />
            </Card>

            <Card
              title='Conference Information'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Meeting Date and Time'
                name='meetingDateAndTime'
                validateStatus={itemErrors.meetingDateAndTime ? 'error' : ''}
                help={itemErrors.meetingDateAndTime}
                {...validationProps('meetingDateAndTime')}
              >
                <DatePicker
                  disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 20, 21, 22, 23]}
                  showTime={{
                    hideDisabledOptions: true
                  }}
                  use12Hours
                  minuteStep={5}
                  format='YYYY-MM-DD / hh:mm A'
                  {...handleFocusEvents}
                  disabled={formDisabledState.meetingDateAndTime}
                />
              </FormItem>
              <Form.Item
                label='Past Meeting Communication Type'
                name='communicationMode'
                {...validationProps('communicationMode')}
              >
                <Checkbox.Group
                  disabled={formDisabledState.communicationMode}
                  options={communicationModes}
                  onChange={checkedValues => {
                    const e = {
                      target: {
                        name: 'communicationMode',
                        value: checkedValues.toString()
                      }
                    }
                    handleMouseEvents.onChange(e)
                  }}
                  defaultValue={[]}
                />
              </Form.Item>
            </Card>

            <Card
              title='Content area of excused member not discussed at the meeting'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='The school district and parent/guardian agree the following member(s) is/are not required to attend the IEP meeting in whole or in part
                because the individual’s area of curriculum, content or related service will not be discussed or modified.'
                name='agreeMemberNotDiscussed'
                {...validationProps('agreeMemberNotDiscussed')}
              >
                <Radio.Group
                  onChange={e => {
                    setAgreeMemberNotDiscussed(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.agreeMemberNotDiscussed}
                  name='agreeMemberNotDiscussed'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>N/A</Radio.Button>
                </Radio.Group>
              </FormItem>

              {membersNotDiscussed.map(({ label, name }, key) => (
                <Form.Item
                  key={key}
                  label={label}
                  name={name}
                  hidden={!agreeMemberNotDiscussed}
                  {...validationProps(name)}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState[name]}
                  />
                </Form.Item>
              ))}
            </Card>

            <Card
              title='Content area of excused member discussed at the meeting'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='The school district and parent/guardian agree the following member(s) may be excused from attending the IEP meeting in whole or
                in part, when the meeting involves a modification to or discussion of the member’s area of the curriculum or related services, if the
                member submits input into the IEP in writing to the parent and to the teacher prior to the meeting.'
                name='agreeMemberDiscussed'
                {...validationProps('agreeMemberDiscussed')}
              >
                <Radio.Group
                  onChange={e => {
                    setAgreeMemberDiscussed(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.agreeMemberDiscussed}
                  name='agreeMemberDiscussed'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>N/A</Radio.Button>
                </Radio.Group>
              </FormItem>
              {membersDiscussed.map(({ label, name }, key) => (
                <Form.Item
                  key={key}
                  label={label}
                  name={name}
                  hidden={!agreeMemberDiscussed}
                  {...validationProps(name)}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState[name]}
                  />
                </Form.Item>
              ))}
            </Card>

            <Card
              title='Details'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Parent / Guardian signature date'
                name='parentSignatureDate'
                {...validationProps('parentSignatureDate', formDisabledState)}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.parentSignatureDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>
              <Form.Item
                label='Authorized School Personnel signature date'
                name='authSignatureDate'
                {...validationProps('authSignatureDate', formDisabledState)}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.authSignatureDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>
            </Card>

            <Card
              title='Contact Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Name'
                name='documentContactName'
                {...validationProps('documentContactName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactName}
                />
              </Form.Item>
              <Form.Item
                label='Title'
                name='documentContactTitle'
                {...validationProps('documentContactTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactTitle}
                />
              </Form.Item>
              <Form.Item
                label='Phone'
                name='documentContactPhone'
                {...validationProps('documentContactPhone')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactPhone}
                />
              </Form.Item>
            </Card>
            <Card
              title='Signee Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Name'
                name='signatureName'
                {...validationProps('signatureName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.signatureName}
                />
              </Form.Item>
              <Form.Item
                label='Title'
                name='signatureTitle'
                {...validationProps('signatureTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.signatureTitle}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3457H}
              extractDataProp='printStudentForm3457H'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3457H = () => {
  // get current form information
  const {
    currentStudentInfo,
    currentEditableForm,
    studentMeetingId,
    meetingDate
  } = useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3457H_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3457H: form } = versionInfo
    const { student } = studentInfo
    const { parent1FullName, parent2FullName, dateOfBirth } = student
    const {
      formDate,
      studentFullName,
      studentDob,
      parent1,
      parent2,
      parentSignatureDate,
      authSignatureDate,
      meetingDateAndTime,
      membersDiscussed,
      membersNotDiscussed
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? dayjs(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
          ? dayjs(dateOfBirth).format('YYYY-MM-DD')
          : '',
      meetingDateAndTime: meetingDateAndTime
        ? dayjs(meetingDateAndTime)
        : dayjs(meetingDate),
      parent1: parent1 || parent1FullName || '',
      parent2: parent2 || parent2FullName || '',
      parentSignatureDate: parentSignatureDate
        ? dayjs(parentSignatureDate)
        : dayjs(Date.now()),
      authSignatureDate: authSignatureDate
        ? dayjs(authSignatureDate)
        : dayjs(Date.now()),
      membersDiscussed1: membersDiscussed[0] || '',
      membersDiscussed2: membersDiscussed[1] || '',
      membersDiscussed3: membersDiscussed[2] || '',
      membersDiscussed4: membersDiscussed[3] || '',
      membersNotDiscussed1: membersNotDiscussed[0] || '',
      membersNotDiscussed2: membersNotDiscussed[1] || '',
      membersNotDiscussed3: membersNotDiscussed[2] || '',
      membersNotDiscussed4: membersNotDiscussed[3] || ''
    }

    return initialValues
  }

  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}

export default StudentForm3457H
