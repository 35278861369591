import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454G_VERSION } from '../../../../operations/queries/forms/3454G'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Typography, Tooltip } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454G } from '../../../../operations/mutations/forms/3454G'
import Editor from '../../../elements/common/Editor'
import LookupSelect from '../LookupSelect'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId,
      studentStrengths: formFieldValues.studentStrengths.join('|')
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      case 'linguisticChanged':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      case 'studentStrengths':
        update[fieldName] = fieldValue.split(',')
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const formFields = [
    {
      label: 'Parental Educational Concerns/Input',
      name: 'parentalEducationConcerns'
    },
    {
      label:
        'Student’s Present Levels of Academic Performance (Include strengths and areas that need improvement)',
      name: 'presentLevelAcademicAchievement'
    },
    {
      label:
        'Student’s Present Levels of Functional/Developmental Performance (Include strengths and areas that need improvement)',
      name: 'presentLevelFunctionalPerformance'
    },
    {
      label:
        'The Results of the Initial or Most Recent Evaluation of the Student',
      name: 'evaluationResults'
    },

    {
      label:
        'Describe the effect of this individual’s disability on involvement and progress in the general education curriculum and the functional implications of the student’s skills. (For a preschool child: Describe the effect of this individual’s disability on involvement in appropriate activities; By age 14 1/2: Describe the effect of this individual’s disability on the pursuit of post-secondary expectations (living, learning, and working).)',
      name: 'disabilityEffects'
    }
  ]

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454G'
            name='studentForm3454G'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Tooltip title='ISBE Form 3454G' placement='bottom'>
              <Title level={4} className='center'>
                PRESENT LEVELS OF ACADEMIC ACHIEVEMENT AND FUNCTIONAL
                PERFORMANCE
              </Title>
            </Tooltip>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>

            <Card
              style={{
                marginBottom: 30
              }}
            >
              <LookupSelect
                label='Student’s Strengths'
                name='studentStrengths'
                type='Strengths'
                placeholder='Please select'
                validation={validationProps('studentStrengths')}
                onFocus={event => {
                  const e = {
                    target: {
                      id: event.target.id,
                      name: 'studentStrengths'
                    }
                  }
                  handleFocusEvents.onFocus(e)
                }}
                onBlur={checkedValues => {
                  const e = {
                    target: {
                      name: 'studentStrengths'
                    }
                  }
                  handleMouseEvents.onChange(e)
                }}
              />
            </Card>

            {formFields.map(({ name, label }, index) => (
              <Card
                key={index}
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item label={label} name={name} {...validationProps(name)}>
                  <Editor
                    formField={name}
                    disabled={formDisabledState[name]}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>
            ))}

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454G}
              extractDataProp='printStudentForm3454G'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454G = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454G_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454G: form } = versionInfo
    const { student } = studentInfo
    const { formDate, studentFullName } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentStrengths: form.studentStrengths
        ? form.studentStrengths.split('|')
        : []
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454G
