import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454O_VERSION } from '../../../../operations/queries/forms/3454O'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import {
  Alert,
  Card,
  DatePicker,
  Form,
  Typography,
  Input,
  Radio,
  Checkbox,
  Space
} from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454O } from '../../../../operations/mutations/forms/3454O'
import Editor from '../../../elements/common/Editor'
import FormItem from 'antd/lib/form/FormItem'
import LookupSelect from '../LookupSelect'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    console.log('getformfieldvalues', formFieldValues.classroomAssessments)
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId,
      classroomAssessments: formFieldValues.classroomAssessments
        ? formFieldValues.classroomAssessments.join('|')
        : '',
      districtAssessments: formFieldValues.districtAssessments
        ? formFieldValues.districtAssessments.join('|')
        : '',
      collegeAssessments: formFieldValues.collegeAssessments
        ? formFieldValues.collegeAssessments.join('|')
        : '',
      scienceAssessment: formFieldValues.scienceAssessment
        ? formFieldValues.scienceAssessment.join('|')
        : '',
      physicalFitnessAssessment: formFieldValues.physicalFitnessAssessment
        ? formFieldValues.physicalFitnessAssessment.join('|')
        : '',
      accessAlternativeAccess: formFieldValues.accessAlternativeAccess
        ? formFieldValues.accessAlternativeAccess.join('|')
        : ''
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      case 'stateAcademicAssessmentNotAdministered':
        update[fieldName] = fieldValue.toString()
        break
      case 'participateClassroomAssessments':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      case 'districtAdministerAssessments':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      case 'districtAdministerAssessmentInGrade':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setDistrictAdministerAssessmentInGrade(update[fieldName])
        break
      case 'physicalFitnessAssessmentFitnessGram':
        setPhysicalFitnessAssessmentFitnessGramExplain(isExplain(fieldValue))
        update[fieldName] = fieldValue === 'null' ? null : fieldValue
        break
      case 'physicalFitnessAssessmentBrockport':
        setPhysicalFitnessAssessmentBrockportExplain(isExplain(fieldValue))
        update[fieldName] = fieldValue === 'null' ? null : fieldValue
        break
      case 'kindergartenDevelopment':
        setKindergartenDevelopmentSubset(isSubset(fieldValue))
        setKindergartenDevelopmentSubset2(isSubset2(fieldValue))
        update[fieldName] = fieldValue === 'null' ? null : fieldValue
        break
      case 'englishLearner':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setEnglishLearner(update[fieldName])
        break
      case 'classroomAssessments':
      case 'districtAssessments':
      case 'collegeAssessments':
      case 'scienceAssessment':
      case 'physicalFitnessAssessment':
      case 'accessAlternativeAccess':
      case 'kidsAssessment':
        console.log(
          'formatfield',
          fieldName,
          fieldValue,
          fieldValue.split(','),
          fieldValue.split(',')[0].length
        )
        update[fieldName] =
          fieldValue !== ' ' ? fieldValue.split(',') : undefined
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const districtAssessmentOptions = [
    {
      label: 'Not participate in the entire district-wide assessment.',
      value: 'not-participate',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label:
        'Participate in the entire district-wide assessment with no accommodation(s).',
      value: 'participate-no-accommodations',
      style: { whiteSpace: 'pre-line', paddingBottom: '10px' }
    },
    {
      label:
        'Participate in entire district-wide assessment with accommodation(s). (Complete Assessment Accommodations section below.)',
      value: 'participate-with-accommodations',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label:
        'Participate in part(s) of the district-wide assessment.  (Specify which part(s) and what, if any, accommodations are required in the Assessment Accommodations section below.)',
      value: 'participate-parts-of-assessment',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label:
        'Participate in the district-wide alternate assessment without accommodation(s).',
      value: 'participate-alternate-no-accommodations',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label:
        'Participate in the district-wide alternate assessment with accommodation(s). (Complete Assessment Accommodations section below.)',
      value: 'participate-alternate-with-accommodations',
      style: { whiteSpace: 'break-spaces' }
    }
  ]
  const illinoisReadinessAssessmentGroup = [
    {
      label: 'The IAR assessment is not appropriate. (Go to #2)',
      value: 'iar-not-appropriate',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label:
        'Participate in IAR with no accessibility features turned on in advance and no accommodation(s).',
      value: 'iar-participate-no-accommodations',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label:
        'Participate in IAR assessment with accessibility features turned on in advance and/or accommodation(s). (Complete IAR Accessibility Features and Accommodations form and attach.)',
      value: 'iar-participate-with-accommodations',
      style: { whiteSpace: 'break-spaces' }
    }
  ]
  const dynamicLearningMapsGroup = [
    {
      label:
        'The DLM-AA Participation Guidelines were met. (Complete the DLM-AA Participation Guidelines and attach.)',
      value: 'dlm-guidelines-met',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label:
        'If met, the student will participate in DLM-AA with no accessibility features/accommodation(s).',
      value: 'dlm-participation-no-accommodations',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label:
        'If met, the student will participate in DLM-AA with accessibility features/accommodation(s). (Complete the DLM Accessibility Features and Accommodations form and attach.)',
      value: 'dlm-participation-with-accommodations',
      style: { whiteSpace: 'break-spaces' }
    }
  ]
  const collegeBoardGroup = [
    {
      label:
        'Participate in PSAT 9, PSAT 10, and SAT assessments with no accommodations.',
      value: 'college-assess-no-accomm',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label:
        'Participate in PSAT 9, PSAT 10, and SAT assessments with accommodation(s). (Complete College Board Assessments box in the Assessments Accommodations section below.)',
      value: 'college-assess-with-accomm',
      style: { whiteSpace: 'break-spaces' }
    }
  ]
  const illinoisScienceAssessmentGroup = [
    {
      label: 'Not administered at student’s current grade level or course.',
      value: 'no-isa-assessment',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label: 'Participate in science assessment with no accommodation(s).',
      value: 'isa-assessment-no-accomm',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label:
        'Participate in science assessment with accommodation(s). (Complete Science Assessments box in the Assessments Accommodations section below.)',
      value: 'isa-assessment-with-accomm',
      style: { whiteSpace: 'break-spaces' }
    }
  ]
  const physicalFitnessAssessmentFitnessGramGroup = [
    {
      label:
        'Will not participate in the physical fitness assessment (Explain)',
      value: 'explain',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label: 'Participate in FitnessGram© with no accommodation(s)',
      value: 'not-participate-fitnessgram',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label: 'Participate in FitnessGram© with accommodation(s).',
      value: 'participate-fitnessgram',
      style: { whiteSpace: 'break-spaces' }
    },
    {
      label: 'Participate in Brockport© with no accommodation(s)',
      value: 'not-participate-brockport',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label:
        'Participate in Brockport© with accommodation(s) (as delineated in the test manual)',
      value: 'participate-brockport',
      style: { whiteSpace: 'break-spaces' }
    }
  ]

  const kindergartenDevelopmentGroup = [
    {
      label: 'The KIDS Assessment is not appropriate',
      value: 'kids-assessment-not-appropriate',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label:
        'Participate in KIDS with no accommodation(s). Indicate which subsets:',
      value: 'subset1',
      style: { whiteSpace: 'pre-line', paddingBottom: '10px' }
    },
    {
      label:
        'Participate in KIDS with accommodation(s). Indicate which subsets:',
      value: 'subset2',
      style: { whiteSpace: 'break-spaces' }
    }
  ]
  const ifYesStudentWillGroup = [
    {
      label: 'Participate in the ACCESS with no accommodation(s).',
      value: 'participate-in-access-no-accommodations',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label:
        'Participate in the ACCESS with accommodation(s). (Complete Assessment Accommodations section below.)',
      value: 'participate-in-access-with-accommodations',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label: 'Participate in the alternate ACCESS with no accommodation(s).',
      value: 'participate-in-alternate-access-no-accommodations',
      style: { whiteSpace: 'break-spaces', paddingBottom: '10px' }
    },
    {
      label:
        'Participate in the alternate ACCESS with accommodation(s). (Complete Assessment Accommodations section below.)',
      value: 'participate-in-alternate-access-with-accommodations',
      style: { whiteSpace: 'break-spaces' }
    }
  ]
  const kidsAssessmentGroup = [
    { label: 'Communication devices', value: 'communication devices' },
    { label: 'Braille', value: 'braille' },
    { label: 'Enlarged Print/Pictures', value: 'enlarged-print-pictures' },
    { label: 'Adapted Writing Utensils', value: 'adapted-writing-utensils' },
    { label: 'Adapted Scissors', value: 'adapted-scissors' },
    { label: 'FM System', value: 'fm-system' }
  ]
  const indicateSubsetGroup = [
    { label: '1', value: 'one' },
    { label: '2', value: 'two' },
    { label: '3', value: 'three' }
  ]

  const isExplain = value => {
    return value === 'explain'
  }
  const isSubset = value => {
    return value === 'subset1'
  }
  const isSubset2 = value => {
    return value === 'subset2'
  }

  const [
    physicalFitnessAssessmentFitnessGramExplain,
    setPhysicalFitnessAssessmentFitnessGramExplain
  ] = useState(isExplain(data?.physicalFitnessAssessmentFitnessGram))
  const [
    physicalFitnessAssessmentBrockportExplain,
    setPhysicalFitnessAssessmentBrockportExplain
  ] = useState(isExplain(data?.physicalFitnessAssessmentBrockport))
  const [kindergartenDevelopmentSubset, setKindergartenDevelopmentSubset] =
    useState(isSubset(data?.kindergartenDevelopment))
  const [kindergartenDevelopmentSubset2, setKindergartenDevelopmentSubset2] =
    useState(isSubset(data?.kindergartenDevelopment))
  const [
    districtAdministerAssessmentInGrade,
    setDistrictAdministerAssessmentInGrade
  ] = useState(data?.districtAdministerAssessmentInGrade)
  const [englishLearner, setEnglishLearner] = useState(data?.englishLearner)

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454O'
            name='studentForm3454O'
            initialValues={data}
            // preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              ASSESSMENT
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>
            <Card
              title='CLASSROOM-BASED ASSESSMENTS'
              style={{
                marginBottom: '30px'
              }}
            >
              <Form.Item
                name='participateClassroomAssessments'
                {...validationProps('participateClassroomAssessments')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.participateClassroomAssessments}
                  name='participateClassroomAssessments'
                >
                  <Radio value={false} style={{ whiteSpace: 'break-spaces' }}>
                    Student will participate in classroom assessments with no
                    accommodation(s).
                    <br />
                    <br />
                  </Radio>
                  <Radio value style={{ whiteSpace: 'break-spaces' }}>
                    Student will participate in classroom assessments with
                    accommodation(s). (Complete Assessment Accommodations
                    section below.)
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Card>
            <Card
              title='DISTRICT-WIDE ASSESSMENTS'
              style={{
                marginBottom: '30px'
              }}
            >
              <Form.Item
                name='districtAdministerAssessments'
                {...validationProps('districtAdministerAssessments')}
                label='District does not administer district-wide assessments.'
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.districtAdministerAssessments}
                  name='districtAdministerAssessments'
                >
                  <Radio value>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name='districtAdministerAssessmentInGrade'
                {...validationProps('districtAdministerAssessmentInGrade')}
                label='District does not administer district-wide assessments at this grade level.'
              >
                <Radio.Group
                  onChange={e => {
                    setDistrictAdministerAssessmentInGrade(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  disabled={
                    formDisabledState.districtAdministerAssessmentInGrade
                  }
                  name='districtAdministerAssessmentInGrade'
                >
                  <Radio value>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Space direction='vertical'>
                <Card
                  style={{
                    marginBottom: '30px'
                  }}
                >
                  <Form.Item
                    label='Student will:'
                    name='studentParticipation'
                    {...validationProps('studentParticipation')}
                  >
                    <Radio.Group
                      options={districtAssessmentOptions}
                      onChange={checkedValues => {
                        const e = {
                          target: {
                            name: 'studentParticipation'
                          }
                        }
                        handleMouseEvents.onChange(e)
                      }}
                      defaultValue={[]}
                      disabled={formDisabledState.studentParticipation}
                    />
                  </Form.Item>
                </Card>
              </Space>
            </Card>
            <Card
              title='STATE ASSESSMENTS'
              style={{
                marginBottom: '30px'
              }}
            >
              <p>
                <strong>
                  Indicate which state academic assessment(s) student will take
                  and, if applicable, if accessibility feature(s) and/or
                  accommodation(s) are needed.
                </strong>
              </p>
              <Form.Item
                label='State academic assessments are not administered at this grade level:'
                name='stateAcademicAssessmentNotAdministered'
                {...validationProps('stateAcademicAssessmentNotAdministered')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  defaultValue={data.stateAcademicAssessmentNotAdministered}
                  buttonStyle='solid'
                  name='stateAcademicAssessmentNotAdministered'
                  disabled={
                    formDisabledState.stateAcademicAssessmentNotAdministered
                  }
                >
                  <Radio.Button value='true'>Yes</Radio.Button>
                  <Radio.Button value='false'>No</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Card
                title='1. Illinois Assessments of Readiness (IAR) (grades 3-8)'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item
                  name='illinoisReadinessAssessment'
                  {...validationProps('illinoisReadinessAssessment')}
                >
                  <Radio.Group
                    options={illinoisReadinessAssessmentGroup}
                    onChange={checkedValues => {
                      const e = {
                        target: {
                          name: 'illinoisReadinessAssessment'
                        }
                      }
                      handleMouseEvents.onChange(e)
                    }}
                    defaultValue={[]}
                    disabled={formDisabledState.illinoisReadinessAssessment}
                  />
                </Form.Item>
              </Card>

              <Card
                title='2. Dynamic Learning Maps Alternate Assessment (DLM-AA) for ELA/L, Math, Science (Grades 3-11)'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item
                  name='illinoisReadinessAssessment'
                  {...validationProps('illinoisReadinessAssessment')}
                >
                  <Radio.Group
                    options={illinoisReadinessAssessmentGroup}
                    onChange={checkedValues => {
                      const e = {
                        target: {
                          name: 'illinoisReadinessAssessment'
                        }
                      }
                      handleMouseEvents.onChange(e)
                    }}
                    defaultValue={[]}
                  />
                </Form.Item>
              </Card>

              <Card
                title='2. Dynamic Learning Maps Alternate Assessment (DLM-AA) for ELA/L, Math, Science (Grades 3-11)'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item
                  name='dynamicLearningMaps'
                  {...validationProps('dynamicLearningMaps')}
                >
                  <Radio.Group
                    options={dynamicLearningMapsGroup}
                    onChange={checkedValues => {
                      const e = {
                        target: {
                          name: 'dynamicLearningMaps'
                        }
                      }
                      handleMouseEvents.onChange(e)
                    }}
                    defaultValue={[]}
                    disabled={formDisabledState.dynamicLearningMaps}
                  />
                </Form.Item>
              </Card>

              <Card
                title='3. College Board Assessments (Grades 9-11)'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item
                  name='collegeBoard'
                  {...validationProps('collegeBoard')}
                >
                  <Radio.Group
                    options={collegeBoardGroup}
                    onChange={checkedValues => {
                      const e = {
                        target: {
                          name: 'collegeBoard'
                        }
                      }
                      handleMouseEvents.onChange(e)
                    }}
                    defaultValue={[]}
                    disabled={formDisabledState.collegeBoard}
                  />
                </Form.Item>
              </Card>

              <Card
                title='4. Illinois Science Assessment (ISA) (Grades 5, 8, high school Biology)'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item
                  name='illinoisScienceAssessment'
                  {...validationProps('illinoisScienceAssessment')}
                >
                  <Radio.Group
                    options={illinoisScienceAssessmentGroup}
                    onChange={checkedValues => {
                      const e = {
                        target: {
                          name: 'illinoisScienceAssessment'
                        }
                      }
                      handleMouseEvents.onChange(e)
                    }}
                    defaultValue={[]}
                    disabled={formDisabledState.illinoisScienceAssessment}
                  />
                </Form.Item>
              </Card>

              <Card
                title='5. Physical Fitness Assessment (e.g. Brockport©, FitnessGram©)'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item
                  name='physicalFitnessAssessmentFitnessGram'
                  {...validationProps('physicalFitnessAssessmentFitnessGram')}
                >
                  <Radio.Group
                    options={physicalFitnessAssessmentFitnessGramGroup}
                    onChange={e => {
                      setPhysicalFitnessAssessmentFitnessGramExplain(
                        isExplain(e.target.value)
                      )
                      e = {
                        target: {
                          name: 'physicalFitnessAssessmentFitnessGram',
                          value: e.target.value
                        }
                      }
                      handleMouseEvents.onChange(e)
                    }}
                    disabled={
                      formDisabledState.physicalFitnessAssessmentFitnessGram
                    }
                    defaultValue={[]}
                  />
                </Form.Item>
                <FormItem
                  hidden={!physicalFitnessAssessmentFitnessGramExplain}
                  label='Explanation for No Participation in FitnessGram Assessment:'
                  name='willNotParticipatePhysicalFitnessAssessment'
                  {...validationProps(
                    'willNotParticipatePhysicalFitnessAssessment'
                  )}
                >
                  <Editor
                    formField='willNotParticipatePhysicalFitnessAssessment'
                    disabled={
                      formDisabledState.willNotParticipatePhysicalFitnessAssessment
                    }
                    {...handleEditorEvents}
                  />
                </FormItem>
              </Card>

              <Card
                title='6. Kindergarten Individual Development Survey (KIDS)'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item
                  name='kindergartenDevelopment'
                  {...validationProps('kindergartenDevelopment')}
                >
                  <Radio.Group
                    options={kindergartenDevelopmentGroup}
                    onChange={e => {
                      setKindergartenDevelopmentSubset(isSubset(e.target.value))
                      setKindergartenDevelopmentSubset2(
                        isSubset2(e.target.value)
                      )
                      e = {
                        target: {
                          name: 'kindergartenDevelopment',
                          value: e.target.value
                        }
                      }
                      handleMouseEvents.onChange(e)
                    }}
                    defaultValue={[]}
                    disabled={formDisabledState.kindergartenDevelopmentGroup}
                  />
                </Form.Item>
                <FormItem
                  hidden={!kindergartenDevelopmentSubset}
                  label='Select Subset:'
                  name='indicateSubset'
                  {...validationProps('indicateSubset')}
                >
                  <Radio.Group
                    options={indicateSubsetGroup}
                    onChange={checkedValues => {
                      const e = {
                        target: {
                          name: 'indicateSubset'
                        }
                      }
                      handleMouseEvents.onChange(e)
                    }}
                    defaultValue={[]}
                  />
                </FormItem>
                <FormItem
                  hidden={!kindergartenDevelopmentSubset2}
                  label='Select Subset:'
                  name='indicateWithAccommodationSubset'
                  {...validationProps('indicateWithAccommodationSubset')}
                >
                  <Radio.Group
                    options={indicateSubsetGroup}
                    onChange={checkedValues => {
                      const e = {
                        target: {
                          name: 'indicateWithAccommodationSubset'
                        }
                      }
                      handleMouseEvents.onChange(e)
                    }}
                    defaultValue={[]}
                  />
                </FormItem>
              </Card>
            </Card>

            <Card
              title='STATE ASSESSMENT OF ENGLISH LANGUAGE PROFICIENCY'
              style={{
                marginBottom: '30px'
              }}
            >
              <p>
                The state assessments of language proficiency for English
                learners in grades K-12 include Accessing Comprehension and
                Communication in English State to State (ACCESS) and the
                Alternate ACCESS.
              </p>
              <Form.Item
                label='Is student an English learner?  If "No", skip to next section.'
                name='englishLearner'
                {...validationProps('englishLearner')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.englishLearner}
                  name='englishLearner'
                  onChange={e => {
                    setEnglishLearner(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name='ifYesStudentWill'
                {...validationProps('ifYesStudentWill')}
                hidden={!englishLearner}
                label='If yes, the student will:'
              >
                <Radio.Group
                  options={ifYesStudentWillGroup}
                  onChange={checkedValues => {
                    const e = {
                      target: {
                        name: 'ifYesStudentWill'
                      }
                    }
                    handleMouseEvents.onChange(e)
                  }}
                  defaultValue={[]}
                  disabled={formDisabledState.ifYesStudentWill}
                />
              </Form.Item>
            </Card>

            <Card
              title='ASSESSMENT ACCOMMODATIONS'
              style={{
                marginBottom: '30px'
              }}
            >
              <p>
                Document any needed accommodations for the content area(s) in
                the section below for a student who will participate in
                assessments with accommodations, other than IAR, DLM-AA, and/or
                ISA.
              </p>
              {/* <Form.Item
                name='classroomAssessments'
                {...validationProps('classroomAssessments')}
                label='Classroom-based Assessments'
              >
                <Editor
                  formField='classroomAssessments'
                  disabled={formDisabledState.classroomAssessments}
                  {...handleEditorEvents}
                />
              </Form.Item> */}
              <LookupSelect
                label='Classroom-based Assessments'
                name='classroomAssessments'
                type='Assessment'
                placeholder='Please select'
                disabled={formDisabledState.classroomAssessments}
                validation={validationProps('classroomAssessments')}
                onFocus={event => {
                  const e = {
                    target: {
                      id: event.target.id,
                      name: 'classroomAssessments'
                    }
                  }
                  handleFocusEvents.onFocus(e)
                }}
                onBlur={checkedValues => {
                  const e = {
                    target: {
                      name: 'classroomAssessments'
                    }
                  }
                  handleMouseEvents.onChange(e)
                }}
              />
              {/* <Form.Item
                name='districtAssessments'
                {...validationProps('districtAssessments')}
                label='District-based Assessments'
              >
                <Editor
                  formField='districtAssessments'
                  disabled={formDisabledState.districtAssessments}
                  {...handleEditorEvents}
                />
              </Form.Item> */}
              <LookupSelect
                label='District-based Assessments'
                name='districtAssessments'
                type='Assessment'
                placeholder='Please select'
                disabled={formDisabledState.districtAssessments}
                validation={validationProps('districtAssessments')}
                onFocus={event => {
                  const e = {
                    target: {
                      id: event.target.id,
                      name: 'classroomAssessments'
                    }
                  }
                  handleFocusEvents.onFocus(e)
                }}
                onBlur={checkedValues => {
                  const e = {
                    target: {
                      name: 'districtAssessments'
                    }
                  }
                  handleMouseEvents.onChange(e)
                }}
              />
              {/* <Form.Item
                name='collegeAssessments'
                {...validationProps('collegeAssessments')}
                label='College Board Assessments'
              >
                <Editor
                  formField='collegeAssessments'
                  disabled={formDisabledState.collegeAssessments}
                  {...handleEditorEvents}
                />
              </Form.Item> */}
              <LookupSelect
                label='College Board Assessments'
                name='collegeAssessments'
                type='Assessment'
                placeholder='Please select'
                disabled={formDisabledState.collegeAssessments}
                validation={validationProps('collegeAssessments')}
                onFocus={event => {
                  const e = {
                    target: {
                      id: event.target.id,
                      name: 'classroomAssessments'
                    }
                  }
                  handleFocusEvents.onFocus(e)
                }}
                onBlur={checkedValues => {
                  const e = {
                    target: {
                      name: 'collegeAssessments'
                    }
                  }
                  handleMouseEvents.onChange(e)
                }}
              />
              {/* <Form.Item
                name='scienceAssessment'
                {...validationProps('scienceAssessment')}
                label='Science Assessment'
              >
                <Editor
                  formField='scienceAssessment'
                  disabled={formDisabledState.scienceAssessment}
                  {...handleEditorEvents}
                />
              </Form.Item> */}
              <LookupSelect
                label='Science Assessments'
                name='scienceAssessment'
                type='Assessment'
                placeholder='Please select'
                disabled={formDisabledState.scienceAssessment}
                validation={validationProps('scienceAssessment')}
                onFocus={event => {
                  const e = {
                    target: {
                      id: event.target.id,
                      name: 'classroomAssessments'
                    }
                  }
                  handleFocusEvents.onFocus(e)
                }}
                onBlur={checkedValues => {
                  const e = {
                    target: {
                      name: 'scienceAssessment'
                    }
                  }
                  handleMouseEvents.onChange(e)
                }}
              />
              {/* <Form.Item
                name='physicalFitnessAssessment'
                {...validationProps('physicalFitnessAssessment')}
                label='Physical Fitness Assessment (e.g. Brockport©)'
              >
                <Editor
                  formField='physicalFitnessAssessment'
                  disabled={formDisabledState.physicalFitnessAssessment}
                  {...handleEditorEvents}
                />
              </Form.Item> */}
              <LookupSelect
                label='Physical Fitness Assessments (e.g. Brockport©)'
                name='physicalFitnessAssessment'
                type='Assessment'
                placeholder='Please select'
                disabled={formDisabledState.physicalFitnessAssessment}
                validation={validationProps('physicalFitnessAssessment')}
                onFocus={event => {
                  const e = {
                    target: {
                      id: event.target.id,
                      name: 'classroomAssessments'
                    }
                  }
                  handleFocusEvents.onFocus(e)
                }}
                onBlur={checkedValues => {
                  const e = {
                    target: {
                      name: 'physicalFitnessAssessment'
                    }
                  }
                  handleMouseEvents.onChange(e)
                }}
              />
              <Form.Item
                label='KIDS Assessment - Indicate which accommodations are needed:'
                name='kidsAssessment'
                {...validationProps('kidsAssessment')}
              >
                <Checkbox.Group
                  options={kidsAssessmentGroup}
                  onChange={checkedValues => {
                    const e = {
                      target: {
                        name: 'kidsAssessment',
                        value: checkedValues.toString()
                      }
                    }
                    handleMouseEvents.onChange(e)
                  }}
                  defaultValue={[]}
                  disabled={formDisabledState.kidsAssessment}
                />
              </Form.Item>
              {/* <Form.Item
                name='accessAlternativeAccess'
                {...validationProps('accessAlternativeAccess')}
                label='ACCESS/Alternate ACCESS'
              >
                <Editor
                  formField='accessAlternativeAccess'
                  disabled={formDisabledState.accessAlternativeAccess}
                  {...handleEditorEvents}
                />
              </Form.Item> */}
              <LookupSelect
                label='ACCESS/Alternate ACCESS'
                name='accessAlternativeAccess'
                type='Assessment'
                placeholder='Please select'
                disabled={formDisabledState.accessAlternativeAccess}
                validation={validationProps('accessAlternativeAccess')}
                onFocus={event => {
                  const e = {
                    target: {
                      id: event.target.id,
                      name: 'classroomAssessments'
                    }
                  }
                  handleFocusEvents.onFocus(e)
                }}
                onBlur={checkedValues => {
                  const e = {
                    target: {
                      name: 'accessAlternativeAccess'
                    }
                  }
                  handleMouseEvents.onChange(e)
                }}
              />
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454O}
              extractDataProp='printStudentForm3454O'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454O = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454O_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )
  const createInitialFormData = (studentInfo, versionInfo) => {
    console.log(
      '🚀 ~ file: StudentForm3454O.js ~ line 637 ~ createInitialFormData ~ studentInfo',
      studentInfo
    )
    console.log(
      '🚀 ~ file: StudentForm3454O.js ~ line 637 ~ createInitialFormData ~ versionInfo',
      versionInfo
    )
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454O: form } = versionInfo
    const { student } = studentInfo
    const { formDate, studentFullName } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      classroomAssessments: form.classroomAssessments
        ? form.classroomAssessments.split('|')
        : [],
      districtAssessments: form.districtAssessments
        ? form.districtAssessments.split('|')
        : [],
      collegeAssessments: form.collegeAssessments
        ? form.collegeAssessments.split('|')
        : [],
      scienceAssessment: form.scienceAssessment
        ? form.scienceAssessment.split('|')
        : [],
      physicalFitnessAssessment: form.physicalFitnessAssessment
        ? form.physicalFitnessAssessment.split('|')
        : [],
      accessAlternativeAccess: form.accessAlternativeAccess
        ? form.accessAlternativeAccess.split('|')
        : []
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454O
