import React, { useContext } from 'react'
import Page from '../../components/Page'
import LoginForm from '../../components/sections/login/LoginForm'
import { Typography, Layout, Row, Col, Card, Image } from 'antd'
import Home from './Home'
import { Link } from 'react-router-dom'
// contexts
import AppContext from '../../utils/appProvider'
import './HomeStyles.css'
import itames from './iTAMES Logo.png'
import generic from './TAMES Simple Logo.png'
import tamesassist from '../../assets/images/tames_assist_logo.png'
const Login = () => {

  const app = useContext(AppContext)
  const { Paragraph, Text } = Typography;
  const { Meta } = Card;
  const { Header, Footer, Sider, Content } = Layout;

  return (
    <Layout className='content-layout' >
      <Home />
      {/* <Header style={{
        position: 'fixed',
        top: 0,
        zIndex: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }} >
        <Image src={generic} preview={false} style={{ width: '5%', padding }} />

        <Row justify={'center'} align={'middle'}>
          <Typography.Title>
            TAMES Health Resource Services
          </Typography.Title>
        </Row>
      </Header>
      <Content>
        <Row justify={'center'} align={'middle'}>
          <Col span={12} style={{ padding: '5rem', }} className="fade-in" >
            <Card
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                height: '30rem'
              }}
            >
              <Row>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <Image src={itames} preview={false} />
                </Col>
              </Row>
              <Row>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >

                  <Link to="https://www.itames.com/" component={Typography.Text} style={{ color: 'black' }}>
                    Go to iTames
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                  paddingBottom: '3rem'
                }}>
                  <Paragraph>
                    For medicaid billing, scheduling services, calendar, and more.
                  </Paragraph>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12} style={{ padding: '5rem' }} className="fade-in">
            <Card
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                height: '30rem'

              }}
            >
              <Row>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <Image src={tamesassist} preview={false} />
                </Col>
              </Row>
              <Row>
                <Col style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                  paddingTop: '5rem'
                }}>

                  <Link to="/signin" component={Typography.Text} style={{ color: 'black' }}>
                    Go to Tames Assist
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <Paragraph>
                    For IEPS, 504s, Health Plans, and more.
                  </Paragraph>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer>
        <Row justify={'center'} align={'middle'}>
          <Typography.Title copyable>
            info@tameshrs.com
          </Typography.Title>
        </Row>
        <Row justify={'center'} align={'middle'}>
          <Typography.Title h1 copyable>
            800-848-0157
          </Typography.Title>
        </Row>
      </Footer> */}

    </Layout>
  )
}

export default Login
