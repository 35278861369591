import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useQuery } from '@apollo/client'
import Editor from '../../../elements/common/Editor'

// ant design
import {
  Form,
  DatePicker,
  Radio,
  Alert,
  Typography,
  Card,
  Checkbox,
  Input
} from 'antd'

import FormContainer from '../../../FormContainer'
import FormItem from 'antd/lib/form/FormItem'
import Title from 'antd/lib/typography/Title'

import { PRINT_FORM3454 } from '../../../../operations/mutations/forms/3454'
import { QUERY_FORM3454_VERSION } from '../../../../operations/queries/forms/3454'
import { QUERY_STUDENT_IEP_SIS_DATA } from '../../../../operations/queries/forms/SIS'
import { QUERY_STUDENT_MEETING_FORMS } from '../../../../operations/queries/meeting'

import FormCollaboration from '../../../FormCollaboration'

import { IepFormContext } from '../../../../utils/iepFormProvider'
import StudentFormActions from '../StudentFormActions'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentGenderSIS from '../sis/StudentGenderSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'
import StudentAddressSIS from '../sis/StudentAddressSIS'
import StudentCitySIS from '../sis/StudentCitySIS'
import StudentStateSIS from '../sis/StudentStateSIS'
import StudentCountrySIS from '../sis/StudentCountrySIS'
import StudentEthnicitySIS from '../sis/StudentEthnicitySIS'
import StudentGradeSIS from '../sis/StudentGradeSIS'
import StudentCodeSIS from '../sis/StudentCodeSIS'
import StudentParent1PhoneSIS from '../sis/StudentParent1PhoneSIS'
import StudentParent1AddressSIS from '../sis/StudentParent1AddressSIS'
import StudentParent1CitySIS from '../sis/StudentParent1CitySIS'
import StudentParent1StateSIS from '../sis/StudentParent1StateSIS'
import StudentParent1CountrySIS from '../sis/StudentParent1CountrySIS'
import StudentParent1ZipSIS from '../sis/StudentParent1ZipSIS'
import StudentParent2PhoneSIS from '../sis/StudentParent2PhoneSIS'
import StudentParent2AddressSIS from '../sis/StudentParent2AddressSIS'
import StudentParent2CitySIS from '../sis/StudentParent2CitySIS'
import StudentParent2StateSIS from '../sis/StudentParent2StateSIS'
import StudentParent2CountrySIS from '../sis/StudentParent2CountrySIS'
import StudentParent2ZipSIS from '../sis/StudentParent2ZipSIS'
import StudentLanguageSIS from '../sis/StudentLanguageSIS'
import MeetingSISInput from '../sis/MeetingSISInput'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId,
  meetingType
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)
  const [interpreterProvided, setInterpreterProvided] = useState(
    data?.interpreterProvided
  )
  const [
    parentRequestedInterpreterServeNoOtherRole,
    setParentRequestedInterpreterServeNoOtherRole
  ] = useState(data?.parentRequestedInterpreterServeNoOtherRole)
  const [purposeOfConference, setPurposeOfConference] = useState(
    data?.purposeOfConference != null
      ? data?.purposeOfConference.toString()
      : ''
  )
  const [parentGivenEvaluationReport, setParentGivenEvaluationReport] =
    useState(data?.parentGivenEvaluationReport)
  const [parentGivenIep, setParentGivenIep] = useState(data?.parentGivenIep)
  const [parentGivenThreeDayDraft, setParentGivenThreeDayDraft] = useState(
    data?.parentGivenThreeDayDraft
  )

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
      case 'recentEvaluationDate':
      case 'nextEvaluationDate':
      case 'highSchoolGraduationDate':
      case 'safeguardReviewDate':
      case 'threeDayDraftDate':
        update[fieldName] = dayjs(fieldValue)
        break
      case 'educationSurrogateParent1':
      case 'educationSurrogateParent2':
      case 'interpreterParent1':
      case 'interpreterParent2':
      case 'placement':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      case 'meetingsAttendedParent1':
      case 'meetingsAttendedParent2':
      case 'meetingsAttendedStudent':
      case 'meetingsAttendedLEARepresentative':
      case 'meetingsAttendedGeneralEducationTeacher':
      case 'meetingsAttendedSpecialEducationTeacher':
      case 'meetingsAttendedSchoolPsychologist':
      case 'meetingsAttendedSchoolSocialWorker':
      case 'meetingsAttendedSpeechLanguagePathologist':
      case 'meetingsAttendedInterpreter':
      case 'meetingsAttendedSchoolNurse':
      case 'meetingsAttendedOther':
        update[fieldName] = fieldValue.split(',')
        break
      case 'purposeOfConference':
        update[fieldName] = fieldValue.split(',')
        setPurposeOfConference(update[fieldName])
        break
      case 'interpreterProvided':
        update[fieldName] = fieldValue
        setInterpreterProvided(update[fieldName])
        break
      case 'parentRequestedInterpreterServeNoOtherRole':
        update[fieldName] = fieldValue
        setParentRequestedInterpreterServeNoOtherRole(update[fieldName])
        break
      case 'parentGivenEvaluationReport':
        update[fieldName] = fieldValue
        setParentGivenEvaluationReport(update[fieldName])
      case 'parentGivenIep':
        update[fieldName] = fieldValue
        setParentGivenIep(update[fieldName])
      case 'parentGivenThreeDayDraft':
        update[fieldName] = fieldValue
        setParentGivenThreeDayDraft(update[fieldName])
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const purposeOptions = [
    { label: 'Review Of Existing Data', value: 'review-of-existing-data' },
    { label: 'Reevaluation', value: 'reevaluation' },
    { label: 'IEP Review Revision', value: 'iep-review-revision' },
    { label: 'FBA/BIP', value: 'fba-bip' },
    { label: 'Graduation', value: 'graduation' },
    {
      label: 'Initial Evaluation/Eligibility',
      value: 'initial-evaluation-eligibility'
    },
    { label: 'Initial IEP', value: 'initial-iep' },
    { label: 'Annual Review', value: 'annual-review' },
    { label: 'Secondary Transition', value: 'secondary-transition' },
    {
      label: 'Manifestation Determination',
      value: 'manifestation-determination'
    },
    { label: 'Other', value: 'other' }
  ]

  // const materialOptions = [
  //   {
  //     label: 'Evaluation Report And Eligibility Determination',
  //     value: 'evaluation-report-and-eligibility-determination'
  //   },
  //   {
  //     label: 'IEP',
  //     value: 'IEP'
  //   },
  //   {
  //     label: 'Understanding PUNS: A Guide to Prioritization for Urgency of Need for Services',
  //     value: 'understanding-puns'
  //   },
  //   {
  //     label: 'Three-Day Draft',
  //     value: 'three-day-draft'
  //   },
  //   {
  //     label: 'District Behavioral Intervention Policies',
  //     value: 'district-behavioral-intervention-policies'
  //   },
  //   {
  //     label: 'District Behavioral Intervention Procedures (initial IEP only)',
  //     value: 'district-behavioral-intervention-procedures'
  //   },
  //   {
  //     label: 'Achieving a Better Life Experience (ABLE) account program information (required as of 2023-2024 school year)',
  //     value: 'achieving-a-better-life-experience'
  //   },

  // ]

  const meetingOptions = [
    { label: 'Eligibility Review', value: 'eligibility-review' },
    { label: 'IEP', value: 'iep' }
  ]

  const meetingsInputs = [
    {
      label: 'Meetings Attended by parent#1',
      name: 'meetingsAttendedParent1'
    },
    {
      label: 'Meetings Attended by parent#2',
      name: 'meetingsAttendedParent2'
    },
    {
      label: 'Meetings Attended by student',
      name: 'meetingsAttendedStudent'
    },
    {
      label: 'Meetings Attended by LEA representative',
      name: 'meetingsAttendedLEARepresentative'
    },
    {
      label: 'Meetings Attended by general education teacher',
      name: 'meetingsAttendedGeneralEducationTeacher'
    },
    {
      label: 'Meetings Attended by special education teacher',
      name: 'meetingsAttendedSpecialEducationTeacher'
    },
    {
      label: 'Meetings Attended by school psychologist',
      name: 'meetingsAttendedSchoolPsychologist'
    },
    {
      label: 'Meetings Attended by school social worker',
      name: 'meetingsAttendedSchoolSocialWorker'
    },
    {
      label: 'Meetings Attended by speech language pathologist',
      name: 'meetingsAttendedSpeechLanguagePathologist'
    },
    {
      label: 'Meetings Attended by bilingual specialist',
      name: 'meetingsAttendedBilingualSpecialist'
    },
    {
      label: 'Meetings Attended by interpreter',
      name: 'meetingsAttendedInterpreter'
    },
    {
      label: 'Meetings Attended by school nurse',
      name: 'meetingsAttendedSchoolNurse'
    },
    {
      label: 'Meetings Attended by other',
      name: 'meetingsAttendedOther'
    }
  ]
  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          handleActionEvents,
          formDisabledState,
          canSave,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457B'
            name='studentForm3457B'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            {meetingType === '504 Plan' ? (
              <>
                {' '}
                <Title level={4} className='center'>
                  CONFERENCE SUMMARY REPORT
                </Title>
              </>
            ) : (
              <>
                {' '}
                <Title level={4} className='center'>
                  INDIVIDUALIZED EDUCATION PROGRAM (CONFERENCE SUMMARY REPORT)
                </Title>
              </>
            )}

            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
              />
            </Form.Item>

            <MeetingSISInput
              name='recentEvaluationDate'
              label='Date of most recent evaluation'
              type='date'
            />

            <MeetingSISInput
              name='nextEvaluationDate'
              label='Date of next reevaluation'
              type='date'
            />

            <Card
              title='Purpose of Conference'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Purpose of conference'
                name='purposeOfConference'
                {...validationProps('purposeOfConference')}
              >
                <Checkbox.Group
                  disabled={formDisabledState.purposeOfConference}
                  options={purposeOptions}
                  onChange={checkedValues => {
                    setPurposeOfConference(
                      checkedValues != null ? checkedValues.toString() : ''
                    )
                    const e = {
                      target: {
                        name: 'purposeOfConference',
                        value:
                          checkedValues != null ? checkedValues.toString() : ''
                      }
                    }
                    handleMouseEvents.onChange(e)
                  }}
                  defaultValue={[]}
                />
              </Form.Item>

              {purposeOfConference.includes('other') && (
                <FormItem
                  label='Description of "Other" if selected:'
                  name='purposeOfConferenceOtherDescription'
                  {...validationProps('purposeOfConferenceOtherDescription')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={
                      formDisabledState.purposeOfConferenceOtherDescription
                    }
                  />
                </FormItem>
              )}
            </Card>

            <Card
              title='Student Identification Information'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentAddressSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.address}
                error={itemErrors.address}
              />
              <StudentCitySIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.city}
                error={itemErrors.city}
              />
              <StudentStateSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.state}
                error={itemErrors.state}
              />
              <StudentCountrySIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.country}
                error={itemErrors.country}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentCodeSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentCode}
                error={itemErrors.studentCode}
              />
              <StudentGenderSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentGender}
                error={itemErrors.studentGender}
              />
              <StudentEthnicitySIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentEthnicity}
                error={itemErrors.studentEthnicity}
              />
              <StudentLanguageSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentLanguage}
                error={itemErrors.studentLanguage}
              />
              <StudentGradeSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentGrade}
                error={itemErrors.studentGrade}
              />

              <MeetingSISInput
                name='highSchoolGraduationDate'
                label='Student High School Completion date'
                type='date'
              />
              <Form.Item
                label='Placement
                (YES OR NO)'
                name='placement'
                {...validationProps('placement')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.placement}
                  name='placement'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <FormItem
                label='Disability(s)'
                name='disabilities'
                {...validationProps('disabilities')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.disabilities}
                />
              </FormItem>
              <FormItem
                label='Medicaid number'
                name='medicaidNumber'
                {...validationProps('medicaidNumber')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.medicaidNumber}
                />
              </FormItem>
              <FormItem
                label='Resident district'
                name='residentDistrict'
                {...validationProps('residentDistrict')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.residentDistrict}
                />
              </FormItem>
              <FormItem
                label='Resident school'
                name='residentSchool'
                {...validationProps('residentSchool')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.residentSchool}
                />
              </FormItem>
            </Card>

            <Card
              title='Placement'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Serving district'
                name='servingDistrict'
                {...validationProps('servingDistrict')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.servingDistrict}
                />
              </FormItem>
              <FormItem
                label='Serving school'
                name='servingSchool'
                {...validationProps('servingSchool')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.residentSchool}
                />
              </FormItem>
            </Card>

            <Card
              title='Parent Information'
              style={{
                marginBottom: 30
              }}
            >
              <Card
                title={data.parent1 + ' (Parent 1) '}
                style={{
                  marginBottom: 30
                }}
              >
                <StudentParent1SIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1}
                  error={itemErrors.parent1}
                //disabled={false}
                />
                <Form.Item
                  label='Educational surrogate parent (1)'
                  name='educationSurrogateParent1'
                  {...validationProps('educationSurrogateParent1')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.educationSurrogateParent1}
                    name='educationSurrogateParent1'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <StudentParent1AddressSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1Address}
                  error={itemErrors.parent1Address}
                //disabled={false}
                />
                <StudentParent1CitySIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1City}
                  error={itemErrors.parent1City}
                //disabled={false}
                />
                <StudentParent1StateSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1State}
                  error={itemErrors.parent1State}
                //disabled={false}
                />
                <StudentParent1ZipSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1Zip}
                  error={itemErrors.parent1Zip}
                //disabled={false}
                />
                <StudentParent1CountrySIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1Country}
                  error={itemErrors.parent1Country}
                //disabled={false}
                />
                <FormItem
                  label='Parent #1 Email Address'
                  name='parent1Email'
                  {...validationProps('parent1Email')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.parent1Email}
                  />
                </FormItem>
                <StudentParent1PhoneSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1PhoneNumber}
                  error={itemErrors.parent1PhoneNumber}
                //disabled={false}
                />
                <FormItem
                  label='Parent #1 Language/Mode of Communication'
                  name='parent1PrimaryLanguage'
                  {...validationProps('parent1PrimaryLanguage')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.parent1PrimaryLanguage}
                  />
                </FormItem>
              </Card>

              <Card
                title={data.parent2 + ' (Parent 2) '}
                style={{
                  marginBottom: 30
                }}
              >
                <StudentParent2SIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2}
                  error={itemErrors.parent2}
                //disabled={false}
                />
                <Form.Item
                  label='Educational surrogate parent (2)'
                  name='educationSurrogateParent2'
                  {...validationProps('educationSurrogateParent2')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.educationSurrogateParent2}
                    name='educationSurrogateParent2'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <StudentParent2AddressSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2Address}
                  error={itemErrors.parent2Address}
                //disabled={false}
                />
                <StudentParent2CitySIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2City}
                  error={itemErrors.parent2City}
                //disabled={false}
                />
                <StudentParent2StateSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2State}
                  error={itemErrors.parent2State}
                //disabled={false}
                />
                <StudentParent2ZipSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2Zip}
                  error={itemErrors.parent2Zip}
                //disabled={false}
                />
                <StudentParent2CountrySIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2Country}
                  error={itemErrors.parent2Country}
                //disabled={false}
                />
                <FormItem
                  label='Parent #2 Email Address'
                  name='parent2Email'
                  {...validationProps('parent2Email')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.parent1Email}
                  />
                </FormItem>
                <StudentParent2PhoneSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2PhoneNumber}
                  error={itemErrors.parent2PhoneNumber}
                //disabled={false}
                />
                <FormItem
                  label='Parent #2 Language/Mode of Communication'
                  name='parent2PrimaryLanguage'
                  {...validationProps('parent2PrimaryLanguage')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.parent2PrimaryLanguage}
                  />
                </FormItem>
              </Card>
            </Card>

            <Card
              title='Interpreter Services'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Did the parent request, or otherwise indicate, that an interpreter was necessary to ensure meaningful parental
                    involvement for this meeting?'
                name='parentRequestedInterpreter'
                {...validationProps('parentRequestedInterpreter')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.parentRequestedInterpreter}
                  name='parentRequestedInterpreter'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <FormItem
                label='Was a qualified interpreter provided for the meeting?'
                name='interpreterProvided'
                {...validationProps('interpreterProvided')}
                style={{ marginBottom: '0' }}
              >
                <Radio.Group
                  onChange={e => {
                    setInterpreterProvided(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.interpreterProvided}
                  name='interpreterProvided'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              {interpreterProvided === 'yes' && (
                <FormItem
                  label='If "Yes," in what language was the interpreter provided?'
                  name='interpreterProvidedLanguage'
                  {...validationProps('interpreterProvidedLanguage')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.interpreterProvidedLanguage}
                  />
                </FormItem>
              )}

              <FormItem
                label='Did the parent request that the interpreter serve no other role in the meeting?'
                name='parentRequestedInterpreterServeNoOtherRole'
                {...validationProps(
                  'parentRequestedInterpreterServeNoOtherRole'
                )}
                style={{ marginBottom: '0' }}
              >
                <Radio.Group
                  onChange={e => {
                    setParentRequestedInterpreterServeNoOtherRole(
                      e.target.value
                    )
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={
                    formDisabledState.parentRequestedInterpreterServeNoOtherRole
                  }
                  name='parentRequestedInterpreterServeNoOtherRole'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                  <Radio.Button value='n/a'>N/A</Radio.Button>
                </Radio.Group>
              </FormItem>

              {parentRequestedInterpreterServeNoOtherRole === 'yes' && (
                <FormItem
                  label='If "Yes," was this request granted?'
                  name='parentInterpreterRoleRequestGranted'
                  {...validationProps('parentInterpreterRoleRequestGranted')}
                  style={{ marginBottom: '0' }}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={
                      formDisabledState.parentInterpreterRoleRequestGranted
                    }
                    name='parentInterpreterRoleRequestGranted'
                  >
                    <Radio.Button value='yes'>Yes</Radio.Button>
                    <Radio.Button value='no'>No</Radio.Button>
                    <Radio.Button value='n/a'>N/A</Radio.Button>
                  </Radio.Group>
                </FormItem>
              )}
            </Card>

            <Card
              title='Procedural Safeguards'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Date that Explanation of Procedural Safeguards were provided to/reviewed with the parent(s) '
                name='safeguardReviewDate'
                {...validationProps('safeguardReviewDate')}
              >
                <DatePicker
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                  {...handleFocusEvents}
                  disabled={formDisabledState.safeguardReviewDate}
                />
              </Form.Item>

              <FormItem
                label='Language in which the Procedural Safeguards were provided to parent:'
                name='safeguardsLanguage'
                {...validationProps('safeguardsLanguage')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.safeguardsLanguage}
                />
              </FormItem>

              <Form.Item
                label='Transfer of Rights - Seventeen-year old student informed of his/her rights that will transfer to the student upon reaching age 18'
                name='transferOfRights'
                {...validationProps('transferOfRights')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.transferOfRights}
                  name='transferOfRights'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='n/a'>N/A</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <hr></hr>

              <FormItem
                label='Parent(s) were given a copy of the Evaluation report and eligibility determination'
                name='parentGivenEvaluationReport'
                {...validationProps('parentGivenEvaluationReport')}
                style={{ marginBottom: '0' }}
              >
                <Radio.Group
                  onChange={e => {
                    setParentGivenEvaluationReport(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.parentGivenEvaluationReport}
                  name='parentGivenEvaluationReport'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              {parentGivenEvaluationReport === 'yes' && (
                <FormItem
                  label='Language in which the written materials were provided to parent:'
                  name='evaluationReportLanguage'
                  {...validationProps('evaluationReportLanguage')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.evaluationReportLanguage}
                  />
                </FormItem>
              )}

              <FormItem
                label='Parent(s) were given a copy of the IEP'
                name='parentGivenIep'
                {...validationProps('parentGivenIep')}
                style={{ marginBottom: '0' }}
              >
                <Radio.Group
                  onChange={e => {
                    setParentGivenIep(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.parentGivenIep}
                  name='parentGivenIep'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              {parentGivenIep === 'yes' && (
                <FormItem
                  label='Language in which the written materials were provided to parent:'
                  name='iepLanguage'
                  {...validationProps('iepLanguage')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.iepLanguage}
                  />
                </FormItem>
              )}

              <Form.Item
                label="Parent(s) were given a copy of the District's behavioral intervention policies"
                name='parentGivenDistrictBehavioralPolicy'
                {...validationProps('parentGivenDistrictBehavioralPolicy')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={
                    formDisabledState.parentGivenDistrictBehavioralPolicy
                  }
                  name='parentGivenDistrictBehavioralPolicy'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="Parent(s) were given a copy of the District's behavioral intervention procedures (initial IEP only)"
                name='parentGivenDistrictBehavioralInterventionProcedure'
                {...validationProps(
                  'parentGivenDistrictBehavioralInterventionProcedure'
                )}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={
                    formDisabledState.parentGivenDistrictBehavioralInterventionProcedure
                  }
                  name='parentGivenDistrictBehavioralInterventionProcedure'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label='Parent(s) were given a copy of the Understanding PUNS: A Guide to Prioritization for Urgency of Need Services'
                name='parentGivenPuns'
                {...validationProps('parentGivenPuns')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.parentGivenPuns}
                  name='parentGivenPuns'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label='Parent(s) were given a copy of the Achieving a Better Life Experience (ABLE) account program information (required as of 2023-2024 school year)'
                name='parentGivenAble'
                {...validationProps('parentGivenAble')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.parentGivenAble}
                  name='parentGivenAble'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <FormItem
                label='Parent(s) were given a copy of the Three-Day Draft materials that will be considered by the IEP Team'
                name='parentGivenThreeDayDraft'
                {...validationProps('parentGivenThreeDayDraft')}
                style={{ marginBottom: '0' }}
              >
                <Radio.Group
                  onChange={e => {
                    setParentGivenThreeDayDraft(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.parentGivenThreeDayDraft}
                  name='parentGivenThreeDayDraft'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              {parentGivenThreeDayDraft === 'yes' && (
                <FormItem
                  label='Materials were provided via:'
                  name='threeDayDraftProvidedVia'
                  {...validationProps('threeDayDraftProvidedVia')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.threeDayDraftProvidedVia}
                  />
                </FormItem>
              )}

              {parentGivenThreeDayDraft === 'yes' && (
                <Form.Item
                  label='Materials were provided on:'
                  name='threeDayDraftDate'
                  {...validationProps('threeDayDraftDate')}
                >
                  <DatePicker
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                    {...handleFocusEvents}
                    disabled={formDisabledState.threeDayDraftDate}
                  />
                </Form.Item>
              )}

              {parentGivenThreeDayDraft === 'yes' && (
                <FormItem
                  label='Language in which the written materials were provided to parent:'
                  name='threeDayDraftLanguage'
                  {...validationProps('threeDayDraftLanguage')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.threeDayDraftLanguage}
                  />
                </FormItem>
              )}
            </Card>

            <Card
              title="Participant's Information"
              style={{
                marginBottom: 30
              }}
            >
              {meetingsInputs.map(({ label, name }, index) => (
                <Form.Item
                  key={index}
                  label={label}
                  name={name}
                  {...validationProps(name)}
                >
                  <Checkbox.Group
                    disabled={formDisabledState[name]}
                    options={meetingOptions}
                    onChange={checkedValues => {
                      const e = {
                        target: {
                          name,
                          value:
                            checkedValues != null
                              ? checkedValues.toString()
                              : ''
                        }
                      }
                      handleMouseEvents.onChange(e)
                    }}
                    defaultValue={[]}
                  />
                </Form.Item>
              ))}
              <FormItem
                label='Other Attendee Description'
                name='meetingsAttendedOtherText'
                {...validationProps('meetingsAttendedOtherText')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.meetingsAttendedOtherText}
                />
              </FormItem>

              <hr></hr>

              <Form.Item
                label='If the parent(s) did not attend the IEP meeting, document the attempts to contact them prior to such meeting:'
                name='parentContactAttemptsBeforeMeeting'
                {...validationProps('parentContactAttemptsBeforeMeeting')}
              >
                <Editor
                  formField='parentContactAttemptsBeforeMeeting'
                  disabled={
                    formDisabledState.parentContactAttemptsBeforeMeeting
                  }
                  {...handleEditorEvents}
                />
              </Form.Item>

              <Form.Item
                label='The meeting was scheduled at a mutually agreed on time and place'
                name='meetingScheduledAtAgreedTimeAndPlace'
                {...validationProps('meetingScheduledAtAgreedTimeAndPlace')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={
                    formDisabledState.meetingScheduledAtAgreedTimeAndPlace
                  }
                  name='meetingScheduledAtAgreedTimeAndPlace'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label='Explanation of meeting schedule:'
                name='meetingScheduleExplanation'
                {...validationProps('meetingScheduleExplanation')}
              >
                <Editor
                  formField='meetingScheduleExplanation'
                  disabled={formDisabledState.meetingScheduleExplanation}
                  {...handleEditorEvents}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454}
              extractDataProp='printStudentForm3454'
              canSave={canSave}
              getFormFieldValues={getFormFieldValues}
              handleActionEvents={handleActionEvents}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454 = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId, meetingType } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_IEP_SIS_DATA,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454: form } = versionInfo
    const { student } = studentInfo
    const {
      formDate,
      recentEvaluationDate,
      nextEvaluationDate,
      highSchoolGraduationDate,
      safeguardReviewDate,
      studentFullName,
      threeDayDraftDate,
      studentDob,
      parent1,
      parent2,
      address,
      city,
      state,
      zip,
      country,
      studentGender,
      studentEthnicity,
      studentGrade,
      studentCode,
      parent1PhoneNumber,
      parent1Address,
      parent1City,
      parent1State,
      parent1Country,
      parent1Zip,
      parent1PrimaryLanguage,
      parent2PhoneNumber,
      parent2Address,
      parent2City,
      parent2State,
      parent2Country,
      parent2Zip,
      parent2PrimaryLanguage,
      studentLanguage,
      lastAnnualReviewDate,
      nextAnnualReviewDate,
      residentDistrict,
      residentSchool,
      servingDistrict,
      servingSchool
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      recentEvaluationDate: recentEvaluationDate
        ? dayjs(recentEvaluationDate)
        : dayjs(Date.now()),
      nextEvaluationDate: nextEvaluationDate
        ? dayjs(nextEvaluationDate)
        : dayjs(Date.now()),
      safeguardReviewDate: safeguardReviewDate
        ? dayjs(safeguardReviewDate)
        : dayjs(Date.now()),
      threeDayDraftDate: threeDayDraftDate
        ? dayjs(threeDayDraftDate)
        : dayjs(Date.now()),
      highSchoolGraduationDate: highSchoolGraduationDate
        ? dayjs(student.highSchoolGraduationDate)
        : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? dayjs(student.dateOfBirth).format('YYYY-MM-DD')
        : '',
      address: address || student.address || '',
      city: city || student.city || '',
      state: state || student.state || '',
      country: country || student.country || '',
      zip: zip || student.zip || '',
      studentGender: studentGender || student.gender || '',
      studentEthnicity: studentEthnicity || student.ethnicity || '',
      studentGrade: studentGrade || student.grade || '',
      studentCode: studentCode || student.studentCode || '',
      studentLanguage: studentLanguage || student.primaryLanguage || '',
      parent1: parent1 || student.parent1FullName || '',
      parent1PhoneNumber:
        parent1PhoneNumber || student.parent1PhoneNumberNumber || '',
      parent1Address: parent1Address || student.parent1Address || '',
      parent1City: parent1City || student.parent1City || '',
      parent1State: parent1State || student.parent1State || '',
      parent1Country: parent1Country || student.parent1Country || '',
      parent1Zip: parent1Zip || student.parent1Zip || '',
      parent1PrimaryLanguage:
        parent1PrimaryLanguage || student.parent1PrimaryLanguage || '',

      parent2: parent2 || student.parent2FullName || '',
      parent2PhoneNumber:
        parent2PhoneNumber || student.parent2PhoneNumber || '',
      parent2Address: parent2Address || student.parent2Address || '',
      parent2City: parent2City || student.parent2City || '',
      parent2State: parent2State || student.parent2State || '',
      parent2Country: parent2Country || student.parent2Country || '',
      parent2Zip: parent2Zip || student.parent2Zip || '',
      parent2PrimaryLanguage:
        parent2PrimaryLanguage || student.parent2PrimaryLanguage || '',

      //highSchoolGraduationDate: highSchoolGraduationDate || student.highSchoolGraduationDate || '',
      lastAnnualReviewDate:
        lastAnnualReviewDate || student.lastAnnualReviewDate || '',
      nextAnnualReviewDate:
        nextAnnualReviewDate || student.nextAnnualReviewDate || '',
      residentDistrict: residentDistrict || student.residentDistrict || '',
      residentSchool: residentSchool || student.residentSchool || '',
      servingDistrict: servingDistrict || student.servingDistrict || '',
      servingSchool: servingSchool || student.servingSchool || ''
    }
    return initialValues
  }

  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
            meetingType={meetingType}
          />
        }
      />
    </FormErrorProvider>
  )
}

export default StudentForm3454
