// react
import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams, Link } from 'react-router-dom'

// apollo & operations
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { QUERY_USER, QUERY_USERS } from '../../../operations/queries/users'
import { QUERY_SCHOOLS } from '../../../operations/queries/schools'
import { QUERY_STUDENTS } from '../../../operations/queries/students'
import {
  INSERT_USER,
  UPDATE_USER,
  EMAIL_INVITE_LINK,
  EMAIL_USER_PASSWORD_RESET_LINK
} from '../../../operations/mutations/users'

import { ADD_SUBROLE_TO_USER } from '../../../operations/mutations/subroles'
//import dayjs from 'dayjs'
import dayjs from "dayjs";


// ant design
import { Button, Card, Collapse, ConfigProvider, Divider, Form, Input, Space, Switch, Table, Tag, Transfer, notification, Radio, Affix } from 'antd'
const { Panel } = Collapse

import { MailOutlined } from '@ant-design/icons'

// elements
import FormItemSelectRole from '../../elements/formItems/FormItemSelectRole'
import FormItemSelectSubRole from '../../elements/formItems/FormItemSelectSubRole'
import FormItemSelectDistrict from '../../elements/formItems/FormItemSelectDistrict'
import FormItemSelectSchool from '../../elements/formItems/FormItemSelectSchool'
import FormItemsSuperAdmin from '../../elements/formItems/FormItemsSuperAdmin'
import FormItemError from '../../elements/formItems/FormItemError'

// other libraries
import AuthContext from '../../../utils/authProvider'
import formatErrors from '../../../utils/form'
import FormContainer from '../../../components/FormContainer'
import ModalDialog from '../../../components/ModalDialog'

import notificationSuccess from '../../../utils/notification'
import { notificationError } from '../../../utils/notification'
import { keys } from 'lodash'
import { all } from 'axios'

//Define table transfer component used for assighment of students to user dashboard
const StudentsTableTransfer = (props) => {
  const { leftColumns, rightColumns, ...restProps } = props;
  //const [selectedStudentRowKeys,setSelectedStudentRowKeys] = useState([])

  return (
    <Transfer
      rowKey={(record) => record.id.toString()} //Needed because we don't have key attribute on students
      style={{
        width: '100%',
      }}
      {...restProps}
    >
      {({
        direction,
        filteredItems,
        onItemSelect,
        onItemSelectAll,
        selectedKeys: listSelectedKeys,

      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;
        const rowSelection = {
          selectedRowKeys: listSelectedKeys,
          //selectedRowKeys: selectedStudentRowKeys,
          onChange(selectedRowKeys) {
            onItemSelectAll(selectedRowKeys, 'replace');
            //setSelectedStudentRowKeys(selectedRowKeys)
            console.log('Transfer-onChange-rowSelection:', selectedRowKeys)
          },
          //Following defines checkbox header dropdown list:
          //  SELECTION_ALL: Selects all students(not just current page).  Disallowed.
          //  SELECTION_INVERT: Inverts selections on current page.
          //  SELECTION_CLEAR: Clears all selections(not just current page). 
          //selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],

          // selections: [
          //   //Table.SELECTION_INVERT,
          //   //Table.SELECTION_NONE,
          //   {
          //     key: 'selectAll',
          //     text: 'Select all students',
          //     onSelect:(allKeys) => {
          //       //console.log(allKeys)
          //       // const selectedKeys = allKeys.filter((key) => {
          //       //   //console.log(key)
          //       //   return true
          //       // })

          //       console.log('Current List-allKeys(visible on page)',allKeys)
          //       console.log('Current List-listSelectedKeys(checkbox checked)',listSelectedKeys)
          //       console.log('Current List-filteredItems(on all pages)',filteredItems)
          //       //console.log('af',selectedKeys)
          //       //setSelectedStudentRowKeys(selectedKeys)
          //     }
          //       //setSelectedStudentRows(allKeys)

          //   },
          // ],

        };
        return (
          <Table
            rowSelection={rowSelection} //Passing rowSelection puts checkmark column in table
            columns={columns}
            dataSource={filteredItems}
            size="small"
            onRow={({ key }) => ({
              onClick: () => {  //Fires when anywhere on the row, except the checkbox, is clicked
                onItemSelect(key, !listSelectedKeys.includes(key));
                console.log('Transfer-Table.onRow.onClick:', key)
              },
            })}
            pagination={{
              showSizeChanger: true,
            }}
          />
        );
      }}
    </Transfer>
  );
};

const FormComponent = ({ data }) => {
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole("SUPER_ADMIN");
  const isDistrictAdmin = Auth.hasRole("DISTRICTS_ADMIN");
  const isSchoolsAdmin = Auth.hasRole("SCHOOLS_ADMIN");

  const history = useNavigate()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const [addSubRoleToUser] = useMutation(ADD_SUBROLE_TO_USER)
  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER)
  const [insertUser, { loading: insertLoading }] = useMutation(INSERT_USER, {
    update(cache, { data: addUser }) {
      const data = cache.readQuery({ query: QUERY_USERS })
      if (data) {
        cache.writeQuery({
          query: QUERY_USERS,
          data: { users: [addUser.insertUser, ...data.users] }
        })
      }

    }
  })

  const [sendEmailInvite, { loading: inviteLoading }] =
    useMutation(EMAIL_INVITE_LINK)

  const [emailUserPasswordResetLink] = useMutation(
    EMAIL_USER_PASSWORD_RESET_LINK
  )

  const continueEditing = _id => _id && history(`/user/${_id}`)

  const onFinish = async values => {
    const subRoleId = values.subRoleId
    // delete (values.subRoleId)
    // if (!subRoleId) console.log('data', data)
    values.desktopStudentIds = studentTargetKeys  //Load value from state
    console.log('onFinish:', values)

    try {
      clearErrors()
      const mutated = id
        ? await updateUser({ variables: { id: id, userInput: values } })
        : await insertUser({ variables: values })

      if (id || mutated.data.insertUser?.id) {
        // this is weird because creating multiple subRoleIds per user copuld be possible in fut
        await addSubRoleToUser({
          variables: { userId: id || mutated.data.insertUser?.id, subRoleId }
        })
      }

      //Send invite if this is a new user (id===null)
      if (id === null) {
        await sendEmailInvite({
          variables: { id: mutated.data.insertuser?.id }
        })
      }
      notificationSuccess(
        `The user has been ${id ? 'updated.' : 'added and an invite email has been sent.'
        }`
      )
    } catch (e) {
      setErrors(formatErrors(e))
    }
  }

  const setErrors = errors => {
    if (errors.itemErrors) {
      setItemErrors(errors.itemErrors)
    }
    if (errors.formError) {
      setFormError(errors.formError)
    }
  }

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }

  const handleResetPasswordClick = async () => {
    try {
      const email = data.user.email
      await emailUserPasswordResetLink({ variables: { email: email } })
      notificationSuccess('A reset password link was sent to ' + email)
    } catch (error) {
      const message =
        error?.metadata?.graphQLErrors?.[0]?.message ||
        error?.message ||
        'An unknown error occurred, please contact your administrator. E006'
      notificationError(
        'Reset password failed',
        message + '. Please contact your administrator.'
      )
    }
  }

  //--------------------------------------------------------------------
  //Following was added to implement assignment of students to user dashboard
  //--------------------------------------------------------------------
  const [studentTargetKeys, setStudentTargetKeys] = useState(data.user.desktopStudentIds);
  const [gradeFilters, setGradeFilters] = useState([]);
  const [schoolFilters, setSchoolFilters] = useState([]);
  const [studentsList, setStudentsList] = useState([])

  const [getStudentData, { loading, studentsQuery, error }] = useLazyQuery(QUERY_STUDENTS, {
    variables: { ...{}, ...{ offset: 0, limit: 10 } },
    fetchPolicy: 'cache-first',
    enabled: false,
    staleTime: Infinity
  });

  //const getSchools = (async (districtIds) => {
  const [getSchoolsData, { schoolsData }] = useLazyQuery(QUERY_SCHOOLS, {
    //variables: { searchCriteria: 'New School', districtId: '6658bd8c7d4f7b1990f448b5' },
    //variables: { districtIds: ['6658bd8c7d4f7b1990f448b5'] },
    //variables: { districtIds: allowedDistrictIds },
    // fetchPolicy: 'cache-first',
    // enabled: false,
    // staleTime: Infinity
  })
  //   return data.schools
  // });

  useEffect(async () => { //Triggered only on first load.  Gets district/school permissions from db.
    const students = await (await getStudentData()).data.students //Get logged in user's students list
    const filteredStudents = students.filter((student) => { return data.user.allowedSchoolIds.includes(...student.schoolIds) }) //Filter all students to the user's allowed schoolIds
    setStudentsList(filteredStudents) //Save filtered students list to state
    setGradeFilters(loadGradeFilters(filteredStudents)) //Load antd grade filter values
    setSchoolFilters(loadSchoolFilters(filteredStudents)) //Load antd school filter values
    //console.log('useEffect:',data.user,students.length,filteredStudents.length,data.user.isSuperAdmin, data.user.isDistrictsAdmin,data.user.isSchoolsAdmin,data.user.isSchoolsGeneral)

    //Filter the students list if the user being edited is schoolsAdmin and/or schoolsGeneral and not superAdmin or districtsAdmin
    // let filteredStudents = []
    // if( (data.user.isSchoolsAdmin || data.user.isSchoolsGeneral) && !data.user.isSuperAdmin && !data.user.isDistrictAdmin ) {
    //   //const allowedSchoolIds = [...data.user.userAdminSchoolIds, ...data.user.userGeneralSchoolIds] //Get the user being edited allowable schoolsIds
    //   filteredStudents = students.filter((student) => {return data.user.allowedSchoolIds.includes(...student.schoolIds)}) //Filter all students to the user's allowed schoolIds
    // } else {
    //   filteredStudents = students
    // }
  }, []);

  const filterStudents = async () => {  //Called when a user's district, school admin or school general schools are changed
    const formValues = form.getFieldValue() //Get current selections

    //Get a list of all schools user can access using selected userAdminDistrictIds, userAdminSchoolIds and userGeneralSchoolIds
    //Schools based on selected districts (userAdminDistrictIds)
    let allSchoolIds = [];
    const allowedDistrictIds = [...formValues.userAdminDistrictIds]
    if (allowedDistrictIds.length > 0) {
      const schools = await (await getSchoolsData({ variables: { districtIds: allowedDistrictIds } })).data.schools;
      schools.map((school) => {
        allSchoolIds.push(school.id);
      })
    }

    //Schools based on user's admin and general schools
    formValues.userAdminSchoolIds.map((id) => {
      allSchoolIds.push(id);
    })
    formValues.userGeneralSchoolIds.map((id) => {
      allSchoolIds.push(id);
    })

    //Get a list of unique schoolIds
    const allowedSchoolIds = Array.from(new Set(allSchoolIds));

    //Filter the students list to those in schools based on current selections
    const students = await (await getStudentData()).data.students //Get logged in user's students list
    const filteredStudents = students.filter((student) => { return allowedSchoolIds.includes(...student.schoolIds) })

    //Remove any existing dashboard students that don't exist in the current filter.  Do we want to do this?
    let newStudentTargetKeys = []
    studentTargetKeys.map((studentId) => {
      //if found in filtered students add
      if (filteredStudents.find((student) => student.id === studentId)) {
        newStudentTargetKeys.push(studentId)
      }
    })
    //console.log(newStudentTargetKeys)

    setStudentsList(filteredStudents) //Save filtered students list to state
    setStudentTargetKeys(newStudentTargetKeys)

    setGradeFilters(loadGradeFilters(filteredStudents)) //Load antd grade filter values
    setSchoolFilters(loadSchoolFilters(filteredStudents)) //Load antd school filter values

    //console.log('filterStudents', allowedDistrictIds,allowedSchoolIds,allSchoolIds,allowedSchoolIds,studentTargetKeys)
    return
  }

  const studentsColumns = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      sorter: {
        compare: (a, b) => a.fullName.localeCompare(b.fullName),
        multiple: 2,
      },
    },
    {
      title: 'Student Code',
      dataIndex: 'studentCode',
      sorter: {
        compare: (a, b) => a.studentCode.localeCompare(b.studentCode),
      },
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dateOfBirth',
      sorter: {
        compare: (a, b) => new Date(a.dateOfBirth) - new Date(b.dateOfBirth),  //Revised. 2.3sec to sort 61k recs
        multiple: 1,
      },
      render: (value, record) => <>{dayjs(record.dateOfBirth).format('M/D/YYYY')}</>
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      sorter: {
        compare: (a, b) => getNumericGrade(a.grade) - getNumericGrade(b.grade),
        multiple: 3,
      },
      filters: gradeFilters,
      onFilter: (value, record) => {
        return record.grade ? record.grade === value : false
      },
    },
    {
      title: 'School',
      dataIndex: 'schoolName',
      sorter: {
        compare: (a, b) => a.schoolName.localeCompare(b.schoolName),
        multiple: 4,
      },
      filters: schoolFilters,
      onFilter: (value, record) => {
        if (record?.schoolName) {
          return record.schoolName.indexOf(value) === 0
        }
      },
    }
  ]

  const getNumericGrade = (grade) => {
    let gradeValue = -99
    if (grade != undefined || null) {
      if (grade.slice(0, 5) === 'Grade') {
        gradeValue = parseInt(grade.replace('Grade', '').trim()) //Grade 1-12
      } else {
        switch (grade.toLowerCase()) {
          case 'early childhood':
            gradeValue = -3
            break
          case 'pre kindergarten':
            gradeValue = -2
            break
          case 'kindergarten':
            gradeValue = -1
            break
          default:
            gradeValue = -98
            break
        }
      }
    }
    return gradeValue
  }

  const loadGradeFilters = (students) => {
    let gradesList = []
    students?.map(student => {  //Load an array of grades with numeric equivalent
      if (student.grade != undefined || null) {
        const gradeValue = getNumericGrade(student.grade)
        gradesList.push([student.grade, gradeValue])
      }
    })
    gradesList = gradesList.sort((a, b) => a[1] - b[1]);  //Sort array of grades by gradeValue

    const gradesSet = new Set() //Load a set of unique grades from the list of grades
    gradesList.map(grade => {
      gradesSet.add(grade[0])
    })

    const antdGrades = [] //Load the antd grades filters
    gradesSet.forEach((grade) => {
      antdGrades.push({ text: grade, value: grade })
    })
    return antdGrades
  }

  const loadSchoolFilters = (students) => {
    let setSchools = new Set()
    let antdSchools = []
    students?.map(e => {
      if (e.schoolName != undefined || null) {
        setSchools.add(e.schoolName)
      }
    })
    const filterSchools = [...setSchools]
    filterSchools?.map(e => {
      antdSchools.push({ text: e, value: e })
    })
    return antdSchools
  }

  const studentsFilterOption = (input, item) => {
    let [word1, word2] = input.split(' ');
    word1 = word1 ? word1.trim().toLowerCase() : '';
    word2 = word2 ? word2.trim().toLowerCase() : '';
    const firstName = item.firstName ? item.firstName.toLowerCase().trim() : '';
    const lastName = item.lastName ? item.lastName.toLowerCase().trim() : '';
    const studentCode = item.studentCode ? item.studentCode.toString().toLowerCase().trim() : '';
    const dateOfBirth = item.dateOfBirth ? new Date(item.dateOfBirth).toLocaleDateString("en-US") : '';
    if (!word2) { //Single word search.
      if (
        firstName.includes(word1) ||
        lastName.includes(word1) ||
        studentCode.includes(word1) ||
        dateOfBirth.substring(0, word1.length) === (word1)
      ) {
        return true
      }
    } else {  //Two word search.  Can be first/last or last/first.
      if (
        (firstName === word1 &&
          lastName.includes(word2)) ||
        (lastName === word1 &&
          firstName.includes(word2))
      ) {
        return true
      }
    }
    return false
  }

  const studentsOnChange = (nextTargetKeys) => {
    setStudentTargetKeys(nextTargetKeys);
  };

  return (
    <>
      {data && (
        <Form
          form={form}
          id='userForm'
          name='userForm'
          onFinish={onFinish}
          preserve={false}
          initialValues={data.user}
          labelCol={{ sm: 8, md: 5, lg: 6, xl: 4, xxl: 3 }}
          wrapperCol={{ sm: 10, md: 7, lg: 8, xl: 6, xxl: 4 }}
        >
          <Card
            title='User Email & Permissions'
            style={{
              marginBottom: 30
            }}
          >

            <Form.Item
              label='Email'
              name='email'
              hasFeedback
              validateStatus={itemErrors.email ? 'error' : ''}
              help={itemErrors.email}
            >
              <Input />
            </Form.Item>

            <FormItemSelectRole
              label='Role(s)'
              name='roleIds'
              mode='multiple'
              disabled={!isSuperAdmin}
              validateStatus={itemErrors.roleIds ? 'error' : ''}
              help={itemErrors.rolesIds}
            />

            <FormItemSelectSubRole
              label='Schools General Subrole'
              name='subRoleId'
              validateStatus={itemErrors.subRoleId ? 'error' : ''}
              help={itemErrors.subRoleId}
            />

            <FormItemSelectDistrict
              label='District admin'
              name='userAdminDistrictIds'
              mode='multiple'
              disabled={!isSuperAdmin}
              validateStatus={itemErrors.userAdminDistrictIds ? 'error' : ''}
              help={itemErrors.userAdminDistrictIds}
              onChange={filterStudents}
            />

            <FormItemSelectSchool
              label='School admin'
              name='userAdminSchoolIds'
              mode='multiple'
              validateStatus={itemErrors.userAdminSchoolIds ? 'error' : ''}
              help={itemErrors.userAdminSchoolIds}
              onChange={filterStudents}
            />

            <FormItemSelectSchool
              label='School general access'
              name='userGeneralSchoolIds'
              mode='multiple'
              validateStatus={itemErrors.userGeneralSchoolIds ? 'error' : ''}
              help={itemErrors.userGeneralSchoolIds}
              onChange={filterStudents}
            />

            <Collapse
              //defaultActiveKey={['1']}
              style={{
                marginTop: 30
              }}
            >
              <Panel header={<span style={{ color: 'black' }}><b>Add/Remove Students from User's Dashboard</b></span>}>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={insertLoading || updateLoading}
                >
                  {id ? 'Update' : 'Add'}
                </Button>
                <StudentsTableTransfer
                  dataSource={studentsList}
                  targetKeys={studentTargetKeys}
                  showSearch={true}
                  showSelectAll={false}
                  onChange={studentsOnChange}
                  filterOption={studentsFilterOption}
                  leftColumns={studentsColumns}
                  rightColumns={studentsColumns}
                  titles={[<span style={{ color: '#152E50' }}><b>All Students</b></span>, <span style={{ color: '#152E50' }}><b>Students on {data.user.email} Dashboard</b></span>]}
                  style={{
                    marginTop: 16,
                    marginBottom: 16,
                  }}
                  selectAllLabels={[
                    ({ selectedCount, totalCount }) => (
                      <span>
                        {selectedCount.toLocaleString()} of {totalCount.toLocaleString()}
                        <span> Students selected</span>
                      </span>
                    ),
                    ({ selectedCount, totalCount }) => (
                      <span>
                        {selectedCount.toLocaleString()} of {totalCount.toLocaleString()}
                        <span> Students selected</span>
                      </span>
                    )
                  ]}

                  locale={{ searchPlaceholder: 'Search by Name, Student Code or Date of Birth' }}
                />
              </Panel>
            </Collapse>

          </Card>

          {isSuperAdmin && (
            <Card
              title='Super Admin Meta'
              style={{
                marginTop: 30,
                marginBottom: 30
              }}
            >
              <FormItemsSuperAdmin data={data.user} hasHidden />

            </Card>
          )}

          <FormItemError error={formError} />
          <Affix offsetBottom={20}>
            {/* <Space style={{ borderRadius: '10px', borderTop: 'solid', borderBottom: 'solid'}}> */}
            <Space>
              <Button
                type='primary'
                htmlType='submit'
                loading={insertLoading || updateLoading}
              >
                {id ? 'Update' : 'Add'}
              </Button>
              {id && (
                <Button
                  type='primary'
                  icon={<MailOutlined />}
                  loading={inviteLoading}
                  //fix this eventually, bandaid
                  onClick={handleResetPasswordClick}
                >
                  Send password reset link{' '}
                </Button>
              )}
            </Space>
          </Affix>

        </Form>
      )}
    </>
  )
}

FormComponent.propTypes = {
  data: PropTypes.object.isRequired
}

const UserForm = () => {
  const { id } = useParams()

  const { data, loading } = useQuery(QUERY_USER, {
    variables: { id },
    skip: !id
  })

  const initialValues = { user: { email: '' } }

  return (
    <FormContainer
      loading={loading}
      size={2}
      form={<FormComponent data={{ ...initialValues, ...data }} />}
    />
  )
}

export default UserForm
