import { PlusOutlined } from '@ant-design/icons'
import {
  DatePicker,
  Form,
  Menu,
  Modal,
  Select,
  Spin,
  Switch,
  Typography,
  Row,
  Col,
  Tooltip
} from 'antd'
import Button from 'antd/lib/button'
import Space from 'antd/lib/space'
import Table from 'antd/lib/table'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

// icons
import eligibilityIcon from '../../../assets/icons/eligibility.svg'
import iepIcon from '../../../assets/icons/iep.svg'
import ispIcon from '../../../assets/icons/isp.svg'
import referralIcon from '../../../assets/icons/referral.svg'

//queries and mutations
import ApolloContext from "../../../utils/apolloProvider";
import { useMutation, useQuery } from '@apollo/client'
import { QUERY_STUDENTS } from '../../../operations/queries/students'
import { GET_STUDENT_CURRENT_IEP_MEETING_ID } from '../../../operations/queries/students'
import { QUERY_DESKTOP } from '../../../operations/queries/userInfo'
import { QUERY_DISTRICT, QUERY_DISTRICTS } from '../../../operations/queries/districts'
import { QUERY_LOOKUPS, QUERY_LOOKUP_TYPES } from '../../../operations/queries/lookup'
import {
  INSERT_MEETING,
  REMOVE_STUDENT_FROM_DESKTOP,
  UPDATE_STUDENT_MEETING_GENERAL
} from '../../../operations/mutations/meetings'

//auth and components
import dayjs from 'dayjs'
import AuthContext from '../../../utils/authProvider'
import { notificationError } from '../../../utils/notification'
import LookupSelectGeneric from '../../elements/common/LookupSelectGeneric'
import { DesktopMeetingMenuItem } from './DesktopMeetingMenuItem'
const { Text } = Typography
export const iconSwitch = iconName => {
  switch (iconName) {
    case 'iep':
      return iepIcon
    case 'eligibility':
      return eligibilityIcon
    case 'referral':
      return referralIcon
    case 'isp':
      return ispIcon
    default:
      break
  }
}

const AddMeetingForm = ({
  student,
  visible,
  afterCreated,
  onCancel,
  validationProps
}) => {
  const [form] = Form.useForm()
  const Auth = useContext(AuthContext)
  const [errorMessage, setErrorMessage] = useState()
  const [insertMeeting, { loading: insertMeetingLoading }] =
    useMutation(INSERT_MEETING)
  const [
    updateStudentMeetingGeneral,
    { loading: updateStudentMeetingGeneralLoading }
  ] = useMutation(UPDATE_STUDENT_MEETING_GENERAL)

  const { loading, error, data } = useQuery(
    GET_STUDENT_CURRENT_IEP_MEETING_ID,
    {
      variables: { id: student.id }
    }
  )

  return (
    <Modal
      visible={visible}
      title='New Meeting/Plan'
      okText='Add'
      confirmLoading={
        insertMeetingLoading && updateStudentMeetingGeneralLoading
      }
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={() => {
        setErrorMessage()
        form
          .validateFields()
          .then(async values => {
            form.resetFields()
            let allowed = true
            if (
              values.meetingType === '504 Plan' &&
              values.isActiveIep === true
            ) {
              allowed = false
              notificationError(
                '504 Plan meetings are not allowed to be part of the active IEP'
              )
            }
            if (allowed === true) {
              const response = await insertMeeting({
                variables: {
                  studentMeetingGeneralInput: {
                    schoolId: values.schoolId,
                    studentId: student.id,
                    studentFormIds: [],
                    meetingType: values.meetingType,
                    meetingDate: values.date.format('MM-DD-YYYY'),
                    isActiveIep: values.isActiveIep,
                    iepMeetingId: student.currentIepMeetingId,
                    parentNotifications: []
                  }
                }
              })
              afterCreated(response?.data?.insertStudentMeetingGeneral.id)

              student?.studentMeetingsGeneral?.map(async e => {
                //mutate all the meetings if the new meeting is an active iep and any existing meeting is active,
                // if no meeting type ignore (crash bandaid for old data)
                if (
                  e.meetingType != null &&
                  e.isActiveIep === true &&
                  values.isActiveIep === true
                ) {
                  await updateStudentMeetingGeneral({
                    variables: {
                      id: e.id,
                      schoolId: e.schoolId,
                      studentId: e.studentId,
                      studentFormIds: e.studentFormIds,
                      meetingType: e.meetingType,
                      meetingDate: e.meetingDate,
                      isActiveIep: false,
                      iepMeetingId: e.currentIepMeetingId,
                      parentNotifications: []
                    }
                  })
                }
              })
              Auth.fetchDesktopData()
            }
          })
          .catch(info => {
            console.log(info, 'error')
            setErrorMessage('An error occured while adding the meeting.')
          })
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='addMeetingForm'
        initialValues={{
          schoolId:
            student?.schools.length === 1 ? student?.schools[0].id : undefined
        }}
      >
        <Form.Item
          label='School'
          name='schoolId'
          rules={[
            {
              required: true,
              message: 'Please select a school'
            }
          ]}
        >
          <Select>
            {student?.schools &&
              student?.schools?.map(school => (
                <Select.Option key={school.id} value={school.id}>
                  {school.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <LookupSelectGeneric
          singleMode={true}
          name='meetingType'
          type='Meeting Type'
          placeholder='Please select a meeting/plan type'
        />
        <Form.Item name='date' label='Meeting Date/Plan Start Date'>
          <DatePicker
            format={[
              'MM-DD-YYYY',
              'MM/DD/YYYY',
              'MM.DD.YY',
              'M-D-YYYY',
              'M/D/YYYY',
              'M.D.YYYY',
              'MM.DD.YYYY',
              'MM-DD-YY',
              'MM/DD/YY',
              'M-D-YY',
              'M/D/YY',
              'M.D.YY',
              'MMDDYYYY',
              'MMDDYY'
            ]}
          />
        </Form.Item>
        <Form.Item
          name='isActiveIep'
          label='Active'
          rules={[
            {
              required: true
            }
          ]}
          initialValue={true}
        >
          <Switch defaultChecked={true} />
        </Form.Item>
        {errorMessage && <Text type='danger'>{errorMessage}</Text>}
      </Form>
    </Modal>
  )
}

AddMeetingForm.propTypes = {
  student: PropTypes.object,
  visible: PropTypes.bool,
  afterCreated: PropTypes.func,
  onCancel: PropTypes.func
}

const Listing = ({ addMeeting }) => {

  const Auth = useContext(AuthContext)
  // unnecessary; grabs available plans
  // const districtAdmin = Auth.hasRole('DISTRICTS_ADMIN')
  // const superAdmin = Auth.hasRole('SUPER_ADMIN')
  // let onlyHealthPlan = false

  // const { loading: lookupLoading, data: lookupData } = useQuery(QUERY_LOOKUPS, {
  //   variables: {
  //     type: 'Meeting Type',
  //     districtId: districtAdmin
  //       ? Auth?.currentDistrictId?.id
  //       : Auth?.districtsAdmin[0]?.id,
  //     skip: superAdmin
  //   }
  // })
  // let lookupdataLength = !lookupLoading && lookupData?.lookups?.length
  // if (lookupData) {
  //   lookupData?.lookups?.map(x => {
  //     if (x.value === "Health Plan" && lookupdataLength === 1) {
  //       console.log('the check worked')
  //       onlyHealthPlan = true
  //     }
  //   })
  // }

  //Following students query is strictly for performance.
  //It does a background load of students into cache so that they are already loaded when a user navigates to the students list.
  //Need to use apollo.query directly(not useQuery hook) because useQuery queries are cancelled on re-renders(e.g. user moves around ui)
  const apollo = useContext(ApolloContext);
  const response = apollo.client.query({
    query: QUERY_STUDENTS,
    variables: { offset: 0, limit: 10 },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    enabled: false,
    staleTime: Infinity
  });

  const [removeStudentAction] = useMutation(REMOVE_STUDENT_FROM_DESKTOP)
  const desktopData = Auth?.desktopData
  if (desktopData === null) {
    Auth.fetchDesktopData()
  }
  const [filteredData, setFilteredData] = useState(desktopData)
  const removeStudentFromDesktop = async studentId => {
    // await fixes triple click
    await removeStudentAction({
      variables: { studentId: studentId },
      update(cache, { data: desktopData }) {
        const data = cache.readQuery({ query: QUERY_DESKTOP })

        if (data && cache) {
          cache.writeQuery({
            query: QUERY_DESKTOP,
            data: data
          })
        }


      }
    })
    Auth.fetchDesktopData()
  }

  const exportCsv = () => {
    const replacer = (key, value) => (value === null ? 'N/A' : value) // specify how you want to handle null values here
    const header = Object.keys(filteredData[1])
    let filename = 'test_csv'
    let csv = filteredData?.map(row => {
      if (header)
        header
          .map(fieldName => JSON.stringify(row[fieldName], replacer))
          .join(',')
    })
    csv.unshift(header.join(','))
    csv = csv.join('\r\n')

    // Create link and download
    var link = document.createElement('a')
    link.setAttribute(
      'href',
      'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv)
    )
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const CustomExportCsv = () => {
    return (
      <Button
        // icon={<FileExcelOutlined />}
        type='primary'
        onClick={exportCsv}
      >
        Export as CSV
      </Button>
    )
  }

  const exportPdf = () => {
    // Default export is a4 paper, portrait, using millimeters for units
    // can be done by hand not making a table as well
    // filteredData.map(i => {
    //   doc.text(i.fullName, 20, 10 + i * 10)
    // })
    // doc.text(filteredData, 10, 10)
    let bodyArray = []
    const header = Object.keys(filteredData[0])
    filteredData?.map(i => {
      if (header) {
        bodyArray.push(Object.values(i))
      }
    })
    const doc = new jsPDF()
    autoTable(doc, {
      head: [header],
      body: bodyArray
    })
    doc.save('a4.pdf')
  }

  const CustomExportPdf = () => {
    return (
      <Button
        // icon={<FileExcelOutlined />}
        type='primary'
        onClick={exportPdf}
      >
        Export as PDF
      </Button>
    )
  }

  const meetingItems = (meetings, icons = false) => {
    if (meetings === undefined || meetings.length === 0) {
      return (
        <Space
          direction='horizontal'
          style={{ width: '100%', justifyContent: 'center' }}
        >
          No Meetings
        </Space>
      )
    }
    if (meetings) {
      return meetings.studentMeetingsGeneral?.map((record, index) => (
        <DesktopMeetingMenuItem
          key={index}
          id={record.id}
          meetingDate={record.meetingDate}
          studentId={record.studentId}
          schoolId={record.schoolId}
          districtId={record?.district?.id}
          meetingType={record.meetingType}
          iepMeetingId={record.iepMeetingId}
          parentNotifications={record.Notifications}
        />
      ))
    }

  }
  const filterTheSchools = () => {
    let setSchools = new Set()
    let antdSchools = []
    desktopData?.map(e => {
      e?.schools?.map(x => {
        if (x.name != undefined || null) {
          setSchools.add(x.name)
        }
      })
    })
    const filterSchools = [...setSchools]
    filterSchools?.map(e => {
      antdSchools.push({ text: e, value: e })
    })
    return antdSchools
  }

  const filterTheGrades = () => {
    let setGrades = new Set()
    let antdGrades = []
    desktopData?.map(e => {
      if (e.grade != undefined || null) {
        setGrades.add(e.grade)
      }
    })
    const filterGrades = [...setGrades]
    filterGrades?.map(e => antdGrades.push({ text: e, value: e }))
    return antdGrades
  }

  const expandedRowRender = row => {
    const filterTheMeetings = () => {
      let setMeetings = new Set()
      let antdMeetings = []
      row.studentMeetingsGeneral.map(e => {
        setMeetings.add(e.meetingType)
      })
      const filterMeetings = [...setMeetings]
      filterMeetings?.map(e => {
        antdMeetings.push({ text: e, value: e })
      })
      return antdMeetings
    }

    const columns = [
      {
        title: 'Type',
        filters: filterTheMeetings(),
        dataIndex: 'studentMeetingsGeneral',
        filterMultiple: true,
        // defaultFilteredValue: ['IEP', 'Referral', 'ISP', 'Evaluation & Eligibility'],
        // filterResetToDefaultFilteredValue: true,

        onFilter: (value, records, record) => {
          if (records.meetingType != undefined) {
            return records.meetingType.includes(value)
          }
        },
        render: (record, records) => {
          return <>{records.meetingType}</>
        }
      },
      {
        title: 'Date',
        dataIndex: 'studentMeetingsGeneral',
        sorter: (a, b) =>
          dayjs(a.meetingDate).unix() - dayjs(b.meetingDate).unix(),
        render: (record, records) => {
          return <> {dayjs(records.meetingDate).format('MM-DD-YYYY')}</>
        }
      },
      {
        title: 'Active',
        dataIndex: 'studentMeetingsGeneral',

        render: (record, records) => {
          return (
            <>{records.isActiveIep === true ? <>ACTIVE</> : <>INACTIVE</>}</>
          )
        }
      },
      {
        title: '',
        dataIndex: 'studentMeetingsGeneral',

        render: (record, records) => {
          return (
            // pass props through link
            <Link
              to={`/student-form/${records.id}`}
              state={{ studentData: records }}
            >
              <Button>Edit</Button>
            </Link>
          )
        }
      }
    ]

    const fields = {
      students: {
        header: 'Active IEP',
        formatter: fieldValue => fieldValue
      },
      ieps: {
        header: 'Number of IEPs',
        formatter: fieldValue => fieldValue
      },
      users: {
        header: 'Number of users',
        formatter: fieldValue => fieldValue
      }
    }

    return (
      <>
        <Table
          columns={columns}
          dataSource={row.studentMeetingsGeneral}
          pagination={false}
          searchable={true}
        />
      </>
    )
  }
  // In the fifth row, other columns are merged into first column
  // by setting it's colSpan to be 0
  const sharedOnCell = (_, index) => {
    if (index === 1) {
      return {
        colSpan: 0,
      };
    }
    return {};
  };
  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      render: (value, record) => (
        <>
          <Link to={{ pathname: `./student/${record.id}` }}>{value}</Link>&nbsp;
        </>
      )
    },
    {
      title: 'School',
      dataIndex: 'schools',
      filters: filterTheSchools(),
      onFilter: (value, records) => {
        if (records.schools[0] != undefined) {
          return records.schools[0].name.indexOf(value) === 0
        }
      },
      render: records => {
        return <>{records?.map(e => e.name)}</>
      }
    },
    {
      title: 'Grade',
      dataIndex: ['grade'],
      filters: filterTheGrades(),
      onFilter: (value, records) => {
        if (records.grade != null) {
          return records.grade.indexOf(value) === 0
        }
      }
    },
    {
      title: 'Next Annual Review Date',
      dataIndex: 'nextAnnualReviewDate',
      sorter: (a, b) =>
        dayjs(a.meetingDate).unix() - dayjs(b.nextAnnualReviewDate).unix(),
      render: (record, records) => {
        return <>{records.nextAnnualReviewDate == null ? <>None</> : <>{dayjs(records.nextAnnualReviewDate).format('MM-DD-YYYY')}</>} </>
      }
    },
    {
      title: 'Re-evaluation Consent Date',
      dataIndex: 'reEvaluationConsentDate',
      sorter: (a, b) =>
        dayjs(a.meetingDate).unix() - dayjs(b.reEvaluationConsentDate).unix(),
      render: (record, records) => {
        return <>{records.reEvaluationConsentDate == null ? <>None</> : <>{dayjs(records.reEvaluationConsentDate).format('MM-DD-YYYY')}</>} </>
      }
    },
    {
      title: <div style={{ textAlign: 'center', width: '199%', }}>Actions</div>,
      colSpan: 2,
    },
    {
      // title: <div style={{ textAlign: 'center' }}>Actions</div>,
      // onCell: sharedOnCell,
      colSpan: 2,
      render: (records, record, id) => {
        const menu = <Menu>{meetingItems(records, true)}</Menu>
        return (
          <>
            <Space size='small'>
              <Tooltip title="Add a meeting or plan.">
                <Button type='primary' onClick={() => addMeeting(record)}>
                  <PlusOutlined />
                  Add
                  {/* {!lookupLoading && onlyHealthPlan === false ? <>Add a Meeting</> : <>Add a Plan</>} */}
                </Button>
              </Tooltip>
            </Space>
          </>
        )
      }
    },
    {
      title: <div ></div>,
      colSpan: 0,
      dataIndex: 'id',
      render: id => (
        <Space size='small'>
          <Tooltip title="Remove student from dashboard.">
            <Button
              danger
              type='primary'
              onClick={() => removeStudentFromDesktop(id)}
            >
              Remove
            </Button>
          </Tooltip>
        </Space>
      )
    }
  ]
  const fields = {
    students: {
      header: 'Grade',
      formatter: fieldValue => fieldValue
    },
    ieps: {
      header: 'Number of IEPs',
      formatter: fieldValue => fieldValue
    },
    users: {
      header: 'Number of users',
      formatter: fieldValue => fieldValue
    }
  }
  return (
    <>
      {desktopData != [] && desktopData != undefined ? (
        <>
          <Table
            columns={columns}
            // loading={loading}
            dataSource={desktopData}
            onChange={(value, selectedRowKeys, selectedRows, info) => {
              setFilteredData(info?.currentDataSource)
            }}
            expandable={{
              expandedRowRender,
              defaultExpandAllRows: false,
            }}
            rowKey={record => record.id}
          // scroll={{ x: 'calc(400px + 50%)' }}
          />
          {/* commented out for now, functional */}
          {/* <CustomExportCsv /> <CustomExportPdf />{' '} */}
        </>
      ) : (
        <>
          <Spin />
        </>
      )}
    </>
  )
}

Listing.propTypes = {
  addMeeting: PropTypes.func
}

const DesktopStudents = () => {
  const [addMeetingVisible, setAddMeetingVisible] = useState(false)
  const [addMeetingStudent, setAddMeetingStudent] = useState()
  const history = useNavigate()

  const addMeeting = record => {
    setAddMeetingStudent(record)
    setAddMeetingVisible(true)
  }

  const afterMeetingCreated = meetingId => {
    setAddMeetingVisible(false)
    setAddMeetingStudent()
    history('/student-form/' + meetingId)
  }

  return (
    <>
      <Listing addMeeting={addMeeting} />
      {addMeetingVisible && (
        <AddMeetingForm
          student={addMeetingStudent}
          visible={addMeetingVisible}
          afterCreated={afterMeetingCreated}
          onCancel={() => {
            setAddMeetingVisible(false)
          }}
        />
      )}
    </>
  )
}

export default DesktopStudents
