import React from 'react';
import logoBig from './img/icons/logo512.png'
import logoSmall from './img/icons/logo192.png'
import tamesAssistLogo from './img/icons/tames_assist_logo.png'



import developer from './img/svg/developer.svg'
import waving from './img/svg/waving.svg'
import medium from './img/svg/medium.svg'
import graphs from './img/svg/graphs.svg'
import logo from './img/svg/logo.svg'
import productLaunch from './img/svg/product-launch.svg'
import scrollTop from './img/svg/scroll-top.svg'
import notes from './img/svg/notes.svg'
import collab from './img/svg/collab.png'
import goals from './img/svg/goals.png'
import history from './img/svg/history.png'

import dashboard from './img/svg/dashboard.svg'
import teamwork from './img/svg/teamwork.jpg'



export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: tamesAssistLogo
  },
  Menu: {
    className: 'header0-menu',
    children: [

      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: 'about',
          children: [{ children: 'About Us', name: 'text' }],
        },
      },
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: 'signin',
          children: [{ children: 'Login', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};

export const Nav01DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: tamesAssistLogo
  },
  Menu: {
    className: 'header0-menu',
    children: [

      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '/',
          children: [{ children: 'Home', name: 'text' }],
        },
      },
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: 'signin',
          children: [{ children: 'Login', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 0 },
  img: {
    children: waving,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'TAMES is a pioneer in special education services.' },
  content: {
    className: 'content1-content',
    children:
      'We are your dedicated partners, developing tools to make your life easier and offering customized solutions at the lowest prices.',
  },
};

export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 0 },
  img: {
    children: history,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'We Make Data Migration Easy' },
  content: {
    className: 'content1-content',
    children: 'TAMESAssist will allow you to import previous IEPs and 504s from other platforms as detailed PDF files. You can easily view previous IEP documents side-by-side with your new forms!'
  },
};

export const Feature30DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 0 },
  img: {
    children: goals,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Robust goal tracking' },
  content: {
    className: 'content1-content',
    children: 'TAMESAssist provides a built-in goal tracking system. Upload charts, review goal history, and update goals all in one application!'
  },
};

export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 0 },
  img: {
    children: teamwork,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Form Collaboration' },
  content: {
    className: 'content1-content',
    children: 'TAMESAssist offers seamless form collaboration, so you and the entire team can work on forms simultaneously to speed up the IEP process!'
  },
};
export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'info@tameshrs.com | 800-848-0157',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Want to Learn More?' },
  content: {
    className: 'content1-content',
    children: "Schedule a demo today! Once you're ready to take the next step, we offer free training for administrators and staff!"
  },
};
export const Banner00DataSource = {

};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<><img src={notes} /></>),
              // 'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'We Make Data Migration Easy',
            },
            { name: 'content', children: 'TAMESAssist will allow you to import previous IEPs and 504s from other platforms as detailed PDF files. You can easily view previous IEP documents side-by-side with your new forms!' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<><img src={productLaunch} /></>),
              // 'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Robust goal tracking',
            },
            {
              name: 'content',
              children: 'TAMESAssist provides a built-in goal tracking system. Upload charts, review goal history, and update goals all in one application!',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<><img src={dashboard} /></>),
              // 'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Form Collaboration',
            },
            {
              name: 'content',
              children: 'TAMESAssist offers seamless form collaboration, so you and the entire team can work on forms simultaneously to speed up the IEP process!',
            },
          ],
        },
      },
    ],
  },
};
export const Content10DataSource = {
  // wrapper: { className: 'home-page-wrapper content1-wrapper' },
  // OverPack: { className: 'home-page content1', playScale: 0.3 },
  // imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  // img: {
  //   children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  // },
  // textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  // title: { className: 'content1-title', children: '企业资源管理' },
  // content: {
  //   className: 'content1-content',
  //   children:
  //     '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
  // },
};
export const Content30DataSource = {
  // wrapper: { className: 'home-page-wrapper content3-wrapper' },
  // page: { className: 'home-page content3' },
  // OverPack: { playScale: 0.3 },
  // titleWrapper: {
  //   className: 'title-wrapper',
  //   children: [
  //     {
  //       name: 'title',
  //       children: '蚂蚁金融云提供专业的服务',
  //       className: 'title-h1',
  //     },
  //     {
  //       name: 'content',
  //       className: 'title-content',
  //       children: '基于阿里云强大的基础资源',
  //     },
  //   ],
  // },
  // block: {
  //   className: 'content3-block-wrapper',
  //   children: [
  //     {
  //       name: 'block0',
  //       className: 'content3-block',
  //       md: 8,
  //       xs: 24,
  //       children: {
  //         icon: {
  //           className: 'content3-icon',
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
  //         },
  //         textWrapper: { className: 'content3-text' },
  //         title: { className: 'content3-title', children: '企业资源管理' },
  //         content: {
  //           className: 'content3-content',
  //           children:
  //             '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
  //         },
  //       },
  //     },
  //     {
  //       name: 'block1',
  //       className: 'content3-block',
  //       md: 8,
  //       xs: 24,
  //       children: {
  //         icon: {
  //           className: 'content3-icon',
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
  //         },
  //         textWrapper: { className: 'content3-text' },
  //         title: { className: 'content3-title', children: '云安全' },
  //         content: {
  //           className: 'content3-content',
  //           children:
  //             '按金融企业安全要求打造的完整云上安全体系，全方位保障金融应用及数据安全。',
  //         },
  //       },
  //     },
  //     {
  //       name: 'block2',
  //       className: 'content3-block',
  //       md: 8,
  //       xs: 24,
  //       children: {
  //         icon: {
  //           className: 'content3-icon',
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
  //         },
  //         textWrapper: { className: 'content3-text' },
  //         title: { className: 'content3-title', children: '云监控' },
  //         content: {
  //           className: 'content3-content',
  //           children:
  //             '分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。',
  //         },
  //       },
  //     },
  //     {
  //       name: 'block3',
  //       className: 'content3-block',
  //       md: 8,
  //       xs: 24,
  //       children: {
  //         icon: {
  //           className: 'content3-icon',
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
  //         },
  //         textWrapper: { className: 'content3-text' },
  //         title: { className: 'content3-title', children: '移动' },
  //         content: {
  //           className: 'content3-content',
  //           children:
  //             '一站式移动金融APP开发及全面监控；丰富可用组件，动态发布和故障热修复。',
  //         },
  //       },
  //     },
  //     {
  //       name: 'block4',
  //       className: 'content3-block',
  //       md: 8,
  //       xs: 24,
  //       children: {
  //         icon: {
  //           className: 'content3-icon',
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
  //         },
  //         textWrapper: { className: 'content3-text' },
  //         title: { className: 'content3-title', children: '分布式中间件' },
  //         content: {
  //           className: 'content3-content',
  //           children:
  //             '金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。',
  //         },
  //       },
  //     },
  //     {
  //       name: 'block5',
  //       className: 'content3-block',
  //       md: 8,
  //       xs: 24,
  //       children: {
  //         icon: {
  //           className: 'content3-icon',
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
  //         },
  //         textWrapper: { className: 'content3-text' },
  //         title: { className: 'content3-title', children: '大数据' },
  //         content: {
  //           className: 'content3-content',
  //           children:
  //             '一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。',
  //         },
  //       },
  //     },
  //   ],
  // },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        © {new Date().getFullYear()} <a>TAMES/Health Resource Service</a>{' '}
        Management, Inc.
      </span>
    ),
  },
};
