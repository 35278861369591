import React, { useContext, Fragment } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import { useQuery } from '@apollo/client'

// ant design
import { Form, DatePicker, Alert, Typography, Card, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import FormContainer from '../../../FormContainer'
import Title from 'antd/lib/typography/Title'

import { PRINT_FORM3457BC } from '../../../../operations/mutations/forms/3457BC'
import { STUDENT_INFO_FORM3457A } from '../../../../operations/queries/forms/3457A'
import { QUERY_FORM3457BC_VERSION } from '../../../../operations/queries/forms/3457BC'

import FormCollaboration from '../../../FormCollaboration'

import { IepFormContext } from '../../../../utils/iepFormProvider'
import InfoArea3457BC from './parts/InfoArea3457BC'
import StudentFormActions from '../StudentFormActions'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    const keys = Object.keys(formFieldValues)
    const lookupFieldValues = {}
    keys.forEach(x => {
      if (x.indexOf('Sources') > 0) {
        if (formFieldValues[x]) {
          lookupFieldValues[x] = formFieldValues[x].join('|')
        } else {
          lookupFieldValues[x] = ''
        }
      }
    })

    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId,
      ...lookupFieldValues
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = dayjs(fieldValue)
        break
      case 'academicSources':
      case 'functionalSources':
      case 'cognitiveSources':
      case 'communicationSources':
      case 'performanceSources':
      case 'hearingSources':
      case 'motorSources':
      case 'socialSources':
        update[fieldName] =
          fieldValue !== ' ' ? fieldValue.split(',') : undefined
        break
      case 'academicRelevance':
      case 'functionalRelevance':
      case 'cognitiveRelevance':
      case 'communicationRelevance':
      case 'performanceRelevance':
      case 'hearingRelevance':
      case 'motorRelevance':
      case 'socialRelevance':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const formDomains = [
    {
      name: 'academic',
      title: 'Academic Achievement',
      description:
        'Current or past academic achievement data  pertinent to current educational performance'
    },
    {
      name: 'functional',
      title: 'Functional Performance',
      description:
        'Current or past functional performance data  pertinent to current functional performance.'
    },
    {
      name: 'cognitive',
      title: 'Cognitive Functioning',
      description:
        'Data regarding cognitive ability, how the child takes in information, understands information and expresses information.'
    },
    {
      name: 'communication',
      title: 'Communication Status',
      description:
        'Information regarding communicative abilities (language, articulation, voice, fluency) affecting educational performance.'
    },
    {
      name: 'performance',
      title: 'Health',
      description:
        'Current or past medical difficulties affecting educational performance.'
    },
    {
      name: 'hearing',
      title: 'Hearing/Vision',
      description:
        'Auditory/visual problems that would interfere with  testing or educational performance. Dates and  results of last hearing/visual test.'
    },
    {
      name: 'motor',
      title: 'Motor Abilities',
      description:
        'Fine and gross motor coordination difficulties, functional mobility, or strength and endurance  issues affecting educational performance.'
    },
    {
      name: 'social',
      title: 'Social/Emotional Status',
      description:
        'Information regarding how the environment affects  educational performance (life history, adaptive  behavior, independent function, personal and  social responsibility, cultural)'
    }
  ]

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457BC'
            name='studentForm3457BC'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              PARENT/GUARDIAN CONSENT FOR EVALUATION IDENTIFICATION OF NEEDED
              ASSESSMENT
            </Title>

            {
              // #region sis
            }
            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>

            {
              // #endregion sis
            }
            <Form.Item
              label='Form Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            {formDomains.map(({ name, title, description }, index) => (
              <Card
                title={title}
                key={index}
                style={{
                  marginBottom: 30
                }}
                extra={
                  <Tooltip placement='left' title={description}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              >
                <InfoArea3457BC
                  data={data}
                  form={form}
                  name={name}
                  handleFocusEvents={handleFocusEvents}
                  handleMouseEvents={handleMouseEvents}
                  handleEditorEvents={handleEditorEvents}
                  formDisabledState={formDisabledState}
                  validationProps={validationProps}
                />
              </Card>
            ))}

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3457BC}
              extractDataProp='printStudentForm3457BC'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3457BC = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    STUDENT_INFO_FORM3457A,
    { variables: { id: studentId } }
  )

  // Load form data
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3457BC_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    if (!studentInfo || !versionInfo) return {}

    const { student } = studentInfo
    const { studentFullName, formDate } = versionInfo.studentForm3457BC

    const keys = Object.keys(versionInfo.studentForm3457BC)

    const mappedKeys = {}

    keys.forEach(x => {
      if (x.indexOf('Sources') > 0) {
        if (versionInfo.studentForm3457BC[x]) {
          mappedKeys[x] = versionInfo.studentForm3457BC[x].split('|')
        } else {
          mappedKeys[x] = []
        }
      }
    })

    // on init server returns an empty state for version - use this to set good defaults, load data
    return {
      ...versionInfo.studentForm3457BC,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName,
      ...mappedKeys
    }
  }

  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}

export default StudentForm3457BC
