import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM504C_VERSION } from '../../../../operations/queries/forms/504C'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Input, Radio, Typography } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent1PhoneSIS from '../sis/StudentParent1PhoneSIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'
import StudentParent2PhoneSIS from '../sis/StudentParent2PhoneSIS'

import FormItem from 'antd/lib/form/FormItem'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM504C } from '../../../../operations/mutations/forms/504C'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    console.log(fieldName, fieldValue)
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
      case 'annualReviewDate':
      case 'triennialReassessmentDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const meetingAttendees = [
    {
      label: 'Parent 1',
      name: 'meetingAttendedParent1'
    },
    {
      label: 'Parent 2',
      name: 'meetingsAttendedParent2'
    },
    {
      label: 'Student',
      name: 'meetingAttendedStudent'
    },
    {
      label: 'LEA Representative',
      name: 'meetingAttendedLEARepresentative'
    },
    {
      label: 'General Education Teacher',
      name: 'meetingAttendedGeneralEducationTeacher'
    },
    {
      label: 'Special Education Teacher',
      name: 'meetingAttendedSpecialEducationTeacher'
    },
    {
      label: 'School Psychologist',
      name: 'meetingAttendedSchoolPsychologist'
    },
    {
      label: 'Social Worker',
      name: 'meetingAttendedSchoolSocialWorker'
    },
    {
      label: 'Speech/Language Pathologist',
      name: 'meetingAttendedSpeechLanguagePathologist'
    },
    {
      label: 'Bilingual Specialist',
      name: 'meetingAttendedBilingualSpecialist'
    },
    {
      label: 'Interpreter',
      name: 'meetingAttendedInterpreter'
    },
    {
      label: 'School Nurse',
      name: 'meetingAttendedSchoolNurse'
    },
    {
      label: 'Other Attendee',
      name: 'meetingAttendedOther'
    }
  ]

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm504C'
            name='studentForm504C'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              504 PLAN
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
            </Card>

            <Card
              title='504 Plan'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label="Please indicate the student's mental and/or physical impairment:"
                name='studentImpairment'
                {...validationProps('studentImpairment')}
              >
                <Editor
                  formField='studentImpairment'
                  disabled={formDisabledState.studentImpairment}
                  {...handleEditorEvents}
                />
              </Form.Item>
              <Form.Item
                label="Please describe in detail the major life activity that is substantially limited by the student's physical and/or mental impairment:"
                name='studentLifeActivityLimited'
                {...validationProps('studentLifeActivityLimited')}
              >
                <Editor
                  formField='studentLifeActivityLimited'
                  disabled={formDisabledState.studentLifeActivityLimited}
                  {...handleEditorEvents}
                />
              </Form.Item>
              <Form.Item
                label="List the accommodations and/or services and supports necessary to limit the impact of the student's impairment on their educational experience:"
                name='studentAccommodations'
                {...validationProps('studentAccommodations')}
              >
                <Editor
                  formField='studentAccommodations'
                  disabled={formDisabledState.studentAccommodations}
                  {...handleEditorEvents}
                />
              </Form.Item>
              <Form.Item
                label='Necessary accommodations for State- and District-Wide Assessments:'
                name='studentAccommodationsForStateAndDistrict'
                {...validationProps('studentAccommodationsForStateAndDistrict')}
              >
                <Editor
                  formField='studentAccommodationsForStateAndDistrict'
                  disabled={
                    formDisabledState.studentAccommodationsForStateAndDistrict
                  }
                  {...handleEditorEvents}
                />
              </Form.Item>
              <Form.Item
                label='Additional comments:'
                name='additionalComments'
                {...validationProps('additionalComments')}
              >
                <Editor
                  formField='additionalComments'
                  disabled={formDisabledState.additionalComments}
                  {...handleEditorEvents}
                />
              </Form.Item>

              <Form.Item
                label='Annual review date'
                name='annualReviewDate'
                {...validationProps('annualReviewDate')}
              >
                <DatePicker
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                  {...handleFocusEvents}
                  disabled={formDisabledState.annualReviewDate}
                />
              </Form.Item>
              <Form.Item
                label='Triennial reassessment date'
                name='triennialReassessmentDate'
                {...validationProps('triennialReassessmentDate')}
              >
                <DatePicker
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                  {...handleFocusEvents}
                  disabled={formDisabledState.triennialReassessmentDate}
                />
              </Form.Item>
              <FormItem
                label='504 Coordinator(Name and/or title):'
                name='districtContact'
                {...validationProps('districtContact')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.districtContact}
                />
              </FormItem>
            </Card>

            <Card
              title="Participant's Information"
              style={{
                marginBottom: 30
              }}
            >
              {meetingAttendees.map(({ label, name }, index) => (
                <FormItem
                  key={index}
                  label={label}
                  name={name}
                  validateStatus={itemErrors[name] ? 'error' : ''}
                  help={itemErrors[name]}
                  {...validationProps(name)}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState[name]}
                  />
                </FormItem>
              ))}

              <FormItem
                label='Other Attendee Description'
                name='meetingsAttendedOtherText'
                {...validationProps('meetingsAttendedOtherText')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.meetingsAttendedOtherText}
                />
              </FormItem>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM504C}
              extractDataProp='printStudentForm504C'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm504C = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM504C_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )
  console.log(formData)
  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}
    if (!studentInfo || !versionInfo) return initialValues
    const { studentForm504C: form } = versionInfo
    console.log('versionInfo', versionInfo, form)
    const { student } = studentInfo
    const { dateOfBirth } = student
    const {
      formDate,
      studentFullName,
      studentDob,
      studentImpairment,
      studentLifeActivityLimited,
      studentAccommodations,
      studentAccommodationsForStateAndDistrict,
      additionalComments,
      annualReviewDate,
      triennialReassessmentDate,
      districtContact,
      meetingAttendedParent1,
      meetingAttendedParent2,
      meetingAttendedStudent,
      meetingAttendedLEARepresentative,
      meetingAttendedGeneralEducationTeacher,
      meetingAttendedSpecialEducationTeacher,
      meetingAttendedSchoolPsychologist,
      meetingAttendedSchoolSocialWorker,
      meetingAttendedSpeechLanguagePathologist,
      meetingAttendedBilingualSpecialist,
      meetingAttendedInterpreter,
      meetingAttendedSchoolNurse,
      meetingAttendedOther,
      meetingAttendedOtherText
    } = form
    console.log('form', form)

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? dayjs(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
          ? dayjs(dateOfBirth).format('YYYY-MM-DD')
          : '',

      studentImpairment: studentImpairment ? studentImpairment : '',
      studentLifeActivityLimited: studentLifeActivityLimited
        ? studentLifeActivityLimited
        : '',
      studentAccommodations: studentAccommodations ? studentAccommodations : '',
      studentAccommodationsForStateAndDistrict:
        studentAccommodationsForStateAndDistrict
          ? studentAccommodationsForStateAndDistrict
          : '',
      additionalComments: additionalComments ? additionalComments : '',
      annualReviewDate: annualReviewDate
        ? dayjs(annualReviewDate)
        : dayjs(Date.now()),
      triennialReassessmentDate: triennialReassessmentDate
        ? dayjs(triennialReassessmentDate)
        : dayjs(Date.now()),

      districtContact: districtContact ? districtContact : '',
      meetingAttendedParent1: meetingAttendedParent1
        ? meetingAttendedParent1
        : '',
      meetingAttendedParent2: meetingAttendedParent2
        ? meetingAttendedParent2
        : '',
      meetingAttendedStudent: meetingAttendedStudent
        ? meetingAttendedStudent
        : '',
      meetingAttendedLEARepresentative: meetingAttendedLEARepresentative
        ? meetingAttendedLEARepresentative
        : '',
      meetingAttendedGeneralEducationTeacher:
        meetingAttendedGeneralEducationTeacher
          ? meetingAttendedGeneralEducationTeacher
          : '',
      meetingAttendedSpecialEducationTeacher:
        meetingAttendedSpecialEducationTeacher
          ? meetingAttendedSpecialEducationTeacher
          : '',
      meetingAttendedSchoolPsychologist: meetingAttendedSchoolPsychologist
        ? meetingAttendedSchoolPsychologist
        : '',
      meetingAttendedSchoolSocialWorker: meetingAttendedSchoolSocialWorker
        ? meetingAttendedSchoolSocialWorker
        : '',
      meetingAttendedSpeechLanguagePathologist:
        meetingAttendedSpeechLanguagePathologist
          ? meetingAttendedSpeechLanguagePathologist
          : '',
      meetingAttendedBilingualSpecialist: meetingAttendedBilingualSpecialist
        ? meetingAttendedBilingualSpecialist
        : '',
      meetingAttendedInterpreter: meetingAttendedInterpreter
        ? meetingAttendedInterpreter
        : '',
      meetingAttendedSchoolNurse: meetingAttendedSchoolNurse
        ? meetingAttendedSchoolNurse
        : '',
      meetingAttendedOther: meetingAttendedOther ? meetingAttendedOther : '',
      meetingAttendedOtherText: meetingAttendedOtherText
        ? meetingAttendedOtherText
        : ''
    }

    return initialValues
  }

  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm504C
