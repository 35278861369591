import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORMTAMES4_VERSION } from '../../../../operations/queries/forms/tames4'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Radio, Typography } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORMTAMES4 } from '../../../../operations/mutations/forms/tames4'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      case 'determinationSpecificLearningDisability':
      case 'intellectualGeneralIntelligence':
      case 'adaptiveBehavior':
      case 'normTest':
      case 'criterionTest':
      case 'portfolio':
      case 'observations':
      case 'interview':
      case 'hearingScreeningConductedBy':
      case 'other':
      case 'intellectualCriteria':
      case 'determinantEnvironmentalCultural':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const formFields = [
    {
      label: 'General Intelligence',
      name: 'intellectualGeneralIntelligence'
    },
    {
      label: 'Adaptive Behavior',
      name: 'adaptiveBehavior'
    }
  ]

  const formFields2 = [
    {
      label: 'Norm Referenced Test',
      name: 'normTest'
    },
    {
      label: 'Criterion Referenced Test',
      name: 'criterionTest'
    },
    {
      label: 'Portfolio',
      name: 'portfolio'
    },
    {
      label: 'Observations',
      name: 'observations'
    },
    {
      label: 'Interview',
      name: 'interview'
    },
    {
      label: 'Hearing Screening Conducted By',
      name: 'hearingScreeningConductedBy'
    },
    {
      label: 'Other',
      name: 'other'
    }
  ]

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentFormTames4'
            name='studentFormTames4'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              DISABILITIES DETERMINATION
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
            </Card>

            <Card
              title='Determination of Specific Learning Disability'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label=' '
                name='determinationSpecificLearningDisability'
                {...validationProps('determinationSpecificLearningDisability')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={
                    formDisabledState.determinationSpecificLearningDisability
                  }
                  name='determinationSpecificLearningDisability'
                >
                  <Radio value style={{ whiteSpace: 'break-spaces' }}>
                    The student meets the specific learning disability criteria.
                  </Radio>
                  <Radio value={false} style={{ whiteSpace: 'break-spaces' }}>
                    The student does not meet the specific learning disability
                    criteria.
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Card>

            <Card
              title='Determination of Intellectual Disability'
              style={{
                marginBottom: '30px'
              }}
            >
              <p>
                The student demonstrates significantly subaverage functioning in
                BOTH of the following areas, which was manifested during the
                development period:
              </p>
              {formFields.map(({ name, label }, index) => (
                <Card
                  key={index}
                  style={{
                    marginBottom: '30px'
                  }}
                >
                  <Form.Item
                    label={label}
                    name={name}
                    {...validationProps(name)}
                  >
                    <Radio.Group
                      {...handleMouseEvents}
                      buttonStyle='solid'
                      disabled={formDisabledState[name]}
                      name={name}
                    >
                      <Radio.Button value>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Card>
              ))}

              <p>The following assessments were used:</p>
              {formFields2.map(({ name, label }, index) => (
                <Card
                  key={index}
                  style={{
                    marginBottom: '30px'
                  }}
                >
                  <Form.Item
                    label={label}
                    name={name}
                    {...validationProps(name)}
                  >
                    <Radio.Group
                      {...handleMouseEvents}
                      buttonStyle='solid'
                      disabled={formDisabledState[name]}
                      name={name}
                    >
                      <Radio.Button value>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Card>
              ))}

              <Form.Item
                name='descriptiveAnalysis'
                label='Descriptive Analysis (Optional):'
                {...validationProps('descriptiveAnalysis')}
              >
                <Editor
                  formField='descriptiveAnalysis'
                  disabled={formDisabledState.descriptiveAnalysis}
                  {...handleEditorEvents}
                />
              </Form.Item>

              <Form.Item
                label='Intellectual Criteria'
                name='intellectualCriteria'
                {...validationProps('intellectualCriteria')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.intellectualCriteria}
                  name='intellectualCriteria'
                >
                  <Radio value style={{ whiteSpace: 'break-spaces' }}>
                    The student meets the Intellectual disability criteria and
                    the eligibility team concurs.
                  </Radio>
                  <Radio value={false} style={{ whiteSpace: 'break-spaces' }}>
                    The student does not meet the Intellectual disability
                    criteria.
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label='Environmental and Cultural Factors'
                name='determinantEnvironmentalCultural'
                {...validationProps('determinantEnvironmentalCultural')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.determinantEnvironmentalCultural}
                  name='determinantEnvironmentalCultural'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORMTAMES4}
              extractDataProp='printStudentFormTames4'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired
}

const StudentFormTames4 = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORMTAMES4_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentFormTames4: form } = versionInfo
    const { student } = studentInfo
    const { dateOfBirth } = student
    const { formDate, studentFullName, studentDob } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? dayjs(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
          ? dayjs(dateOfBirth).format('YYYY-MM-DD')
          : dayjs(Date.now()).format('YYYY-MM-DD')
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentFormTames4
