import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { useQuery, useSubscription } from "@apollo/client";
import { QUERY_STUDENT_IEP_META } from "../operations/queries/students";
import { IepFormContext } from "./iepFormProvider";
import { MEETING_META_SUBSCRIPTION } from "../operations/subscriptions/meetingMeta";

// Create Auth context
const MeetingSISContext = React.createContext();
//console.log("In meetingSISProvider")
const MeetingSISProvider = (props) => {
  const { studentMeetingId, currentEditableForm } = useContext(IepFormContext);
  // selected form to render in FormArea
  const [meta, setMeta] = useState({});

  // Load Meta Information
  const { loading: loadingMeta, data: formData } = useQuery(
    QUERY_STUDENT_IEP_META,
    { variables: { id: studentMeetingId } }
  );

  // Setup Subscription
  const { data: subscriptionData } = useSubscription(
    MEETING_META_SUBSCRIPTION,
    {
      variables: { meetingId: studentMeetingId },
    }
  );

  const updateMeta = (update) => {
    setMeta({ ...meta, ...update });
  };

  const parseMeta = (meta) => {
    const metaCopy = Object.assign({}, meta);

    Object.keys(metaCopy).forEach((key) => {
      if (key.includes("Date")) {
        // Parse Date
        // console.log(metaCopy[key])
        metaCopy[key] = metaCopy[key]
          ? dayjs(metaCopy[key])
          : dayjs(Date.now());
      }
    });

    //metaCopy.studentNotes = metaCopy.studentNotes || ''

    return metaCopy;
  };

  // Set meta initially
  useEffect(() => {
    //console.log("In meetingSISProvider.useEffect()")
    if (formData?.studentMeetingGeneral?.meta) {
      const meta = formData?.studentMeetingGeneral?.meta;
      // console.log(meta)
      setMeta({ ...parseMeta(meta) });
    }
  }, [formData]);

  // Set meta when subscription receives data
  useEffect(() => {
    // Do nothing if user is already on the form
    if (currentEditableForm.formCode === "MEETING_SIS") {
      return;
    }
    if (subscriptionData?.meetingMetaUpdated?.meta) {
      setMeta({ ...parseMeta(subscriptionData?.meetingMetaUpdated?.meta) });
    }
  }, [subscriptionData]);

  return (
    <MeetingSISContext.Provider
      value={{
        meta,
        loadingMeta,
        updateMeta,
      }}
    >
      {props.children}
    </MeetingSISContext.Provider>
  );
};

MeetingSISProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MeetingSISProvider;

export { MeetingSISContext, MeetingSISProvider };
