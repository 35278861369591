/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import { Typography } from 'antd';
import Nav0 from './Nav0';



import Footer0 from './Footer0';
import { Row, Col } from 'antd';
import {
    Nav01DataSource,

    Footer00DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};
const { Title } = Typography;

export default class About extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
        };
    }



    componentDidMount() {
        // 适配手机屏幕;

        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
    }
    render() {
        const children2 = [
            <Nav0
                id="Nav0_0"
                key="Nav0_0"
                dataSource={Nav01DataSource}
                isMobile={this.state.isMobile}
                style={{ position: 'absolute', zIndex: 1, width: '100%' }}
            />,
            <Row style={{ padding: '3rem' }}>
                <Col span={2}>
                    {' '}
                </Col>
                <Col span={20} >
                    <Title>Our History</Title>
                </Col>
                <Col span={2}>
                    {' '}
                </Col>
            </Row>,
            <Row>
                <Col span={2}>
                    {' '}
                </Col>
                <Col span={20}>
                    TAMES Health Resource Service Management is a pioneer in the field of Medicaid claim
                    reimbursements throughout the state of Illinois. TAMES facilitates the provision of
                    health care services by working directly with school districts and cooperatives.
                    The company helps access funding to support the continued expansion of health-related
                    services for children. Marv Roelofs, a social worker and the founder of TAMES HRS,
                    authored the initial cost-sharing concept utilizing Medicaid funds and private health
                    care funds to support the provision of child health care services. This initiative
                    resulted in the passage of federal legislation and state codes for school districts
                    to access non-educational funding resources. More than 300 school districts have utilized
                    the TAMES reimbursement program to access federal Medicaid funds and support their
                    child health care services.
                </Col>
                <Col span={2}>
                    {' '}
                </Col>
            </Row>,
            <Row>
                <Col span={2}>
                    {' '}
                </Col>
                <Col span={20}>
                    TAMES also provides special education software solutions to make the process of
                    writing and tracking health plans as simple as possible for practitioners and
                    administrators. For a tour of the various tools available from TAMES, reach out to us at
                    info@tameshrs.com.
                </Col>
                <Col span={2}>
                    {' '}
                </Col>
            </Row>,

            <Footer0
                id="Footer0_0"
                key="Footer0_0"
                dataSource={Footer00DataSource}
                isMobile={this.state.isMobile}
            />,
        ];
        const children = [
            <Nav0
                id="Nav0_0"
                key="Nav0_0"
                dataSource={Nav01DataSource}
                isMobile={this.state.isMobile}
                style={{ position: 'absolute', zIndex: 1, width: '100%' }}
            />,
            <Row style={{ padding: '4rem' }}>
                <Col span={2}>
                    {' '}
                </Col>
                <Col span={20} >
                    <Title>Our History</Title>
                </Col>
                <Col span={2}>
                    {' '}
                </Col>
            </Row>,
            <Row style={{ padding: '50px' }}>
                <Col span={2}>
                    {' '}
                </Col>
                <Col span={20}>
                    <Title>
                        TAMES Health Resource Service Management is a pioneer in the field of Medicaid claim
                        reimbursements throughout the state of Illinois. TAMES facilitates the provision of
                        health care services by working directly with school districts and cooperatives.
                        The company helps access funding to support the continued expansion of health-related
                        services for children. Marv Roelofs, a social worker and the founder of TAMES HRS,
                        authored the initial cost-sharing concept utilizing Medicaid funds and private health
                        care funds to support the provision of child health care services. This initiative
                        resulted in the passage of federal legislation and state codes for school districts
                        to access non-educational funding resources. More than 300 school districts have utilized
                        the TAMES reimbursement program to access federal Medicaid funds and support their
                        child health care services.
                    </Title>

                </Col>
                <Col span={2}>
                    {' '}
                </Col>
            </Row>,
            <Row style={{ padding: '50px' }}>
                <Col span={2}>
                    {' '}
                </Col>
                <Col span={20}>
                    <Title>
                        TAMES also provides special education software solutions to make the process of
                        writing and tracking health plans as simple as possible for practitioners and
                        administrators. For a tour of the various tools available from TAMES, reach out to us at
                        info@tameshrs.com.
                    </Title>

                </Col>
                <Col span={2} >
                    {' '}
                </Col>
            </Row>,
            <Footer0
                id="Footer0_0"
                key="Footer0_0"
                dataSource={Footer00DataSource}
                isMobile={this.state.isMobile}
            />,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {/* {this.state.show && children} */}
                {isMobile == true && this.state.show && children2}
                {!isMobile && this.state.show && children}
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}
