import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import {
  Alert,
  Card,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Col,
  Select,
  Typography
} from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent1PhoneSIS from '../sis/StudentParent1PhoneSIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'
import StudentParent2PhoneSIS from '../sis/StudentParent2PhoneSIS'
import StudentGradeSIS from '../sis/StudentGradeSIS'
import StudentParent1EmailSIS from '../sis/StudentParent1EmailSIS'
import StudentParent2EmailSIS from '../sis/StudentParent2EmailSIS'

import FormItem from 'antd/lib/form/FormItem'
import StudentFormActions from '../StudentFormActions'
import { QUERY_FORMIAP_VERSION } from '../../../../operations/queries/forms/IAP'
import { PRINT_FORMIAP } from '../../../../operations/mutations/forms/IAP'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)
  const [parentsList, setParentsList] = useState([])
  const [studentAge, setStudentAge] = useState()
  const dateFormat = [
    'MM-DD-YYYY',
    'MM/DD/YYYY',
    'MM.DD.YY',
    'M-D-YYYY',
    'M/D/YYYY',
    'M.D.YYYY',
    'MM.DD.YYYY',
    'MM-DD-YY',
    'MM/DD/YY',
    'M-D-YY',
    'M/D/YY',
    'M.D.YY',
    'MMDDYYYY',
    'MMDDYY'
  ]
  useEffect(() => {
    setParentsList(data.parents)
    setStudentAge(dayjs().diff(data.studentDob, 'years') + ' years')
  }, [])

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    console.log(fieldName, fieldValue)
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
      case 'parentSignatureDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      case 'parentConsent':
        update[fieldName] = fieldValue ? fieldValue : 'no'
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentFormIAP'
            name='studentFormIAP'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              Individualized Acommodations Plan (IAP) Plan de Ajustes Razonables
              (PIAR)
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                format={dateFormat}
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentGradeSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentGrade}
                error={itemErrors.studentGrade}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />

              <Form.Item label='Age' id='Age'>
                <Input
                  type='text'
                  value={studentAge}
                  disabled={true}
                  readOnly={true}
                />
              </Form.Item>
              <StudentParent1SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1}
                error={itemErrors.parent1}
              />
              <StudentParent2SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2}
                error={itemErrors.parent2}
              />

              <StudentParent1PhoneSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1PhoneNumber}
                error={itemErrors.parent1PhoneNumber}
              />
              <StudentParent2PhoneSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2PhoneNumber}
                error={itemErrors.parent2PhoneNumber}
              />
              <StudentParent1EmailSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1Email}
                error={itemErrors.parent1Email}
              />
              <StudentParent2EmailSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2Email}
                error={itemErrors.parent2Email}
              />
            </Card>

            <Card
              title='Learning Profile/Perfil de Aprendizaje'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Learning Profile/Perfil de Aprendizaje'
                name='learningProfile'
                {...validationProps('learningProfile')}
              >
                <Editor
                  {...handleFocusEvents}
                  disabled={formDisabledState.learningProfile}
                />
              </FormItem>
              <FormItem
                label='Areas of Strength/Áreas de Fortaleza'
                name='areasOfStrength'
                {...validationProps('areasOfStrength')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.areasOfStrength}
                />
              </FormItem>
              <FormItem
                label='Areas for Improvement/Áreas para Mejorar'
                name='areasOfImprovement'
                {...validationProps('areasOfImprovement')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.areasOfImprovement}
                />
              </FormItem>
            </Card>

            <Card
              title='Successful Accommodations/Acomodaciones Exitosas'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Instructional/Instruccionales'
                name='instructional'
                {...validationProps('instructional')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.instructional}
                />
              </FormItem>
              <FormItem
                label='Environmental/Del Entorno'
                name='environmental'
                {...validationProps('environmental')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.environmental}
                />
              </FormItem>
              <FormItem
                label='Assessment/Para Evaluaciones'
                name='assessment'
                {...validationProps('assessment')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.assessment}
                />
              </FormItem>
              <FormItem
                label='Background Information/Información Relevante'
                name='backgroundInformation'
                {...validationProps('backgroundInformation')}
              >
                <Editor
                  {...handleFocusEvents}
                  disabled={formDisabledState.backgroundInformation}
                />
              </FormItem>
            </Card>
            <Card
              title='Schedule/Horario'
              style={{
                marginBottom: 30
              }}
            >
              <Row>
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate1a'
                    {...validationProps('scheduleDate1a')}
                  >
                    1: &nbsp; &nbsp;
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate1a}
                    />
                  </Form.Item>
                </Col>
                &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate1b'
                    {...validationProps('scheduleDate1b')}
                  >
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate1b}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate2a'
                    {...validationProps('scheduleDate2a')}
                  >
                    2: &nbsp; &nbsp;
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate2a}
                    />
                  </Form.Item>
                </Col>
                &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate2b'
                    {...validationProps('scheduleDate2b')}
                  >
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate2b}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate3a'
                    {...validationProps('scheduleDate3a')}
                  >
                    3: &nbsp; &nbsp;
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate3a}
                    />
                  </Form.Item>
                </Col>
                &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate3b'
                    {...validationProps('scheduleDate3b')}
                  >
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate3b}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate4a'
                    {...validationProps('scheduleDate4a')}
                  >
                    4: &nbsp; &nbsp;
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate4a}
                    />
                  </Form.Item>
                </Col>
                &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate4b'
                    {...validationProps('scheduleDate4b')}
                  >
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate4b}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate5a'
                    {...validationProps('scheduleDate5a')}
                  >
                    5: &nbsp; &nbsp;
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate5a}
                    />
                  </Form.Item>
                </Col>
                &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate5b'
                    {...validationProps('scheduleDate5b')}
                  >
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate5b}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate6a'
                    {...validationProps('scheduleDate6a')}
                  >
                    6: &nbsp; &nbsp;
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate6a}
                    />
                  </Form.Item>
                </Col>
                &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate6b'
                    {...validationProps('scheduleDate6b')}
                  >
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate6b}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate7a'
                    {...validationProps('scheduleDate7a')}
                  >
                    7: &nbsp; &nbsp;
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate7a}
                    />
                  </Form.Item>
                </Col>
                &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate7b'
                    {...validationProps('scheduleDate7b')}
                  >
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate7b}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate8a'
                    {...validationProps('scheduleDate8a')}
                  >
                    8: &nbsp; &nbsp;
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate8a}
                    />
                  </Form.Item>
                </Col>
                &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                <Col span={4}>
                  <Form.Item
                    name='scheduleDate8b'
                    {...validationProps('scheduleDate8b')}
                  >
                    <DatePicker
                      format={dateFormat}
                      {...handleFocusEvents}
                      disabled={formDisabledState.scheduleDate8b}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Form.Item
              label='Parent or Guardian Signature/Firma de los Padres o Guardianes:'
              name='parentSignatureName'
              validateStatus={itemErrors.parentSignatureName ? 'error' : ''}
              help={itemErrors.parentSignatureName}
              {...handleFocusEvents}
              {...validationProps('parentSignatureName')}
              hasFeedback
            >
              <Select
                style={{ width: '100%' }}
                allowClear
                disabled={formDisabledState.parentSignatureName}
              >
                {parentsList?.map(parent => (
                  <Select.Option key={parent} value={parent}>
                    {parent}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label='Parent Signature Date'
              name='parentSignatureDate'
              {...validationProps('parentSignatureDate')}
            >
              <DatePicker
                format={dateFormat}
                {...handleFocusEvents}
                disabled={formDisabledState.parentSignatureDate}
              />
            </Form.Item>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORMIAP}
              extractDataProp='printStudentFormIAP'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const IAP = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORMIAP_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}
    if (!studentInfo || !versionInfo) return initialValues

    const { studentFormIAP: form } = versionInfo
    const { student } = studentInfo
    const {
      formDate,
      studentFullName,
      studentDob,
      studentGrade,
      parent1FullName,
      parent1,
      parent2,
      parent2FullName,
      parent1PhoneNumber,
      parent2PhoneNumber,
      parent1Email,
      parent2Email,
      learningProfile,
      areasOfStrength,
      areasOfImprovement,
      instructional,
      environmental,
      assessment,
      backgroundInformation,
      scheduleDate1a,
      scheduleDate1b,
      scheduleDate2a,
      scheduleDate2b,
      scheduleDate3a,
      scheduleDate3b,
      scheduleDate4a,
      scheduleDate4b,
      scheduleDate5a,
      scheduleDate5b,
      scheduleDate6a,
      scheduleDate6b,
      scheduleDate7a,
      scheduleDate7b,
      scheduleDate8a,
      scheduleDate8b,
      parentSignatureName,
      parentSignatureDate
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || studentInfo.fullName || '',
      studentGrade: studentGrade ? studentGrade : '',
      studentDob: studentDob
        ? dayjs(studentDob).format('YYYY-MM-DD')
        : student.dateOfBirth
          ? dayjs(student.dateOfBirth).format('YYYY-MM-DD')
          : '',
      parent1FullName: parent1FullName ? parent1FullName : student.parent1FullName,
      parent2FullName: parent2FullName ? parent2FullName : student.parent2FullName,
      parent1: parent1 ? parent1 : '',
      parent2: parent2 ? parent2 : '',
      parent1PhoneNumber: parent1PhoneNumber ? student.parent1PhoneNumber : '',
      parent2PhoneNumber: parent2PhoneNumber ? student.parent2PhoneNumber : '',
      parent1Email: parent1Email ? student.parent1Email : '',
      parent2Email: parent2Email ? student.parent2Email : '',
      parentSignatureName: parentSignatureName ? parentSignatureName : '',
      learningProfile: learningProfile ? learningProfile : '',
      areasOfStrength: areasOfStrength ? areasOfStrength : '',
      areasOfImprovement: areasOfImprovement ? areasOfImprovement : '',
      instructional: instructional ? instructional : '',
      environmental: environmental ? environmental : '',
      assessment: assessment ? assessment : '',
      backgroundInformation: backgroundInformation ? backgroundInformation : '',
      parentSignatureDate: parentSignatureDate ? dayjs(parentSignatureDate) : dayjs(Date.now()),
      scheduleDate1a: scheduleDate1a ? dayjs(scheduleDate1a) : '',
      scheduleDate1b: scheduleDate1b ? dayjs(scheduleDate1b) : '',
      scheduleDate2a: scheduleDate2a ? dayjs(scheduleDate2a) : '',
      scheduleDate2b: scheduleDate2b ? dayjs(scheduleDate2b) : '',
      scheduleDate3a: scheduleDate3a ? dayjs(scheduleDate3a) : '',
      scheduleDate3b: scheduleDate3b ? dayjs(scheduleDate3b) : '',
      scheduleDate4a: scheduleDate4a ? dayjs(scheduleDate4a) : '',
      scheduleDate4b: scheduleDate4b ? dayjs(scheduleDate4b) : '',
      scheduleDate5a: scheduleDate5a ? dayjs(scheduleDate5a) : '',
      scheduleDate5b: scheduleDate5b ? dayjs(scheduleDate5b) : '',
      scheduleDate6a: scheduleDate6a ? dayjs(scheduleDate6a) : '',
      scheduleDate6b: scheduleDate6b ? dayjs(scheduleDate6b) : '',
      scheduleDate7a: scheduleDate7a ? dayjs(scheduleDate7a) : '',
      scheduleDate7b: scheduleDate7b ? dayjs(scheduleDate7b) : '',
      scheduleDate8a: scheduleDate8a ? dayjs(scheduleDate8a) : '',
      scheduleDate8b: scheduleDate8b ? dayjs(scheduleDate8b) : '',

    }

    return initialValues
  }

  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default IAP
