import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454V_VERSION } from '../../../../operations/queries/forms/3454V'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Typography } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454V } from '../../../../operations/mutations/forms/3454V'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454V'
            name='studentForm3454V'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              AUTISM CONSIDERATIONS
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>
            <Card
              style={{
                marginBottom: '30px'
              }}
            >
              <p>
                In accordance with Section 14-8.02 of the School code, “In the
                development of the individualized education program for a
                student who has a disability on the autism spectrum (which
                includes autistic disorder, Asperger disorder, pervasive
                developmental disorder not otherwise specified, childhood
                disintegrative disorder, and Rett Syndrome, as defined in the
                [(DSM-IV,2000)], the IEP team shall consider all the following
                factors.”
              </p>
              <Card
                title='1. Verbal and nonverbal communication needs'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item
                  label='Student Needs:'
                  name='verbalStudentNeeds'
                  {...validationProps('verbalStudentNeeds')}
                >
                  <Editor
                    formField='verbalStudentNeeds'
                    disabled={formDisabledState.verbalStudentNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Supports Indentified:'
                  name='verbalSupportsIdentified'
                  {...validationProps('verbalSupportsIdentified')}
                >
                  <Editor
                    formField='verbalSupportsIdentified'
                    disabled={formDisabledState.verbalSupportsIdentified}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>
              <Card
                title='2. Social interaction skills and proficiencies'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item
                  label='Student Needs:'
                  name='socialStudentNeeds'
                  {...validationProps('socialStudentNeeds')}
                >
                  <Editor
                    formField='socialStudentNeeds'
                    disabled={formDisabledState.socialStudentNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Supports Indentified:'
                  name='socialSupportsIdentified'
                  {...validationProps('socialSupportsIdentified')}
                >
                  <Editor
                    formField='socialSupportsIdentified'
                    disabled={formDisabledState.socialSupportsIdentified}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>
              <Card
                title='3. Needs resulting from unusual responses to sensory experience'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item
                  label='Student Needs:'
                  name='sensoryStudentNeeds'
                  {...validationProps('sensoryStudentNeeds')}
                >
                  <Editor
                    formField='sensoryStudentNeeds'
                    disabled={formDisabledState.sensoryStudentNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Supports Indentified:'
                  name='sensorySupportsIdentified'
                  {...validationProps('sensorySupportsIdentified')}
                >
                  <Editor
                    formField='sensorySupportsIdentified'
                    disabled={formDisabledState.sensorySupportsIdentified}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>
              <Card
                title='4. Needs resulting from resistance to environmental change or change in daily routines'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item
                  label='Student Needs:'
                  name='environmentalStudentNeeds'
                  {...validationProps('environmentalStudentNeeds')}
                >
                  <Editor
                    formField='environmentalStudentNeeds'
                    disabled={formDisabledState.environmentalStudentNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Supports Indentified:'
                  name='environmentalSupportsIdentified'
                  {...validationProps('environmentalSupportsIdentified')}
                >
                  <Editor
                    formField='environmentalSupportsIdentified'
                    disabled={formDisabledState.environmentalSupportsIdentified}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>
              <Card
                title='5. Needs resulting from engagement in repetitive activities and stereotyped movements'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item
                  label='Student Needs:'
                  name='activitiesStudentNeeds'
                  {...validationProps('activitiesStudentNeeds')}
                >
                  <Editor
                    formField='activitiesStudentNeeds'
                    disabled={formDisabledState.activitiesStudentNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Supports Indentified:'
                  name='activitiesSupportsIdentified'
                  {...validationProps('activitiesSupportsIdentified')}
                >
                  <Editor
                    formField='activitiesSupportsIdentified'
                    disabled={formDisabledState.activitiesSupportsIdentified}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>
              <Card
                title='6. Needs for any positive behavioral interventions, strategies and supports'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item
                  label='Student Needs:'
                  name='behavioralStudentNeeds'
                  {...validationProps('behavioralStudentNeeds')}
                >
                  <Editor
                    formField='behavioralStudentNeeds'
                    disabled={formDisabledState.behavioralStudentNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Supports Indentified:'
                  name='behavioralSupportsIdentified'
                  {...validationProps('behavioralSupportsIdentified')}
                >
                  <Editor
                    formField='behavioralSupportsIdentified'
                    disabled={formDisabledState.behavioralSupportsIdentified}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>
              <Card
                title='7. Other needs which impact progress in general curriculum, including social and emotional development'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item
                  label='Student Needs:'
                  name='otherStudentNeeds'
                  {...validationProps('otherStudentNeeds')}
                >
                  <Editor
                    formField='otherStudentNeeds'
                    disabled={formDisabledState.otherStudentNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Supports Indentified:'
                  name='otherSupportsIdentified'
                  {...validationProps('otherSupportsIdentified')}
                >
                  <Editor
                    formField='otherSupportsIdentified'
                    disabled={formDisabledState.otherSupportsIdentified}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>
            </Card>
            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454V}
              extractDataProp='printStudentForm3454V'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454V = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454V_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454V: form } = versionInfo
    const { student } = studentInfo
    const { formDate, studentFullName } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454V
