import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import { useQuery } from '@apollo/client'

// ant design
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Input,
  Form,
  Radio,
  Typography
} from 'antd'

import { PlusOutlined } from '@ant-design/icons'
import FormContainer from '../../../FormContainer'
import FormItem from 'antd/lib/form/FormItem'
import Title from 'antd/lib/typography/Title'

import { PRINT_FORM3457O } from '../../../../operations/mutations/forms/3457O'
import { QUERY_FORM3457O_VERSION } from '../../../../operations/queries/forms/3457O'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'

import Editor from '../../../elements/common/Editor'
import FormCollaboration from '../../../FormCollaboration'

import { IepFormContext } from '../../../../utils/iepFormProvider'
import StudentFormActions from '../StudentFormActions'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'

const { Paragraph, Text } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    console.log('In StudentForm3457O.getFormFieldValues', formFieldValues)
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    console.log('formatFormFieldUpdate', fieldName, fieldValue)
    //console.log("In StudentForm3457O.formatFormFieldUpdate")
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = dayjs(fieldValue)
        break
      case 'servicesNotImplemented':
        update[fieldName] = JSON.parse(fieldValue)
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457O'
            name='studentForm3457O'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom'
          >
            <Title level={4} className='center'>
              NOTIFICATION OF NON-IMPLEMENTATION OF SERVICE
            </Title>

            <Alert
              style={{ marginBottom: '24px' }}
              message="If, at a meeting to develop or revise a child's individualized education program (IEP), the IEP team determines that a certain 
                service is required in order for the child to receive a free, appropriate public education and that service is not implemented within 
                10 school days after the service was to be initiated  as set forth by the child's IEP, then the local education agency shall provide 
                the child's parent or guardian with written notification that the service has not been implemented.  The notification must be
                provided to the child's parent or guardian within 2 school days after the local education agency's non-compliance with the child's 
                IEP and must inform the parent or guardian about the school district's procedures for requesting compensatory
                services.  (Section 14-8.02f(d-5) of the School Code) For purposes of this Section, 'school days' does not include days in which 
                a child is absent from school for reasons unrelated to a lack of IEP services or when the service is available but the child is 
                unavailable."
              type='info'
            />

            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />

              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentParent1SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1}
                error={itemErrors.parent1}
              />
              <StudentParent2SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2}
                error={itemErrors.parent2}
              />
            </Card>

            <Card title='Services Not Implemented' style={{ marginBottom: 30 }}>
              <Input.Group
                onFocus={() => {
                  handleEditorEvents.onFocus('servicesNotImplemented')
                }}
                onBlur={() => {
                  handleEditorEvents.onBlur(
                    'servicesNotImplemented',
                    JSON.stringify(form.getFieldValue('servicesNotImplemented'))
                  )
                }}
              >
                <Form.List
                  name='servicesNotImplemented'
                  {...validationProps('servicesNotImplemented')}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <Card
                            style={{
                              marginBottom: '30px',
                              borderStyle: 'dashed'
                            }}
                          >
                            <Form.Item
                              label='Date services were required to initiate'
                              name={[
                                field.name,
                                'serviceNotImplementedDateRequiredToInitiate'
                              ]}
                              key={[
                                field.fieldKey,
                                'serviceNotImplementedDateRequiredToInitiate'
                              ]}
                              getValueProps={i => ({ value: dayjs(i) })}
                            >
                              <DatePicker
                                {...handleFocusEvents}
                                disabled={
                                  formDisabledState.servicesNotImplemented
                                }
                                format={[
                                  'MM-DD-YYYY',
                                  'MM/DD/YYYY',
                                  'MM.DD.YY',
                                  'M-D-YYYY',
                                  'M/D/YYYY',
                                  'M.D.YYYY',
                                  'MM.DD.YYYY',
                                  'MM-DD-YY',
                                  'MM/DD/YY',
                                  'M-D-YY',
                                  'M/D/YY',
                                  'M.D.YY',
                                  'MMDDYYYY',
                                  'MMDDYY'
                                ]}
                              />
                            </Form.Item>

                            <Form.Item
                              {...field}
                              label='Provider Name/Title'
                              name={[
                                field.name,
                                'serviceNotImplementedProviderNameAndTitle'
                              ]}
                              key={[
                                field.fieldKey,
                                'serviceNotImplementedProviderNameAndTitle'
                              ]}
                            >
                              <Input
                                disabled={
                                  formDisabledState.servicesNotImplemented
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label='Specific service not being implemented'
                              name={[field.name, 'serviceNotImplemented']}
                              key={[field.fieldKey, 'serviceNotImplemented']}
                            >
                              <Input
                                disabled={
                                  formDisabledState.servicesNotImplemented
                                }
                              />
                            </Form.Item>
                            <Button
                              danger
                              disabled={
                                formDisabledState.servicesNotImplemented
                              }
                              onClick={() => {
                                remove(field.name)
                                handleEditorEvents.onBlur(
                                  'servicesNotImplemented',
                                  JSON.stringify(
                                    form.getFieldValue('servicesNotImplemented')
                                  )
                                )
                              }}
                            >
                              Remove Non-Implemented Service
                            </Button>
                          </Card>
                        </div>
                      ))}

                      <Form.Item>
                        {
                          // Default value below prevents Quill from breaking the render
                        }
                        <Button
                          disabled={formDisabledState.servicesNotImplemented}
                          type='dashed'
                          onClick={() =>
                            add({
                              serviceNotImplementedDateRequiredToInitiate:
                                dayjs(Date.now()).format('YYYY-MM-DD'),
                              serviceNotImplementedProviderNameAndTitle: '',
                              serviceNotImplemented: ''
                            })
                          }
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Non-Implemented Service
                        </Button>
                      </Form.Item>
                      <Text type='warning'>
                        {validationProps('servicesNotImplemented').help}
                      </Text>
                    </>
                  )}
                </Form.List>
              </Input.Group>

              <Form.Item
                label="Explanation of the school district's procedures for requesting compensatory services:"
                name='explanationOfCompensatoryServicesProcedure'
                {...validationProps(
                  'explanationOfCompensatoryServicesProcedure'
                )}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='explanationOfCompensatoryServicesProcedure'
                  disabled={
                    formDisabledState.explanationOfCompensatoryServicesProcedure
                  }
                />
              </Form.Item>
            </Card>

            <Card
              title='Non-Compliance Contact Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Contact Name'
                name='nonComplianceContactName'
                {...validationProps('nonComplianceContactName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.nonComplianceContactName}
                />
              </Form.Item>
              <Form.Item
                label='Contact Title'
                name='nonComplianceContactTitle'
                {...validationProps('nonComplianceContactTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.nonComplianceContactTitle}
                />
              </Form.Item>
              <Form.Item
                label='Contact Phone'
                name='nonComplianceContactPhone'
                {...validationProps('nonComplianceContactPhone')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.nonComplianceContactPhone}
                />
              </Form.Item>
            </Card>

            <Card
              title='District Representative Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Name'
                name='districtRepresentativeName'
                {...validationProps('districtRepresentativeName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.districtRepresentativeName}
                />
              </Form.Item>
              <Form.Item
                label='Title'
                name='districtRepresentativeTitle'
                {...validationProps('districtRepresentativeTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.districtRepresentativeTitle}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3457O}
              extractDataProp='printStudentForm3457O'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3457O = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm
  //console.log("In StudentForm3457O studentFormId: ", studentFormId)

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )

  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3457O_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )
  //console.log("In StudentForm3457O=>After QUERY_FORM3457O_VERSION query")

  const createInitialFormData = (studentInfo, versionInfo) => {
    console.log(studentInfo, versionInfo)

    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3457O: form } = versionInfo
    const { student } = studentInfo
    const { parent1FullName, parent2FullName, dateOfBirth } = student
    const {
      formDate,
      studentFullName,
      studentDob,
      parent1,
      parent2,
      servicesNotImplemented,
      explanationOfCompensatoryServicesProcedure,
      nonComplianceContactName,
      nonComplianceContactTitle,
      nonComplianceContactPhone,
      districtRepresentativeName,
      districtRepresentativeTitle
    } = form

    //The servicesNotImplemented array include a date field that is stored as a string.  We need to convert it to a dayjs date and use that to load initialValues.
    let servicesNotImplementedList = []
    servicesNotImplemented.map(service => {
      servicesNotImplementedList.push({
        serviceNotImplementedDateRequiredToInitiate: dayjs(
          service.serviceNotImplementedDateRequiredToInitiate
        ),
        serviceNotImplementedProviderNameAndTitle:
          service.serviceNotImplementedProviderNameAndTitle,
        serviceNotImplemented: service.serviceNotImplemented
      })
    })

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? dayjs(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
          ? dayjs(dateOfBirth).format('YYYY-MM-DD')
          : '',
      parent1: parent1 || parent1FullName || '',
      parent2: parent2 || parent2FullName || '',
      servicesNotImplemented: servicesNotImplementedList
        ? servicesNotImplementedList
        : [],
      explanationOfCompensatoryServicesProcedure:
        explanationOfCompensatoryServicesProcedure
          ? explanationOfCompensatoryServicesProcedure
          : '',
      nonComplianceContactName: nonComplianceContactName
        ? nonComplianceContactName
        : '',
      nonComplianceContactTitle: nonComplianceContactTitle
        ? nonComplianceContactTitle
        : '',
      nonComplianceContactPhone: nonComplianceContactPhone
        ? nonComplianceContactPhone
        : '',
      districtRepresentativeName: districtRepresentativeName
        ? districtRepresentativeName
        : '',
      districtRepresentativeTitle: districtRepresentativeTitle
        ? districtRepresentativeTitle
        : ''
    }

    console.log(initialValues)
    return initialValues
  }

  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}

export default StudentForm3457O
