// react
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'

// dayjs
import dayjs from 'dayjs'
import DateSelector from '../../elements/common/DateSelector'
// apollo & operations
import { useQuery, useMutation } from '@apollo/client'
import { QUERY_STUDENT } from '../../../operations/queries/students'
import { UPDATE_STUDENT_IEP_META } from '../../../operations/mutations/students'
import FormItemSelectGrade from '../../elements/formItems/FormItemSelectGrade'
import { IepFormContext } from '../../../utils/iepFormProvider'
import { MeetingSISContext } from '../../../utils/meetingSISProvider'
import notificationSuccess from '../../../utils/notification'
// ant design
import {
  Form,
  Input,
  Button,
  Typography,
  Divider,
  DatePicker,
  message,
  Radio,
  Col,
  Row,
  Card,
  Space
} from 'antd'

// other libraries
import formatErrors from '../../../utils/form'
import ModalDialog from '../../ModalDialog'
import AuthContext from '../../../utils/authProvider'

const { Paragraph } = Typography

const MeetingSISForm = props => {
  // get current IEP meta data
  const data = props.student
  const { currentStudentInfo } = useContext(IepFormContext)
  const Auth = useContext(AuthContext)
  let studentId = currentStudentInfo?.studentId
  const { id } = useParams()
  if (studentId == '') {
    studentId = id
  }
  const history = useNavigate()
  const [form] = Form.useForm()
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const [updateStudentIEPMeta, { loading: updateLoading }] = useMutation(
    UPDATE_STUDENT_IEP_META
  )

  // const continueEditing = _id => _id && history(`/student/${_id}`)

  const onFinish = async values => {
    try {
      clearErrors()
      const mutated = await updateStudentIEPMeta({
        variables: { id: studentId, studentIEPMetaInput: values }
      })
      // ModalDialog.success({
      //   title: 'Success',
      //   content: `The student IEP meta has been updated.`,
      //   okText: 'Return to listing',
      //   onOk: () => history('/students'),
      //   cancelText: 'Continue editing',
      //   onCancel: () => continueEditing(mutated.data.updateStudentIEPMeta?.id)
      // })
      // ^ absurdly over engineered and annoying for the end user
      notificationSuccess('Global Meeting Info Updated')
      Auth.fetchDesktopData()
    } catch (e) {
      if (e) {
        console.log(e, 'error')
      }
      message.error(
        'Error(s) need to be corrected before the student IEP meta can be updated.'
      )
      setErrors(formatErrors(e))
    }
  }

  const setErrors = errors =>
    errors.itemErrors
      ? setItemErrors(errors.itemErrors)
      : setFormError(errors.formError)

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }
  const initialValues = {
    anticipatedGraduationDate: data?.anticipatedGraduationDate
      ? dayjs(data?.anticipatedGraduationDate)
      : '',
    currentGrade: data?.currentGrade || '',
    lastAnnualReviewDate: data?.lastAnnualReviewDate
      ? dayjs(data?.lastAnnualReviewDate)
      : '',
    nextAnnualReviewDate: data?.nextAnnualReviewDate
      ? dayjs(data?.nextAnnualReviewDate)
      : '',
    initialEvaluationConsent: data?.initialEvaluationConsent || '',
    initialEvaluationConsentDate: data?.initialEvaluationConsentDate
      ? dayjs(data?.initialEvaluationConsentDate)
      : '',
    reEvaluationConsent: data?.reEvaluationConsent || '',
    reEvaluationConsentDate: data?.reEvaluationConsentDate
      ? dayjs(data?.reEvaluationConsentDate)
      : '',
    triennialReEvaluationDueDate: data?.triennialReEvaluationDueDate
      ? dayjs(data?.triennialReEvaluationDueDate)
      : '',
    caseManager: data?.caseManager || '',
    residentDistrict: data?.residentDistrict || '',
    residentSchool: data?.residentSchool || '',
    servingDistrict: data?.servingDistrict || '',
    servingSchool: data?.servingSchool || '',
    nextYearCaseManager: data?.nextYearCaseManager || '',
    nextYearGrade: data?.nextYearGrade || '',
    nextYearResidentDistrict: data?.nextYearResidentDistrict || '',
    nextYearResidentSchool: data?.nextYearResidentSchool || '',
    nextYearServingDistrict: data?.nextYearServingDistrict || '',
    nextYearServingSchool: data?.nextYearServingSchool || ''
  }
  return (
    <>
      {data ? (
        <>
          <Card title='Global Meeting Meta Information'>
            <Form
              form={form}
              id='MeetingSISForm'
              name='MeetingSISForm'
              onFinish={onFinish}
              preserve={false}
              initialValues={initialValues}
            //scrollToFirstError
            >
              <Row>
                <Col span={8} style={{ paddingLeft: '100px' }}>
                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Anticipated Graduation Date'
                    name='anticipatedGraduationDate'
                    validateStatus={
                      itemErrors.anticipatedGraduationDate ? 'error' : ''
                    }
                    help={itemErrors.anticipatedGraduationDate}
                  >
                    <DatePicker
                      format={[
                        'MM-DD-YYYY',
                        'MM/DD/YYYY',
                        'MM.DD.YY',
                        'M-D-YYYY',
                        'M/D/YYYY',
                        'M.D.YYYY',
                        'MM.DD.YYYY',
                        'MM-DD-YY',
                        'MM/DD/YY',
                        'M-D-YY',
                        'M/D/YY',
                        'M.D.YY',
                        'MMDDYYYY',
                        'MMDDYY'
                      ]}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Last Annual Review Date'
                    name='lastAnnualReviewDate'
                    validateStatus={
                      itemErrors.lastAnnualReviewDate ? 'error' : ''
                    }
                    message={itemErrors?.lastAnnualReviewDate}
                  >
                    <DatePicker
                      format={[
                        'MM-DD-YYYY',
                        'MM/DD/YYYY',
                        'MM.DD.YY',
                        'M-D-YYYY',
                        'M/D/YYYY',
                        'M.D.YYYY',
                        'MM.DD.YYYY',
                        'MM-DD-YY',
                        'MM/DD/YY',
                        'M-D-YY',
                        'M/D/YY',
                        'M.D.YY',
                        'MMDDYYYY',
                        'MMDDYY'
                      ]}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Next Annual Review Date'
                    name='nextAnnualReviewDate'
                    validateStatus={
                      itemErrors.nextAnnualReviewDate ? 'error' : ''
                    }
                    help={itemErrors.nextAnnualReviewDate}
                  >
                    <DatePicker
                      format={[
                        'MM-DD-YYYY',
                        'MM/DD/YYYY',
                        'MM.DD.YY',
                        'M-D-YYYY',
                        'M/D/YYYY',
                        'M.D.YYYY',
                        'MM.DD.YYYY',
                        'MM-DD-YY',
                        'MM/DD/YY',
                        'M-D-YY',
                        'M/D/YY',
                        'M.D.YY',
                        'MMDDYYYY',
                        'MMDDYY'
                      ]}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Initial Evaluation Consent'
                    name='initialEvaluationConsent'
                    hasFeedback
                    validateStatus={
                      itemErrors.initialEvaluationConsent ? 'error' : ''
                    }
                    help={itemErrors.initialEvaluationConsent}
                  >
                    <Radio.Group
                      defaultValue
                      defaultChecked='n/a'
                      buttonStyle='solid'
                    >
                      <Space direction='vertical'>
                        <Radio value='yes'>I give consent</Radio>
                        <Radio value='no'>I do not give consent</Radio>
                        <Radio value='n/a'>Decision Delayed</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Initial Evaluation Consent Date'
                    name='initialEvaluationConsentDate'
                    validateStatus={
                      itemErrors.initialEvaluationConsentDate ? 'error' : ''
                    }
                    help={itemErrors.initialEvaluationConsentDate}
                  >
                    <DatePicker
                      format={[
                        'MM-DD-YYYY',
                        'MM/DD/YYYY',
                        'MM.DD.YY',
                        'M-D-YYYY',
                        'M/D/YYYY',
                        'M.D.YYYY',
                        'MM.DD.YYYY',
                        'MM-DD-YY',
                        'MM/DD/YY',
                        'M-D-YY',
                        'M/D/YY',
                        'M.D.YY',
                        'MMDDYYYY',
                        'MMDDYY'
                      ]}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Reevaluation Consent'
                    name='reEvaluationConsent'
                    hasFeedback
                    validateStatus={
                      itemErrors.reEvaluationConsent ? 'error' : ''
                    }
                    help={itemErrors.reEvaluationConsent}
                  >
                    <Radio.Group
                      defaultValue
                      defaultChecked='n/a'
                      buttonStyle='solid'
                    >
                      <Space direction='vertical'>
                        <Radio value='yes'>I give consent</Radio>
                        <Radio value='no'>I do not give consent</Radio>
                        <Radio value='n/a'>Decision Delayed</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Reevaluation Consent Date'
                    name='reEvaluationConsentDate'
                    validateStatus={
                      itemErrors.reEvaluationConsentDate ? 'error' : ''
                    }
                    help={itemErrors.reEvaluationConsentDate}
                  >
                    <DatePicker
                      format={[
                        'MM-DD-YYYY',
                        'MM/DD/YYYY',
                        'MM.DD.YY',
                        'M-D-YYYY',
                        'M/D/YYYY',
                        'M.D.YYYY',
                        'MM.DD.YYYY',
                        'MM-DD-YY',
                        'MM/DD/YY',
                        'M-D-YY',
                        'M/D/YY',
                        'M.D.YY',
                        'MMDDYYYY',
                        'MMDDYY'
                      ]}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Triennial Reevaluation Due Date'
                    name='triennialReEvaluationDueDate'
                    validateStatus={
                      itemErrors.triennialReEvaluationDueDate ? 'error' : ''
                    }
                    help={itemErrors.triennialReEvaluationDueDate}
                  >
                    <DatePicker
                      format={[
                        'MM-DD-YYYY',
                        'MM/DD/YYYY',
                        'MM.DD.YY',
                        'M-D-YYYY',
                        'M/D/YYYY',
                        'M.D.YYYY',
                        'MM.DD.YYYY',
                        'MM-DD-YY',
                        'MM/DD/YY',
                        'M-D-YY',
                        'M/D/YY',
                        'M.D.YY',
                        'MMDDYYYY',
                        'MMDDYY'
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: '100px' }}>
                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Grade'
                    hasFeedback
                    validateStatus={itemErrors.currentGrade ? 'error' : ''}
                    help={itemErrors.currentGrade}
                  >
                    <FormItemSelectGrade name='currentGrade' />
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Case Manager'
                    name='caseManager'
                    hasFeedback
                    validateStatus={itemErrors.caseManager ? 'error' : ''}
                    help={itemErrors.caseManager}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Resident District'
                    name='residentDistrict'
                    hasFeedback
                    validateStatus={itemErrors.residentDistrict ? 'error' : ''}
                    help={itemErrors.residentDistrict}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Resident School'
                    name='residentSchool'
                    hasFeedback
                    validateStatus={itemErrors.residentSchool ? 'error' : ''}
                    help={itemErrors.residentSchool}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Serving District'
                    name='servingDistrict'
                    hasFeedback
                    validateStatus={itemErrors.servingDistrict ? 'error' : ''}
                    help={itemErrors.servingDistrict}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Serving School'
                    name='servingSchool'
                    hasFeedback
                    validateStatus={itemErrors.servingSchool ? 'error' : ''}
                    help={itemErrors.servingSchool}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: '100px' }}>
                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Next Year Grade'
                    hasFeedback
                    validateStatus={itemErrors.nextYearGrade ? 'error' : ''}
                    help={itemErrors.nextYearGrade}
                  >
                    <FormItemSelectGrade name='nextYearGrade' />
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Next Year Case Manager'
                    name='nextYearCaseManager'
                    hasFeedback
                    validateStatus={
                      itemErrors.nextYearCaseManager ? 'error' : ''
                    }
                    help={itemErrors.nextYearCaseManager}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Next Year Resident District'
                    name='nextYearResidentDistrict'
                    hasFeedback
                    validateStatus={
                      itemErrors.nextYearResidentDistrict ? 'error' : ''
                    }
                    help={itemErrors.nextYearResidentDistrict}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Next Year Resident School'
                    name='nextYearResidentSchool'
                    hasFeedback
                    validateStatus={
                      itemErrors.nextYearResidentSchool ? 'error' : ''
                    }
                    help={itemErrors.nextYearResidentSchool}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Next Year Serving District'
                    name='nextYearServingDistrict'
                    hasFeedback
                    validateStatus={
                      itemErrors.nextYearServingDistrict ? 'error' : ''
                    }
                    help={itemErrors.nextYearServingDistrict}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    style={{ padding: '5px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label='Next Year Serving School'
                    name='nextYearServingSchool'
                    hasFeedback
                    validateStatus={
                      itemErrors.nextYearServingSchool ? 'error' : ''
                    }
                    help={itemErrors.nextYearServingSchool}
                  >
                    <Input />
                  </Form.Item>

                  {formError && (
                    <Paragraph type='danger'>{formError}</Paragraph>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24} offset={10}>
                  <Form.Item
                    style={{ padding: '5px', justifyContent: 'center' }}
                  >
                    <Button
                      type='primary'
                      htmlType='submit'
                      loading={updateLoading}
                    >
                      {'Save'}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </>
      ) : (
        <>loading</>
      )}
    </>
  )
}

MeetingSISForm.propTypes = {
  //data: PropTypes.object.isRequired,
  //studentMeetingId: PropTypes.string.isRequired,
}

export default MeetingSISForm
