import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_NAME } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454J_VERSION } from '../../../../operations/queries/forms/3454J'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Typography } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454J } from '../../../../operations/mutations/forms/3454J'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const formEditorFields = [
    {
      name: 'studentStrengths',
      label: 'Student’s Strengths',
      instructions:
        'Include a description of behavioral strengths (e.g., ignores inappropriate behavior of peers, positive interactions with staff, accepts responsibility, etc.)'
    },
    {
      name: 'operationalDefinitionTargetBehavior',
      label: 'Operational Definition of Target Behavior',
      instructions:
        'Include a description of the frequency, duration and intensity of the behavior.'
    },
    {
      name: 'setting',
      label: 'Setting',
      instructions:
        'Include a description of the setting in which the behavior occurs (e.g., physical setting, time of day, persons involved.)'
    },
    {
      name: 'antecedents',
      label: 'Antecedents',
      instructions:
        'Include a description of the relevant events that preceded the target behavior.'
    },
    {
      name: 'consequences',
      label: 'Consequences',
      instructions:
        'Include a description of the result of the target behavior (e.g. removed from classroom and did not complete assignment. What is the payoff for the student?)'
    },
    {
      name: 'environmentalVariables',
      label: 'Environmental Variables',
      instructions:
        'Include a description of any environmental variables that may affect the behavior (e.g., medication, weather, diet, sleep, social factors.)'
    },
    {
      name: 'behavioralFunction',
      label: 'Hypothesis of Behavioral Function',
      instructions:
        'Include a hypothesis of the relationship between the behavior and the environment in which it occurs.'
    }
  ]

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454J'
            name='studentForm3454J'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              FUNCTIONAL BEHAVIORAL ASSESSMENT (AS APPROPRIATE)
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>

            <Card
              title='Details'
              style={{
                marginBottom: 30
              }}
            >
              {formEditorFields.map(({ name, label, instructions }, index) => (
                <div key={index}>
                  <Form.Item
                    label={label}
                    name={name}
                    {...validationProps(name)}
                    style={instructions ? { marginBottom: '0' } : {}}
                  >
                    <Editor
                      {...handleEditorEvents}
                      formField={name}
                      disabled={formDisabledState[name]}
                    />
                  </Form.Item>
                  {!!instructions && (
                    <Alert
                      message={instructions}
                      type='info'
                      style={{ marginBottom: '24px' }}
                    />
                  )}
                </div>
              ))}
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454J}
              extractDataProp='printStudentForm3454J'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454J = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_NAME,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454J_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454J: form } = versionInfo
    const { student } = studentInfo
    const { formDate, studentFullName } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454J
