import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454E_VERSION } from '../../../../operations/queries/forms/3454E'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import {
  Alert,
  Card,
  DatePicker,
  Form,
  Typography,
  Radio,
  Checkbox,
  Select,
  Input
} from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454E } from '../../../../operations/mutations/forms/3454E'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography
const { Option } = Select

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)

  const getMemberCertifyArray = formValues => {
    const memberCertify = []
    for (let index = 0; index < 10; index++) {
      const value = formValues[`memberCertify${index + 1}`]
      if (value === null || value === 'undefined') {
        memberCertify.push('')
        continue
      }
      memberCertify.push(value)
    }
    return memberCertify
  }

  const getMemberCertifyNameArray = formValues => {
    const memberCertifyName = []
    for (let index = 0; index < 10; index++) {
      const value = formValues[`memberCertifyName${index + 1}`]
      if (value === null || value === 'undefined') {
        memberCertifyName.push('')
        continue
      }
      memberCertifyName.push(value)
    }
    return memberCertifyName
  }

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId,
      memberCertify: getMemberCertifyArray(formFieldValues),
      memberCertifyName: getMemberCertifyNameArray(formFieldValues)
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      case 'needsDifferent':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setNeedsDifferent(update[fieldName])
        break
      case 'achievementDiscrepancy':
        //update[fieldName] = fieldValue === "null" ? null : fieldValue === "yes";
        update[fieldName] = fieldValue
        setAchievementDiscrepancy(update[fieldName])
        break
      case 'specificLearningDisability':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setSpecificLearningDisability(update[fieldName])
        break
      case 'specializedInstruction':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      case 'specificLearningDisabilityArea':
        update[fieldName] = fieldValue.split(',')
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const disabilityOptions = [
    { label: 'Basic reading skills', value: 'basic-reading-skills' },
    { label: 'Mathematical calculation', value: 'mathematical-calculations' },
    { label: 'Oral expression', value: 'oral-expression' },
    { label: 'Reading fluency skills', value: 'reading-fluency-skills' },
    {
      label: 'Mathematical problem-solving',
      value: 'mathematical-problem-solving'
    },
    { label: 'Listening comprehension', value: 'listening-comprehension' },
    { label: 'Reading comprehension', value: 'reading-comprehension' },
    { label: 'Written expression', value: 'written-expression' }
  ]

  const [needsDifferent, setNeedsDifferent] = useState(data?.needsDifferent)
  const [achievementDiscrepancy, setAchievementDiscrepancy] = useState(
    data?.achievementDiscrepancy
  )
  const [specificLearningDisability, setSpecificLearningDisability] = useState(
    data?.specificLearningDisability
  )

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454E'
            name='studentForm3454E'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              DOCUMENTATION OF INTERVENTION/EVALUATION RESULTS (SPECIFIC
              LEARNING DISABILITY)
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>

            <Card
              title='Instructional Need'
              style={{
                marginBottom: '30px'
              }}
            >
              <Alert
                message="Evidence in the Documentation of Intervention/Evaluation Results form should support the team's answer to this question."
                type='info'
                style={{ marginBottom: 20 }}
              />
              <Form.Item
                label='Are this student’s needs in any areas of concern significantly different from the needs of typical peers and of an intensity or type that exceeds
                general education resources?'
                name='needsDifferent'
                {...validationProps('needsDifferent')}
              >
                <Radio.Group
                  onChange={e => {
                    setNeedsDifferent(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.needsDifferent}
                  name='needsDifferent'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name='needsDifferentAreas'
                label='If yes, which areas?'
                hidden={!needsDifferent}
                {...validationProps('needsDifferentAreas')}
              >
                <Editor
                  formField='needsDifferentAreas'
                  disabled={formDisabledState.needsDifferentAreas}
                  {...handleEditorEvents}
                />
              </Form.Item>
            </Card>

            {/*  Removed in ISBE version 1/22
            <Card
              title="Optional Criteria"
              style={{
                marginBottom: "30px",
              }}
            >
              <Form.Item
                label="Does a severe discrepancy exist between achievement and ability that is not correctable without special education

                and related services? (Please refer to evidence in Documentation of Evaluation Results)"
                name="achievementDiscrepancy"
                {...validationProps("achievementDiscrepancy")}
              >
                <Radio.Group
                  onChange={(e) => {
                    console.log('radio group value:',e.target.value)
                    setAchievementDiscrepancy(e.target.value);
                    handleMouseEvents.onChange(e);
                  }}
                  buttonStyle="solid"
                  disabled={formDisabledState.achievementDiscrepancy}
                  name="achievementDiscrepancy"
                >
                  <Radio.Button value="yes">Yes</Radio.Button>
                  <Radio.Button value="no">No</Radio.Button>
                  <Radio.Button value="n/a">N/A</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="achievementDiscrepancyAreas"
                label="In which area(s)?"
                hidden={!(achievementDiscrepancy === "yes")}
                {...validationProps("achievementDiscrepancyAreas")}
              >
                <Editor
                  formField="achievementDiscrepancyAreas"
                  disabled={formDisabledState.achievementDiscrepancyAreas}
                  {...handleEditorEvents}
                />
              </Form.Item>
                </Card>*/}

            <Card
              title='ELIGIBILITY DETERMINATION'
              style={{
                marginBottom: '30px'
              }}
            >
              <Card
                title='Step 1: Disability Adversely Affecting Educational Performance'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Alert
                  message='If any of the boxes in the Inclusionary Criteria section are marked "No," the student does not have a specific learning disability 
                  and the team must complete Step 2 below.'
                  type='info'
                  style={{ marginBottom: 20 }}
                />
                <Form.Item
                  label='Based on the answers to the questions in the Determinant Factors, Exclusionary Criteria, and Inclusionary Criteria sections, does the student have a specific learning disability?'
                  name='specificLearningDisability'
                  {...validationProps('specificLearningDisability')}
                >
                  <Radio.Group
                    onChange={e => {
                      setSpecificLearningDisability(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={formDisabledState.specificLearningDisability}
                    name='specificLearningDisability'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </Form.Item>

                <Form.Item hidden={specificLearningDisability}>
                  <Alert
                    message='If the answer is "No," the student is not eligible for special education services under the category of Specific 
                    Learning Disability and the team must complete Step 2 below.'
                    type='info'
                    style={{ marginBottom: 20 }}
                  />
                </Form.Item>

                <Form.Item
                  label='If the answer is "Yes," indicate the area below and complete Step 2.'
                  name='specificLearningDisabilityArea'
                  hidden={!specificLearningDisability}
                  {...validationProps('specificLearningDisabilityArea')}
                >
                  <Checkbox.Group
                    options={disabilityOptions}
                    onChange={checkedValues => {
                      const e = {
                        target: {
                          name: 'specificLearningDisabilityArea',
                          value: checkedValues.toString()
                        }
                      }
                      handleMouseEvents.onChange(e)
                    }}
                    defaultValue={[]}
                  />
                </Form.Item>
              </Card>

              <Card
                title='Step 2: Special Education and Related Services'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Form.Item
                  name='specializedInstruction'
                  {...validationProps('specializedInstruction')}
                >
                  <Radio.Group
                    onChange={e => {
                      handleMouseEvents.onChange(e)
                    }}
                    disabled={formDisabledState.specializedInstruction}
                    name='specializedInstruction'
                  >
                    <Radio value>
                      Specialized instruction is required in order for the
                      student to make progress and reduce discrepancy (eligible)
                    </Radio>
                    <Radio value={false}>
                      Specialized instruction is not required in order for the
                      student to make progress and reduce discrepancy (not
                      eligible)
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                {[...Array(10)].map((x, index) => (
                  <Card key={index} style={{ marginBottom: '24px' }}>
                    <Form.Item
                      key={index}
                      label={`Member ${index + 1} Certify`}
                      name={`memberCertify${index + 1}`}
                      {...validationProps(`memberCertify${index + 1}`)}
                      {...handleFocusEvents}
                    >
                      <Select
                        onChange={e => {
                          handleMouseEvents.onChange({
                            target: { name: `memberCertify${index + 1}` }
                          })
                        }}
                        disabled={
                          formDisabledState[`memberCertify${index + 1}`]
                        }
                        name={`memberCertify${index + 1}`}
                        style={{ width: '100px' }}
                      >
                        <Option value={null} />
                        <Option value='yes'>Yes</Option>
                        <Option value='no'>No</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      key={index + 100} //To make key unique from above item
                      label='Name'
                      name={`memberCertifyName${index + 1}`}
                      {...validationProps(`memberCertifyName${index + 1}`)}
                    >
                      <Input
                        {...handleFocusEvents}
                        disabled={
                          formDisabledState[`memberCertifyName${index + 1}`]
                        }
                        name={`memberCertifyName${index + 1}`}
                      />
                    </Form.Item>
                  </Card>
                ))}
              </Card>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454E}
              extractDataProp='printStudentForm3454E'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454E = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454E_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454E: form } = versionInfo
    const { student } = studentInfo
    const {
      formDate,
      studentFullName,
      memberCertify,
      memberCertifyName = []
    } = form
    console.log(memberCertifyName)

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      memberCertify1: memberCertify[0] || '',
      memberCertify2: memberCertify[1] || '',
      memberCertify3: memberCertify[2] || '',
      memberCertify4: memberCertify[3] || '',
      memberCertify5: memberCertify[4] || '',
      memberCertify6: memberCertify[5] || '',
      memberCertify7: memberCertify[6] || '',
      memberCertify8: memberCertify[7] || '',
      memberCertify9: memberCertify[8] || '',
      memberCertify10: memberCertify[9] || '',
      memberCertifyName1: memberCertifyName[0] || '',
      memberCertifyName2: memberCertifyName[1] || '',
      memberCertifyName3: memberCertifyName[2] || '',
      memberCertifyName4: memberCertifyName[3] || '',
      memberCertifyName5: memberCertifyName[4] || '',
      memberCertifyName6: memberCertifyName[5] || '',
      memberCertifyName7: memberCertifyName[6] || '',
      memberCertifyName8: memberCertifyName[7] || '',
      memberCertifyName9: memberCertifyName[8] || '',
      memberCertifyName10: memberCertifyName[9] || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454E
