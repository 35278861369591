import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454D_VERSION } from '../../../../operations/queries/forms/3454D'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Radio, Typography, Space } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import FormItem from 'antd/lib/form/FormItem'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454D } from '../../../../operations/mutations/forms/3454D'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const [
    lackAppropriateInstructionReading,
    setLackAppropriateInstructionReading
  ] = useState(data?.lackAppropriateInstructionReading)
  const [lackAppropriateInstructionMath, setLackAppropriateInstructionMath] =
    useState(data?.lackAppropriateInstructionMath)
  const [factorEnglishLearnerStatus, setFactorEnglishLearnerStatus] = useState(
    data?.factorEnglishLearnerStatus
  )
  const [factorVisualHearingMotor, setFactorVisualHearingMotor] = useState(
    data?.factorVisualHearingMotor
  )
  const [factorIntellectual, setFactorIntellectual] = useState(
    data?.factorIntellectual
  )
  const [factorEmotional, setFactorEmotional] = useState(data?.factorEmotional)
  const [factorCultural, setFactorCultural] = useState(data?.factorCultural)
  const [factorEnvironmentalEconomic, setFactorEnvironmentalEconomic] =
    useState(data?.factorEnvironmentalEconomic)
  const [factorSlowerRate, setFactorSlowerRate] = useState(
    data?.factorSlowerRate
  )
  const [factorBelowPerformance, setFactorBelowPerformance] = useState(
    data?.factorBelowPerformance
  )

  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      case 'lackAppropriateInstructionReading':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setLackAppropriateInstructionReading(update[fieldName])
        break
      case 'lackAppropriateInstructionMath':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setLackAppropriateInstructionMath(update[fieldName])
        break
      case 'factorEnglishLearnerStatus':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setFactorEnglishLearnerStatus(update[fieldName])
        break
      case 'factorVisualHearingMotor':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setFactorVisualHearingMotor(update[fieldName])
        break
      case 'factorIntellectual':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setFactorIntellectual(update[fieldName])
        break
      case 'factorEmotional':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setFactorEmotional(update[fieldName])
        break
      case 'factorCultural':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setFactorCultural(update[fieldName])
        break
      case 'factorEnvironmentalEconomic':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setFactorEnvironmentalEconomic(update[fieldName])
        break
      case 'factorSlowerRate':
        update[fieldName] = fieldValue
        setFactorSlowerRate(update[fieldName])
        break
      case 'factorBelowPerformance':
        update[fieldName] = fieldValue
        setFactorBelowPerformance(update[fieldName])
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454D'
            name='studentForm3454D'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              ELIGIBILITY Determination (SPECIFIC LEARNING DISABILITY)
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>

            <Card
              title='Determinant Factor'
              style={{
                marginBottom: 30
              }}
            >
              <Title level={4}>
                The determinant factor for the student's suspected disability
                is:
              </Title>

              <Card
                title='Reading'
                style={{
                  marginBottom: 30
                }}
              >
                <FormItem
                  label='Lack of appropriate instruction in reading, including the essential components of reading instruction'
                  name='lackAppropriateInstructionReading'
                  {...validationProps('lackAppropriateInstructionReading')}
                  style={{ marginBottom: '0' }}
                >
                  <Radio.Group
                    onChange={e => {
                      setLackAppropriateInstructionReading(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={
                      formDisabledState.lackAppropriateInstructionReading
                    }
                    name='lackAppropriateInstructionReading'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>

                {lackAppropriateInstructionReading && (
                  <Form.Item
                    label='Evidence Provided'
                    name='lackAppropriateInstructionReadingEvidence'
                    {...validationProps(
                      'lackAppropriateInstructionReadingEvidence'
                    )}
                  >
                    <Editor
                      formField='lackAppropriateInstructionReadingEvidence'
                      disabled={
                        formDisabledState.lackAppropriateInstructionReadingEvidence
                      }
                      {...handleEditorEvents}
                    />
                  </Form.Item>
                )}
              </Card>

              <Card
                title='Math'
                style={{
                  marginBottom: 30
                }}
              >
                <FormItem
                  label='Lack of appropriate instruction in math'
                  name='lackAppropriateInstructionMath'
                  {...validationProps('lackAppropriateInstructionMath')}
                  style={{ marginBottom: '0' }}
                >
                  <Radio.Group
                    onChange={e => {
                      setLackAppropriateInstructionMath(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={formDisabledState.lackAppropriateInstructionMath}
                    name='lackAppropriateInstructionMath'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>

                {lackAppropriateInstructionMath && (
                  <Form.Item
                    label='Evidence Provided'
                    name='lackAppropriateInstructionMathEvidence'
                    {...validationProps(
                      'lackAppropriateInstructionMathEvidence'
                    )}
                  >
                    <Editor
                      formField='lackAppropriateInstructionMathEvidence'
                      disabled={
                        formDisabledState.lackAppropriateInstructionMathEvidence
                      }
                      {...handleEditorEvents}
                    />
                  </Form.Item>
                )}
              </Card>

              <Card
                title='English'
                style={{
                  marginBottom: 30
                }}
              >
                <FormItem
                  label='English Learner Status'
                  name='factorEnglishLearnerStatus'
                  {...validationProps('factorEnglishLearnerStatus')}
                  style={{ marginBottom: '0' }}
                >
                  <Radio.Group
                    onChange={e => {
                      setFactorEnglishLearnerStatus(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={formDisabledState.factorEnglishLearnerStatus}
                    name='factorEnglishLearnerStatus'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>

                {factorEnglishLearnerStatus && (
                  <Form.Item
                    label='Evidence Provided'
                    name='factorEnglishLearnerStatusEvidence'
                    {...validationProps('factorEnglishLearnerStatusEvidence')}
                  >
                    <Editor
                      formField='factorEnglishLearnerStatusEvidence'
                      disabled={
                        formDisabledState.factorEnglishLearnerStatusEvidence
                      }
                      {...handleEditorEvents}
                    />
                  </Form.Item>
                )}
              </Card>
              <Alert
                style={{ marginBottom: '24px' }}
                message='If any of the above answers is “yes,” the student is not eligible for services under IDEA and the team must complete the Eligibility Determination section accordingly. If all of the answers are “no,” complete the following sections.'
                type='info'
              />
            </Card>

            <Card
              title='Exclusionary Criteria'
              style={{
                marginBottom: 30
              }}
            >
              <Title level={4}>
                The team determined that the following factors are the primary
                basis for the student's learning difficulties. Document the
                source of evidence in each area:
              </Title>

              <Card
                title='Visual, hearing, or motor disability'
                style={{
                  marginBottom: 30
                }}
              >
                <FormItem
                  label='The primary basis for the student’s learning difficulties is a visual, hearing or motor disability'
                  name='factorVisualHearingMotor'
                  {...validationProps('factorVisualHearingMotor')}
                  style={{ marginBottom: '0' }}
                >
                  <Radio.Group
                    onChange={e => {
                      setFactorVisualHearingMotor(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={formDisabledState.factorVisualHearingMotor}
                    name='factorVisualHearingMotor'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>

                {factorVisualHearingMotor && (
                  <Form.Item
                    label='Source of evidence'
                    name='factorVisualHearingMotorEvidence'
                    {...validationProps('factorVisualHearingMotorEvidence')}
                  >
                    <Editor
                      formField='factorVisualHearingMotorEvidence'
                      disabled={
                        formDisabledState.factorVisualHearingMotorEvidence
                      }
                      {...handleEditorEvents}
                    />
                  </Form.Item>
                )}
              </Card>

              <Card
                title='Intellectual disability'
                style={{
                  marginBottom: 30
                }}
              >
                <FormItem
                  label='The primary basis for the student’s learning difficulties is an intellectual disability'
                  name='factorIntellectual'
                  {...validationProps('factorIntellectual')}
                  style={{ marginBottom: '0' }}
                >
                  <Radio.Group
                    onChange={e => {
                      setFactorIntellectual(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={formDisabledState.factorIntellectual}
                    name='factorIntellectual'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>

                {factorIntellectual && (
                  <Form.Item
                    label='Source of evidence'
                    name='factorIntellectualEvidence'
                    {...validationProps('factorIntellectualEvidence')}
                  >
                    <Editor
                      formField='factorIntellectualEvidence'
                      disabled={formDisabledState.factorIntellectualEvidence}
                      {...handleEditorEvents}
                    />
                  </Form.Item>
                )}
              </Card>

              <Card
                title='Emotional disability'
                style={{
                  marginBottom: 30
                }}
              >
                <FormItem
                  label='The primary basis for the student’s learning difficulties is a emotional disability'
                  name='factorEmotional'
                  {...validationProps('factorEmotional')}
                  style={{ marginBottom: '0' }}
                >
                  <Radio.Group
                    onChange={e => {
                      setFactorEmotional(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={formDisabledState.factorEmotional}
                    name='factorEmotional'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>

                {factorEmotional && (
                  <Form.Item
                    label='Source of evidence'
                    name='factorEmotionalEvidence'
                    {...validationProps('factorEmotionalEvidence')}
                  >
                    <Editor
                      formField='factorEmotionalEvidence'
                      disabled={formDisabledState.factorEmotionalEvidence}
                      {...handleEditorEvents}
                    />
                  </Form.Item>
                )}
              </Card>

              <Card
                title='Cultural'
                style={{
                  marginBottom: 30
                }}
              >
                <FormItem
                  label='The primary basis for the student’s learning difficulties are cultural factors'
                  name='factorCultural'
                  {...validationProps('factorCultural')}
                  style={{ marginBottom: '0' }}
                >
                  <Radio.Group
                    onChange={e => {
                      setFactorCultural(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={formDisabledState.factorCultural}
                    name='factorCultural'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>

                {factorCultural && (
                  <Form.Item
                    label='Source of evidence'
                    name='factorCulturalEvidence'
                    {...validationProps('factorCulturalEvidence')}
                  >
                    <Editor
                      formField='factorCulturalEvidence'
                      disabled={formDisabledState.factorCulturalEvidence}
                      {...handleEditorEvents}
                    />
                  </Form.Item>
                )}
              </Card>

              <Card
                title='Environmental or economic disadvantage'
                style={{
                  marginBottom: 30
                }}
              >
                <FormItem
                  label='The primary basis for the student’s learning difficulties is an environmental or economic disadvantage'
                  name='factorEnvironmentalEconomic'
                  {...validationProps('factorEnvironmentalEconomic')}
                  style={{ marginBottom: '0' }}
                >
                  <Radio.Group
                    onChange={e => {
                      setFactorEnvironmentalEconomic(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={formDisabledState.factorEnvironmentalEconomic}
                    name='factorEnvironmentalEconomic'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>

                {factorEnvironmentalEconomic && (
                  <Form.Item
                    label='Source of evidence'
                    name='factorEnvironmentalEconomicEvidence'
                    {...validationProps('factorEnvironmentalEconomicEvidence')}
                  >
                    <Editor
                      formField='factorEnvironmentalEconomicEvidence'
                      disabled={
                        formDisabledState.factorEnvironmentalEconomicEvidence
                      }
                      {...handleEditorEvents}
                    />
                  </Form.Item>
                )}
              </Card>
              <Alert
                style={{ marginBottom: '24px' }}
                message='If any of the boxes immediately above is checked “Yes,” the student cannot have a specific learning disability and the team must complete the Eligibility Determination section accordingly. If all of the answers are “no,” complete the following sections.'
                type='info'
              />
            </Card>

            <Card
              title='Inclusionary Criteria'
              style={{
                marginBottom: 30
              }}
            >
              <Card
                title='Educational Progress (Over Time)'
                style={{
                  marginBottom: 30
                }}
              >
                <Alert
                  style={{ marginBottom: '24px' }}
                  message="Evidence in the Documentation of Evaluation Results should support the team's answer to this question."
                  type='info'
                />
                <FormItem
                  label='Is the student progressing at a significantly slower rate than is expected in any areas of concern?'
                  name='factorSlowerRate'
                  {...validationProps('factorSlowerRate')}
                >
                  <Radio.Group
                    onChange={e => {
                      setFactorSlowerRate(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    disabled={formDisabledState.factorSlowerRate}
                    name='factorSlowerRate'
                  >
                    <Space direction='vertical'>
                      <Radio value='no'>No</Radio>
                      <Radio
                        value='slow'
                        style={{ whiteSpace: 'break-spaces' }}
                      >
                        Yes, The student is progressing at a significantly
                        slower rate than expected
                      </Radio>
                      <Radio
                        value='acceptable'
                        style={{ whiteSpace: 'break-spaces' }}
                      >
                        Yes, The student is currently making an acceptable rate
                        of progress, but only because of the intensity of the
                        intervention that is being provided.
                      </Radio>
                    </Space>
                  </Radio.Group>
                </FormItem>

                {factorSlowerRate !== 'no' && (
                  <Form.Item
                    label='If "Yes," in which area(s)?'
                    name='factorSlowerRateAreas'
                    {...validationProps('factorSlowerRateAreas')}
                  >
                    <Editor
                      formField='factorSlowerRateAreas'
                      disabled={formDisabledState.factorSlowerRateAreas}
                      {...handleEditorEvents}
                    />
                  </Form.Item>
                )}
              </Card>

              <Card
                title='Discrepancy(At One Point in Time)'
                style={{
                  marginBottom: 30
                }}
              >
                <Alert
                  style={{ marginBottom: '24px' }}
                  message="Evidence in the Documentation of Evaluation Results should support the team's answer to this question."
                  type='info'
                />
                <FormItem
                  label='Is the student’s performance significantly below performance of peers or expected standards in any areas of concern?'
                  name='factorBelowPerformance'
                  {...validationProps('factorBelowPerformance')}
                >
                  <Radio.Group
                    onChange={e => {
                      setFactorBelowPerformance(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    disabled={formDisabledState.factorBelowPerformance}
                    name='factorBelowPerformance'
                  >
                    <Space direction='vertical'>
                      <Radio value='no'>No</Radio>
                      <Radio
                        value='discrepant'
                        style={{ whiteSpace: 'break-spaces' }}
                      >
                        Yes, The student’s performance is significantly
                        discrepant
                      </Radio>
                      <Radio
                        value='notdiscrepant'
                        style={{ whiteSpace: 'break-spaces' }}
                      >
                        The student’s performance is not currently discrepant
                        but only because of the intensity of the intervention
                        that is being provided.
                      </Radio>
                    </Space>
                  </Radio.Group>
                </FormItem>

                {factorBelowPerformance !== 'no' && (
                  <Form.Item
                    label='If "Yes," in which area(s)?'
                    name='factorBelowPerformanceAreas'
                    {...validationProps('factorBelowPerformanceAreas')}
                  >
                    <Editor
                      formField='factorBelowPerformanceAreas'
                      disabled={formDisabledState.factorBelowPerformanceAreas}
                      {...handleEditorEvents}
                    />
                  </Form.Item>
                )}
              </Card>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454D}
              extractDataProp='printStudentForm3454D'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454D = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454D_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454D: form } = versionInfo
    const { student } = studentInfo
    const { formDate, studentFullName } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454D
