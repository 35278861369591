import React from 'react'

import Button from 'antd/lib/button'
import { Card, Typography, Col, Row, Progress } from 'antd'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

const { Text } = Typography
export const CurrentGoals = ({ x }) => {
  return (
    <>
      {x != undefined ? (
        <>
          <Row>
            <Col span={24}>
              <Card
                title={`Student Goal: ${x.studentFormId}`}
                size='small'
                // bodyStyle={{ backgroundColor: 'rgba(232, 236, 241, .4)' }}
                style={{ height: '100%' }}
              >
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={8}>
                        <Col>
                          <Text strong={true}>Goal Statement: &nbsp;</Text>
                          <Text>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: x?.submittedData.goalStatement
                              }}
                            />
                          </Text>
                        </Col>
                        <Col>
                          <Text strong={true}>Goal Number: &nbsp;</Text>
                          <Text>{x.submittedData.goalNumber}</Text>
                        </Col>
                        <Col>
                          <Text strong={true}>Goal Date: &nbsp;</Text>
                          <Text>
                            {' '}
                            {dayjs(x.submittedData.formDate).format(
                              'MM-DD-YYYY'
                            )}
                          </Text>
                        </Col>
                        <Col>
                          <Text strong={true}>Initial Evaluation: &nbsp;</Text>
                          <Text>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  x?.submittedData.initialEvaluationResults
                              }}
                            />
                          </Text>
                        </Col>
                      </Col>
                      <Col span={8}>
                        {x?.submittedData.evaluationMeasures.map(x => (
                          <>
                            {' '}
                            <Col>
                              {' '}
                              <Text strong={true}>
                                Short Term Objective: &nbsp;
                              </Text>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: x?.shortTermObjective
                                }}
                              />{' '}
                            </Col>
                            <Col>
                              <Text strong={true}>
                                Evaluation Criteria Attempt: &nbsp;
                              </Text>
                              <Text>{x.evaluationCriteriaAttempts}</Text>
                            </Col>
                            <Col>
                              <Text strong={true}>
                                Evaluation Criteria Total Attempts: &nbsp;
                              </Text>
                              <Text>{x.evaluationCriteriaTotalAttempts}</Text>
                            </Col>
                            <Col>
                              <Text strong={true}>
                                Evaluation Criteria Accuracy Percentage: &nbsp;
                              </Text>
                              <Progress
                                type='circle'
                                width={50}
                                percent={x.evaluationCriteriaAccuracyPercent}
                              ></Progress>
                            </Col>
                          </>
                        ))}
                      </Col>
                      <Col span={8}>
                        {x?.submittedData.evaluationMeasures.map(x => (
                          <>
                            <Col>
                              <Text strong={true}>
                                Determing Progress Schedule: &nbsp;
                              </Text>
                              {x.scheduleDeterminingProgress.map(x => (
                                <Text>{x}&nbsp;</Text>
                              ))}
                            </Col>
                            <Col>
                              <Text strong={true}>
                                Evaluation Procedures: &nbsp;
                              </Text>
                              {x.evaluationProcedures.map(x => (
                                <Text>{x}&nbsp;</Text>
                              ))}
                            </Col>
                            <Col>
                              <Text strong={true}>
                                Evaluation Prodedure Description: &nbsp;
                              </Text>
                              <Text>{x.evaluationProcedureOtherText}</Text>
                            </Col>
                            <Col>
                              <Text strong={true}>
                                Determining Progress Description: &nbsp;
                              </Text>
                              <Text>
                                {x.scheduleDeterminingProgressOtherText}
                              </Text>
                            </Col>
                            <Col>
                              <Text strong={true}>
                                Goal Implementer: &nbsp;
                              </Text>
                              <Text>{x.goalImplementers}</Text>
                            </Col>
                          </>
                        ))}
                        <Col>
                          <Text strong={true}>
                            Illinois Learning Standard: &nbsp;
                          </Text>
                          <Text>
                            {x.submittedData.illinoisLearningStandard}
                          </Text>
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                  {x?.submittedData.progressUpdates.map(x => (
                    <Col span={8}>
                      <Card
                        title={`Progress report: ${x.progressReport}`}
                        size='small'
                        style={{ height: '100%' }}
                      >
                        <Col>
                          <Text strong={true}>
                            Progress Update Date: &nbsp;
                          </Text>
                          <Text>
                            {' '}
                            {dayjs(x.formDate).format('MM-DD-YYYY')}
                          </Text>
                        </Col>
                        {x.makingExpectedProgress === '' ? (
                          <></>
                        ) : (
                          <>
                            <Col>
                              <Text strong={true}>
                                Making Expected Progress:{' '}
                              </Text>
                              <Text>{x.makingExpectedProgress}</Text>
                            </Col>
                          </>
                        )}

                        {x.notMakingExpectedProgress === '' ? (
                          <></>
                        ) : (
                          <>
                            <Col>
                              <Text strong={true}>
                                Not Making Expected Progress:{' '}
                              </Text>
                              <Text>{x.notMakingExpectedProgress}</Text>
                            </Col>
                          </>
                        )}
                        {x.completed === '' ? (
                          <></>
                        ) : (
                          <>
                            <Col>
                              <Text strong={true}>Completed: </Text>
                              <Text>{x.completed}</Text>
                            </Col>
                          </>
                        )}

                        <Col>
                          <Text strong={true}>Type of Report: </Text>
                          <Text>{x.typeOfReport}</Text>
                        </Col>

                        <Col>
                          <Text strong={true}>Additional Comments: </Text>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: x?.additionalComments
                            }}
                          />
                        </Col>
                        {/* <Row justify='center'>
                          <Col>
                            <Link to={{
                              pathname: `/student-form/${x?.submittedData.studentMeetingId}`,
                              state: { currentGoal: x.submittedData }
                            }} ><Button>Edit Progress Update</Button></Link>

                          </Col>
                        </Row> */}
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Row justify='center'>
                  <Col>
                    {/* this button doesn't work if it's an old meeting/bad data */}
                    <Link
                      to={`/student-form/${x?.submittedData.studentMeetingId}`}
                      state={{ currentGoal: x.submittedData }}
                    >
                      <Button>Edit Goal</Button>
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
