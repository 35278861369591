import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454B_VERSION } from '../../../../operations/queries/forms/3454B'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Radio, Typography, Space } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import FormItem from 'antd/lib/form/FormItem'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454B } from '../../../../operations/mutations/forms/3454B'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const [lackInstructionReading, setLackInstructionReading] = useState(
    data?.lackInstructionReading
  )
  const [lackInstructionMath, setLackInstructionMath] = useState(
    data?.lackInstructionMath
  )
  const [englishLearnerStatus, setEnglishLearnerStatus] = useState(
    data?.englishLearnerStatus
  )
  const [disibilityIdentified, setDisibilityIdentified] = useState(
    data?.disibilityIdentified
  )
  const [adverseEffects, setAdverseEffects] = useState(data?.adverseEffects)

  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      case 'lackInstructionReading':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setLackInstructionReading(update[fieldName])
        break
      case 'lackInstructionMath':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setLackInstructionMath(update[fieldName])
        break
      case 'englishLearnerStatus':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setEnglishLearnerStatus(update[fieldName])
        break
      case 'adverseEffects':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setAdverseEffects(update[fieldName])
        break
      case 'disibilityIdentified':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setDisibilityIdentified(update[fieldName])
        break
      case 'primaryAutism':
      case 'secondaryAutism':
      case 'primaryDeaf':
      case 'secondaryDeaf':
      case 'primaryDeafness':
      case 'secondaryDeafness':
      case 'primaryDevelopmentDelay':
      case 'secondaryDevelopmentDelay':
      case 'primaryEmotionalDisability':
      case 'secondaryEmotionalDisability':
      case 'primaryHearingIMpairment':
      case 'secondaryHearingIMpairment':
      case 'primaryItellactualDisability':
      case 'secondaryItellactualDisability':
      case 'primaryMultipleDisabilities':
      case 'secondaryMultipleDisabilities':
      case 'primaryOrthopedicImpairment':
      case 'secondaryOrthopedicImpairment':
      case 'primaryOtherHealthImpairment':
      case 'secondaryOtherHealthImpairment':
      case 'primarySpeechImpairment':
      case 'secondarySpeechImpairment':
      case 'primaryBrainInjury':
      case 'secondaryBrainInjury':
      case 'primaryVisualImpairment':
      case 'eligibility':
      case 'secondaryVisualImpairment': //Logic applied to case="primaryAutism" down through this case
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,

          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454B'
            name='studentForm3454B'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              ELIGIBILITY DETERMINATION
              <br />
              ALL DISABILITIES (OTHER THAN SPECIFIC LEARNING DISABILITY)
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>

            <Card
              title='DETERMINANT FACTORS'
              style={{
                marginBottom: 30
              }}
            >
              <h1>
                The determinant factor for the student’s suspected disability
                is:
              </h1>
              <br />
              <FormItem
                label='Lack of appropriate instruction in reading, including the essential components of reading instruction'
                name='lackInstructionReading'
                {...validationProps('lackInstructionReading')}
              >
                <Radio.Group
                  onChange={e => {
                    setLackInstructionReading(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.lackInstructionReading}
                  name='lackInstructionReading'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              {lackInstructionReading && (
                <Form.Item
                  label='Evidence Provided'
                  name='evidenceLackInstructionReading'
                  {...validationProps('evidenceLackInstructionReading')}
                >
                  <Editor
                    formField='evidenceLackInstructionReading'
                    disabled={formDisabledState.evidenceLackInstructionReading}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              )}

              <FormItem
                label='Lack of appropriate instruction in math'
                name='lackInstructionMath'
                {...validationProps('lackInstructionMath')}
              >
                <Radio.Group
                  onChange={e => {
                    setLackInstructionMath(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.lackInstructionMath}
                  name='lackInstructionMath'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              {lackInstructionMath && (
                <Form.Item
                  label='Source of evidence'
                  name='evidenceLackInstructionMath'
                  {...validationProps('evidenceLackInstructionMath')}
                >
                  <Editor
                    formField='evidenceLackInstructionMath'
                    disabled={formDisabledState.evidenceLackInstructionMath}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              )}

              <FormItem
                label='English learner status'
                name='englishLearnerStatus'
                {...validationProps('englishLearnerStatus')}
              >
                <Radio.Group
                  onChange={e => {
                    setEnglishLearnerStatus(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.englishLearnerStatus}
                  name='englishLearnerStatus'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              {englishLearnerStatus && (
                <Form.Item
                  label='Source of evidence'
                  name='evidenceEnglishLearnerStatus'
                  {...validationProps('evidenceEnglishLearnerStatus')}
                >
                  <Editor
                    formField='evidenceEnglishLearnerStatus'
                    disabled={formDisabledState.evidenceEnglishLearnerStatus}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              )}
            </Card>

            {(lackInstructionReading ||
              lackInstructionMath ||
              englishLearnerStatus ||
              (!lackInstructionReading &&
                !lackInstructionMath &&
                !englishLearnerStatus)) && (
                <Card
                  title='COMPLETE FOR STUDENTS SUSPECTED OF HAVING A DISABILITY UNDER IDEA'
                  style={{
                    marginBottom: 30
                  }}
                >
                  <h1>
                    <strong>STEP 1 – DISABILITY</strong>
                  </h1>
                  <hr />
                  <br />
                  <FormItem
                    name='disibilityIdentified'
                    {...validationProps('disibilityIdentified')}
                  >
                    <Radio.Group
                      {...handleFocusEvents}
                      onChange={e => {
                        setDisibilityIdentified(e.target.value)
                        handleMouseEvents.onChange(e)
                      }}
                      buttonStyle='solid'
                      disabled={formDisabledState.disibilityIdentified}
                      name='disibilityIdentified'
                    >
                      <Radio value={false} style={{ whiteSpace: 'break-spaces' }}>
                        <strong>No Disability Identified</strong> (Complete Step 4
                        and write “Not Eligible for Special Education Services” in
                        the Disability section of the Conference Summary Report
                        page.)
                        <br />
                        <br />
                      </Radio>
                      <Radio value style={{ whiteSpace: 'break-spaces' }}>
                        <strong>Disability Identified</strong> Based on the team’s
                        analysis, identify the disability(s):
                      </Radio>
                    </Radio.Group>
                  </FormItem>

                  {disibilityIdentified && (
                    <Card
                      style={{
                        marginBottom: 30
                      }}
                    >
                      <Space size='large' align='start'>
                        <strong>Primary</strong>
                        <pre>
                          <strong> Secondary</strong>
                        </pre>
                      </Space>
                      <br />
                      <Space size='large' align='start'>
                        <Form.Item
                          name='primaryAutism'
                          {...validationProps('primaryAutism')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.primaryAutism}
                            name='primaryAutism'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name='secondaryAutism'
                          {...validationProps('secondaryAutism')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.secondaryAutism}
                            name='secondaryAutism'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div style={{ paddingTop: '5px' }}>
                          &nbsp;&nbsp;&nbsp;Autism (O)
                        </div>
                      </Space>
                      <br />
                      <Space size='large' align='start'>
                        <Form.Item
                          name='primaryDeaf'
                          {...validationProps('primaryDeaf')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.primaryDeaf}
                            name='primaryDeaf'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name='secondaryDeaf'
                          {...validationProps('secondaryDeaf')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.secondaryDeaf}
                            name='secondaryDeaf'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div style={{ paddingTop: '5px' }}>
                          &nbsp;&nbsp;&nbsp;Deaf/Blindness (H)
                          <br />
                        </div>
                      </Space>
                      <br />
                      <Space size='large' align='start'>
                        <Form.Item
                          name='primaryDeafness'
                          {...validationProps('primaryDeafness')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.primaryDeafness}
                            name='primaryDeafness'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name='secondaryDeafness'
                          {...validationProps('secondaryDeafness')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.secondaryDeafness}
                            name='secondaryDeafness'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div style={{ paddingTop: '5px' }}>
                          &nbsp;&nbsp;&nbsp;Deafness (G)
                          <br />
                        </div>
                      </Space>
                      <br />
                      <Space size='large' align='start'>
                        <Form.Item
                          name='primaryDevelopmentDelay'
                          {...validationProps('primaryDevelopmentDelay')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.primaryDevelopmentDelay}
                            name='primaryDevelopmentDelay'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name='secondaryDevelopmentDelay'
                          {...validationProps('secondaryDevelopmentDelay')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.secondaryDevelopmentDelay}
                            name='secondaryDevelopmentDelay'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div style={{ paddingTop: '5px' }}>
                          &nbsp;&nbsp;&nbsp;Developmental Delay (3-9) (N)
                          <br />
                        </div>
                      </Space>
                      <br />
                      <Space size='large' align='start'>
                        <Form.Item
                          name='primaryEmotionalDisability'
                          {...validationProps('primaryEmotionalDisability')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={
                              formDisabledState.primaryEmotionalDisability
                            }
                            name='primaryEmotionalDisability'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name='secondaryEmotionalDisability'
                          {...validationProps('secondaryEmotionalDisability')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={
                              formDisabledState.secondaryEmotionalDisability
                            }
                            name='secondaryEmotionalDisability'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div style={{ paddingTop: '5px' }}>
                          &nbsp;&nbsp;&nbsp;Emotional Disability (K)
                          <br />
                        </div>
                      </Space>
                      <br />
                      <Space size='large' align='start'>
                        <Form.Item
                          name='primaryHearingIMpairment'
                          {...validationProps('primaryHearingIMpairment')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.primaryHearingIMpairment}
                            name='primaryHearingIMpairment'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name='secondaryHearingIMpairment'
                          {...validationProps('secondaryHearingIMpairment')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={
                              formDisabledState.secondaryHearingIMpairment
                            }
                            name='secondaryHearingIMpairment'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div style={{ paddingTop: '5px' }}>
                          &nbsp;&nbsp;&nbsp;Hearing Impairment (F)
                          <br />
                        </div>
                      </Space>
                      <br />
                      <Space size='large' align='start'>
                        <Form.Item
                          name='primaryItellactualDisability'
                          {...validationProps('primaryItellactualDisability')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={
                              formDisabledState.primaryItellactualDisability
                            }
                            name='primaryItellactualDisability'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name='secondaryItellactualDisability'
                          {...validationProps('secondaryItellactualDisability')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={
                              formDisabledState.secondaryItellactualDisability
                            }
                            name='secondaryItellactualDisability'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div style={{ paddingTop: '5px' }}>
                          &nbsp;&nbsp;&nbsp;Intellectual Disability (A)
                          <br />
                        </div>
                      </Space>
                      <br />
                      <Space size='large' align='start'>
                        <Form.Item
                          name='primaryMultipleDisabilities'
                          {...validationProps('primaryMultipleDisabilities')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={
                              formDisabledState.primaryMultipleDisabilities
                            }
                            name='primaryMultipleDisabilities'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name='secondaryMultipleDisabilities'
                          {...validationProps('secondaryMultipleDisabilities')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={
                              formDisabledState.secondaryMultipleDisabilities
                            }
                            name='secondaryMultipleDisabilities'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div style={{ paddingTop: '5px' }}>
                          &nbsp;&nbsp;&nbsp;Multiple Disabilities (M)
                          <br />
                        </div>
                      </Space>
                      <br />
                      <Space size='large' align='start'>
                        <Form.Item
                          name='primaryOrthopedicImpairment'
                          {...validationProps('primaryOrthopedicImpairment')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={
                              formDisabledState.primaryOrthopedicImpairment
                            }
                            name='primaryOrthopedicImpairment'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name='secondaryOrthopedicImpairment'
                          {...validationProps('secondaryOrthopedicImpairment')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={
                              formDisabledState.secondaryOrthopedicImpairment
                            }
                            name='secondaryOrthopedicImpairment'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div style={{ paddingTop: '5px' }}>
                          &nbsp;&nbsp;&nbsp;Orthopedic Impairment (C)
                          <br />
                        </div>
                      </Space>
                      <br />
                      <Space size='large' align='start'>
                        <Form.Item
                          name='primaryOtherHealthImpairment'
                          {...validationProps('primaryOtherHealthImpairment')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={
                              formDisabledState.primaryOtherHealthImpairment
                            }
                            name='primaryOtherHealthImpairment'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name='secondaryOtherHealthImpairment'
                          {...validationProps('secondaryOtherHealthImpairment')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={
                              formDisabledState.secondaryOtherHealthImpairment
                            }
                            name='secondaryOtherHealthImpairment'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div style={{ paddingTop: '5px' }}>
                          &nbsp;&nbsp;&nbsp;Other Health Impairment (L)
                          <br />
                        </div>
                      </Space>
                      <br />
                      <Space size='large' align='start'>
                        <Form.Item
                          name='primarySpeechImpairment'
                          {...validationProps('primarySpeechImpairment')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.primarySpeechImpairment}
                            name='primarySpeechImpairment'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name='secondarySpeechImpairment'
                          {...validationProps('secondarySpeechImpairment')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.secondarySpeechImpairment}
                            name='secondarySpeechImpairment'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div style={{ paddingTop: '5px' }}>
                          &nbsp;&nbsp;&nbsp;Speech or Language Impairment (I)
                          <br />
                        </div>
                      </Space>
                      <br />
                      <Space size='large' align='start'>
                        <Form.Item
                          name='primaryBrainInjury'
                          {...validationProps('primaryBrainInjury')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.primaryBrainInjury}
                            name='primaryBrainInjury'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name='secondaryBrainInjury'
                          {...validationProps('secondaryBrainInjury')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.secondaryBrainInjury}
                            name='secondaryBrainInjury'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div style={{ paddingTop: '5px' }}>
                          &nbsp;&nbsp;&nbsp;Traumatic Brain Injury (P)
                          <br />
                        </div>
                      </Space>
                      <br />
                      <Space size='large' align='start'>
                        <Form.Item
                          name='primaryVisualImpairment'
                          {...validationProps('primaryVisualImpairment')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.primaryVisualImpairment}
                            name='primaryVisualImpairment'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name='secondaryVisualImpairment'
                          {...validationProps('secondaryVisualImpairment')}
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.secondaryVisualImpairment}
                            name='secondaryVisualImpairment'
                          >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div style={{ paddingTop: '5px' }}>
                          &nbsp;&nbsp;&nbsp;Visual Impairment including Blindness
                          (E)
                        </div>
                      </Space>
                    </Card>
                  )}
                  {!lackInstructionReading &&
                    !lackInstructionMath &&
                    !englishLearnerStatus && (
                      <>
                        <h1>
                          <strong>STEP 2 – ADVERSE EFFECTS</strong>
                        </h1>
                        <hr />
                        <br />
                        <FormItem
                          name='adverseEffects'
                          {...validationProps('adverseEffects')}
                          hidden={
                            lackInstructionReading &&
                            lackInstructionMath &&
                            englishLearnerStatus
                          }
                        >
                          <Radio.Group
                            {...handleMouseEvents}
                            onChange={e => {
                              setAdverseEffects(e.target.value)
                              handleMouseEvents.onChange(e)
                            }}
                            buttonStyle='solid'
                            disabled={formDisabledState.adverseEffects}
                            name='adverseEffects'
                          >
                            <Radio
                              value={false}
                              style={{ whiteSpace: 'break-spaces' }}
                            >
                              <strong>No Adverse Effect Identified. </strong>
                              (Complete Step 4 and write “Not Eligible for Special
                              Education Services” in the Disability section of the
                              Conference Summary Report page.)
                              <br />
                              <br />
                            </Radio>
                            <Radio value style={{ whiteSpace: 'break-spaces' }}>
                              <strong>Adverse Effect Identified. </strong>For each
                              disability identified, describe how the disability
                              adversely affects the student’s educational
                              performance.
                            </Radio>
                          </Radio.Group>
                        </FormItem>
                      </>
                    )}
                  {adverseEffects && (
                    <Form.Item
                      label='Description of Adverse Effect of Each Disability'
                      name='adverseEffectsDescription'
                      {...validationProps('adverseEffectsDescription')}
                    >
                      <Editor
                        formField='adverseEffectsDescription'
                        disabled={formDisabledState.adverseEffectsDescription}
                        {...handleEditorEvents}
                      />
                    </Form.Item>
                  )}
                  {!lackInstructionReading &&
                    !lackInstructionMath &&
                    !englishLearnerStatus && (
                      <>
                        <h1>
                          <strong>STEP 3 – EDUCATIONAL NEEDS</strong>
                        </h1>
                        <hr />
                        <br />
                        <p>
                          State to what extent the student requires special
                          education and related services to address educational
                          needs.
                        </p>
                      </>
                    )}
                  <FormItem
                    name='educationalNeedsDescription'
                    {...validationProps('educationalNeedsDescription')}
                    hidden={
                      !(
                        !lackInstructionReading &&
                        !lackInstructionMath &&
                        !englishLearnerStatus
                      )
                    }
                  >
                    <Editor
                      formField='educationalNeedsDescription'
                      disabled={formDisabledState.educationalNeedsDescription}
                      {...handleEditorEvents}
                    />
                  </FormItem>

                  <h1>
                    <strong>STEP 4 – ELIGIBILITY</strong>
                  </h1>
                  <hr />
                  <br />
                  <FormItem
                    name='eligibility'
                    {...validationProps('eligibility')}
                    label='Based on the steps above, the student is entitled to special education and related services.'
                  >
                    <Radio.Group
                      onChange={e => {
                        handleMouseEvents.onChange(e)
                      }}
                      buttonStyle='solid'
                      disabled={formDisabledState.eligibility}
                      name='eligibility'
                    >
                      <Radio.Button value={false}>
                        <strong>No (Not Eligible) </strong>
                      </Radio.Button>
                      <Radio.Button value>
                        <strong>Yes (Eligible)</strong>
                      </Radio.Button>
                    </Radio.Group>
                  </FormItem>
                </Card>
              )}
            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454B}
              extractDataProp='printStudentForm3454B'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454B = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454B_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454B: form } = versionInfo
    const { student } = studentInfo
    const { formDate, studentFullName } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454B
