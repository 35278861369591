import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { useLazyQuery } from "@apollo/client";

import { Form, Input } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { QUERY_STUDENT_DOB } from "../../../../operations/queries/forms/SIS";
import SyncSISButton from "../../../elements/formItems/SyncSISButton";

const StudentDobSIS = ({
  form,
  sis,
  id,
  initialValue,
  error,
  disabled = true,
}) => {
  const [getStudentDOB, { data: studentDOBData, loading: dobLoading }] =
    useLazyQuery(QUERY_STUDENT_DOB);
  const [studentDob, setStudentDob] = useState(initialValue);

  useEffect(() => {
    if (!studentDOBData) return;
    const studentDob =
      dayjs(studentDOBData.student.dateOfBirth).format("MM-DD-YYYY") || "";
    //dayjs(studentDOBData.student.dateOfBirth).format("YYYY-MM-DD") || "";
    form.setFieldsValue({ studentDob });
    setStudentDob(studentDob);
  }, [studentDOBData]);
  return (
    <Form.Item
      label="Student Date of Birth"
      name="studentDob"
      validateStatus={error ? "error" : ""}
      help={error}
    >
      <Input
        onChange={(e) => setStudentDob(e.target.value)}
        disabled={disabled}
        addonAfter={
          <SyncSISButton
            loading={dobLoading}
            sisValue={dayjs(sis?.student.dateOfBirth).format("MM-DD-YYYY")}
            //sisValue={dayjs(sis?.student.dateOfBirth).format("YYYY-MM-DD")}
            currentValue={dayjs(studentDob).format("MM-DD-YYYY")}
          //currentValue={dayjs(studentDob).format("YYYY-MM-DD")}

          >
            <SyncOutlined
              onClick={() => getStudentDOB({ variables: { id: id } })}
            />
          </SyncSISButton>
        }
      />
    </Form.Item>
  );
};

StudentDobSIS.propTypes = {
  form: PropTypes.object.isRequired,
  initialValue: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

export default StudentDobSIS;
