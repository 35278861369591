import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {
  Modal,
  Row,
  Col,
  Card,
  Button,
  Select,
  Form,
  DatePicker,
  Switch,
  Input,
  Popconfirm,
  Collapse,
  Typography
} from 'antd'
import { IepFormContext } from '../../../utils/iepFormProvider'
import AuthContext from '../../../utils/authProvider'
import { useParams } from 'react-router-dom'
import LookupSelectGeneric from '../../elements/common/LookupSelectGeneric'
import MeetingSISForm from './MeetingSISForm'
import MeetingSISProvider from '../../../utils/meetingSISProvider'
// apollo & operations
import { useMutation, useQuery } from '@apollo/client'
import { QUERY_STUDENT_MEETING_FORMS } from '../../../operations/queries/meeting'
import { UPDATE_STUDENT_MEETING_GENERAL } from '../../../operations/mutations/meetings'
import { DELETE_STUDENT_MEETING_PARENT_NOTIFICATION } from '../../../operations/mutations/meetings'
import { UPDATE_STUDENT_MEETING_PARENT_NOTIFICATION } from '../../../operations/mutations/meetings'
import { INSERT_STUDENT_MEETING_PARENT_NOTIFICATION } from '../../../operations/mutations/meetings'
import {
  SettingOutlined,
  PlusSquareOutlined,
  DeleteOutlined,
  CaretRightOutlined
} from '@ant-design/icons'
import {
  notificationSuccess,
  notificationError
} from '../../../utils/notification'

const EditMeetingInfo = ({ studentData }) => {
  const { Panel } = Collapse
  const { Text } = Typography
  const { Option } = Select
  const { studentMeetingId } = useParams()
  const Auth = useContext(AuthContext)
  const { setStudentFormInfo, setStudentMeetingId, setMeetingDate } =
    useContext(IepFormContext)
  const { data, loading } = useQuery(QUERY_STUDENT_MEETING_FORMS, {
    variables: { id: studentMeetingId }
  })
  const [updateMeetingInfo, { loading: updateMeetingLoading }] = useMutation(
    UPDATE_STUDENT_MEETING_GENERAL,
    {
      refetchQueries: [
        {
          query: QUERY_STUDENT_MEETING_FORMS,
          variables: { id: studentMeetingId }
        }
      ]
    }
  )
  const [updateStudentMeetingParentNotification] = useMutation(
    UPDATE_STUDENT_MEETING_PARENT_NOTIFICATION,
    {
      refetchQueries: [
        {
          query: QUERY_STUDENT_MEETING_FORMS,
          variables: { id: studentMeetingId }
        }
      ]
    }
  )

  const [
    insertStudentMeetingParentNotification,
    { loading: insertNotificationLoading }
  ] = useMutation(INSERT_STUDENT_MEETING_PARENT_NOTIFICATION, {
    refetchQueries: [
      {
        query: QUERY_STUDENT_MEETING_FORMS,
        variables: { id: studentMeetingId }
      }
    ]
  })

  const [deleteStudentMeetingParentNotification] = useMutation(
    DELETE_STUDENT_MEETING_PARENT_NOTIFICATION,
    {
      refetchQueries: [
        {
          query: QUERY_STUDENT_MEETING_FORMS,
          variables: { id: studentMeetingId }
        }
      ]
    }
  )

  useEffect(() => {
    if (data && !loading) {
      setStudentFormInfo({
        studentFormId: data?.studentMeetingGeneral.studentFormId,
        studentId: data?.studentMeetingGeneral.studentId,
        schoolId: data?.studentMeetingGeneral.schoolId,
        districtId: data?.studentMeetingGeneral.district.id
      })
      setStudentMeetingId(studentMeetingId)

      if (data?.studentMeetingGeneral.meetingDate) {
        setMeetingDate(data?.studentMeetingGeneral.meetingDate)
      }
    }
  }, [data])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editParentNotificationOpen, setEditParentNotificationOpen] =
    useState(false)
  const [addNotificationOpen, setAddNotificationOpen] = useState(false)
  const [notificationItem, setNotificationItem] = useState({})
  const dateFormat = 'MM/DD/YYYY'

  const editMeeting = () => {
    setIsModalOpen(true)
  }

  const addParentNotification = () => {
    setAddNotificationOpen(true)
  }

  const EditMeeting = () => {
    const [form] = Form.useForm()

    const handleOk = () => {
      let meetingType = form.getFieldValue('meetingType')
      let allowed = true
      if (
        data?.studentMeetingGeneral.meetingType === '504 Plan' &&
        meetingType != '504 Plan'
      ) {
        notificationError(
          '504 meetings are not allowed to be converted to IEP meetings. Please contact your administrator'
        )
        allowed = false
      }

      if (
        data?.studentMeetingGeneral.meetingType != '504 Plan' &&
        meetingType === '504 Plan'
      ) {
        notificationError(
          'IEP meetings are not allowed to be converted to 504 meetings. Please contact your administrator'
        )
        allowed = false
      }
      if (
        data?.studentMeetingGeneral.meetingType === '504 Plan' &&
        form.getFieldValue('isActiveIep') === true
      ) {
        notificationError(
          '504 meetings are not allowed to be a part of the active IEP. Please contact your administrator'
        )
        allowed = false
      }
      if (allowed === true) {
        form.validateFields().then(async () => {
          await updateMeetingInfo({
            variables: {
              id: studentMeetingId,
              schoolId: data?.studentMeetingGeneral.schoolId,
              studentId: data?.studentMeetingGeneral.studentId,
              meetingDate: form.getFieldValue('meetingDate'),
              studentFormIds: data?.studentMeetingGeneral.studentFormIds,
              meetingType: form.getFieldValue('meetingType'),
              isActiveIep: form.getFieldValue('isActiveIep')
            }
          })
          Auth.fetchDesktopData()
        })
        notificationSuccess('Meeting Edited')
      }
      setIsModalOpen(false)
      return
    }
    const handleCancel = () => {
      setIsModalOpen(false)
    }
    return (
      <Modal
        title='Edit Meeting Info'
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        okText='Confirm Edit'
      // mask={false}
      >
        <Form layout='vertical' name='editMeetingForm' form={form}>
          <LookupSelectGeneric
            singleMode={true}
            name='meetingType'
            type='Meeting Type'
            placeholder='Please select a meeting type'
            initialValue={data?.studentMeetingGeneral.meetingType}
          />
          <Form.Item
            name='meetingDate'
            label='Meeting date'
            initialValue={dayjs(data?.studentMeetingGeneral.meetingDate)}
          >
            <DatePicker
              format={[
                'MM-DD-YYYY',
                'MM/DD/YYYY',
                'MM.DD.YY',
                'M-D-YYYY',
                'M/D/YYYY',
                'M.D.YYYY',
                'MM.DD.YYYY',
                'MM-DD-YY',
                'MM/DD/YY',
                'M-D-YY',
                'M/D/YY',
                'M.D.YY',
                'MMDDYYYY',
                'MMDDYY'
              ]}
              placeholder='Select Date'
            />
          </Form.Item>
          <Form.Item name='isActiveIep' label='Active IEP'>
            <Switch defaultChecked={data?.studentMeetingGeneral.isActiveIep} />
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  const EditParentNotification = () => {
    const [form] = Form.useForm()
    const handleOk = () => {
      form.validateFields().then(async () => {
        await updateStudentMeetingParentNotification({
          variables: {
            studentMeetingId: studentMeetingId,
            parentNotification: {
              id: notificationItem.id,
              date: form.getFieldValue('date'),
              parentName: form.getFieldValue('parentName'),
              contactMethod: form.getFieldValue('contactMethod')
            }
          }
        })
      })
      setEditParentNotificationOpen(false)
      notificationSuccess('parent notification edited')
      return
    }
    const handleCancel = () => {
      setEditParentNotificationOpen(false)
    }
    let fixedDate = dayjs(notificationItem.date)
    return (
      <Modal
        title='Edit Parent Notification'
        visible={editParentNotificationOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        okText='Confirm Edit'
      >
        <Form
          name='editNotificationForm'
          form={form}
          initialValues={{
            parentName: notificationItem?.parentName,
            date: fixedDate,
            contactMethod: notificationItem?.contactMethod
          }}
        >
          <Form.Item
            name='parentName'
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select
              style={{
                width: '200px'
              }}
              placeholder={'Select Parent or Guardian'}
            >
              <Option
                value={
                  studentData?.student.parent1FirstName +
                  ' ' +
                  studentData?.student.parent1LastName
                }
              >
                {studentData?.student.parent1FirstName}{' '}
                {studentData?.student.parent1LastName}
              </Option>
              <Option
                value={
                  studentData?.student.parent2FirstName +
                  ' ' +
                  studentData?.student.parent1LastName
                }
              >
                {studentData?.student.parent2FirstName}{' '}
                {studentData?.student.parent2LastName}
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            name='date'
            rules={[{ required: true, message: 'Required' }]}
          >
            <DatePicker
              placeholder='Select Date'
              format={[
                'MM-DD-YYYY',
                'MM/DD/YYYY',
                'MM.DD.YY',
                'M-D-YYYY',
                'M/D/YYYY',
                'M.D.YYYY',
                'MM.DD.YYYY',
                'MM-DD-YY',
                'MM/DD/YY',
                'M-D-YY',
                'M/D/YY',
                'M.D.YY',
                'MMDDYYYY',
                'MMDDYY'
              ]}
            />
          </Form.Item>
          <Form.Item>
            <Input placeholder='Contact Method' />
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  const AddParentNotification = () => {
    const [form] = Form.useForm()
    const handleOk = () => {
      form.validateFields().then(async () => {
        await insertStudentMeetingParentNotification({
          variables: {
            studentMeetingId: studentMeetingId,
            parentNotification: {
              date: form.getFieldValue('date'),
              parentName: form.getFieldValue('parentName'),
              contactMethod: form.getFieldValue('contactMethod')
            }
          }
        })
      })
      setAddNotificationOpen(false)
      notificationSuccess('parent notification added', '')
      return
    }
    const handleCancel = () => {
      setAddNotificationOpen(false)
    }
    return (
      <Modal
        title='Add Parent Notification'
        visible={addNotificationOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        okText='Add Notification'
      >
        <Form name='addParentNotificationForm' form={form}>
          <Form.Item name='parentName'>
            <Select
              style={{
                width: '200px'
              }}
              placeholder={'Select Parent or Guardian'}
            >
              <Option
                value={
                  studentData?.student.parent1FirstName +
                  ' ' +
                  studentData?.student.parent1LastName
                }
              >
                {studentData?.student.parent1FirstName}{' '}
                {studentData?.student.parent1LastName}
              </Option>
              <Option
                value={
                  studentData?.student.parent2FirstName +
                  ' ' +
                  studentData?.student.parent1LastName
                }
              >
                {studentData?.student.parent2FirstName}{' '}
                {studentData?.student.parent2LastName}
              </Option>
            </Select>
          </Form.Item>
          <Form.Item name='date'>
            <DatePicker
              format={[
                'MM-DD-YYYY',
                'MM/DD/YYYY',
                'MM.DD.YY',
                'M-D-YYYY',
                'M/D/YYYY',
                'M.D.YYYY',
                'MM.DD.YYYY',
                'MM-DD-YY',
                'MM/DD/YY',
                'M-D-YY',
                'M/D/YY',
                'M.D.YY',
                'MMDDYYYY',
                'MMDDYY'
              ]}
              placeholder='Select Date'
            />
          </Form.Item>
          <Form.Item name='contactMethod'>
            <Input placeholder='Contact Method' />
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  let studentId = data?.student?.id
  let schoolId = data?.student?.schoolIds[0]
  const { currentStudentInfo, currentEditableForm } = useContext(IepFormContext)
  const { formId, formCode, studentFormId } = currentEditableForm

  const commonFormProps = {
    studentId: studentData?.student?.id,
    schoolId: studentData?.student?.schoolIds[0],
    formId: '123'
  }

  return (
    <div>
      {!loading && data !== undefined && (
        <>
          <Collapse
            defaultActiveKey={['1']}
            style={{ margin: '0px' }}
          // style={{ paddingBottom: '5px', margin: '0px' }}
          // expandIcon={({ isActive }) => (
          //   <>

          //      <CaretRightOutlined
          //         rotate={isActive ? 90 : 0}
          //         style={{ color: 'white',  }}
          //       />

          //   </>
          // )}
          >
            <Panel
              header={
                <>
                  <Text strong={true}>Edit Meeting Info</Text>
                  &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text strong={true}>Student:</Text>{' '}
                  <Text strong={true}>
                    {studentData?.student.firstName}{' '}
                    {studentData?.student.lastName}
                  </Text>
                  &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text strong={true}>Meeting Type:</Text>{' '}
                  <Text strong={true}>
                    {data?.studentMeetingGeneral.meetingType}
                  </Text>
                  &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text strong={true}>Meeting Date:</Text>{' '}
                  <Text strong={true}>
                    {dayjs(data?.studentMeetingGeneral.meetingDate).format(
                      'MM/DD/YYYY'
                    )}
                  </Text>
                </>
              }
              key='75'
            >
              {!loading && !updateMeetingLoading && data != undefined ? (
                <>
                  {' '}
                  <AddParentNotification />
                  <EditMeeting />
                  <Card title='Meeting Info'>
                    <Row style={{ marginLeft: '100px' }}>
                      <Col span={6}>
                        <div style={{ fontWeight: 'bold' }}>
                          Meeting Date:{' '}
                          {dayjs(
                            data.studentMeetingGeneral.meetingDate
                          ).format('MM/DD/YYYY')}
                        </div>
                      </Col>
                      <Col span={6}>
                        <div style={{ fontWeight: 'bold' }}>
                          Meeting Type:{' '}
                          {data?.studentMeetingGeneral.meetingType}
                        </div>{' '}
                      </Col>
                      {/* need to have a convo about this, don't know that we ever reached a conclusion */}
                      {/* <Col span={6}>
                <div style={{ fontWeight: 'bold' }}>Parent Meeting:</div>{' '}
                {data.studentMeetingGeneral.iepMeetingId} //need to do a query
                here, for a lot of them it's null rn
              </Col> */}
                      <Col span={6}>
                        <div style={{ fontWeight: 'bold' }}>
                          Status:{' '}
                          {data.studentMeetingGeneral.isActiveIep === true ? (
                            <>Part of Active IEP</>
                          ) : (
                            <>Not Part of Active IEP</>
                          )}
                        </div>{' '}
                      </Col>
                      <Col span={6}>
                        <Button
                          style={{
                            height: 'auto'
                          }}
                          size='small'
                          onClick={editMeeting}
                        >
                          Edit Meeting Info
                          <SettingOutlined />
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </>
              ) : (
                <>loading</>
              )}

              {!loading && data != undefined ? (
                <>
                  <Card title='Parent Notifications'>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Row>
                        <Col span={24}>
                          <Button
                            style={{
                              height: 'auto'
                            }}
                            size='small'
                            onClick={addParentNotification}
                          >
                            Add Parent Notification
                            <PlusSquareOutlined />
                          </Button>
                        </Col>
                      </Row>
                    </div>

                    <Row>
                      {data?.studentMeetingGeneral.parentNotifications.map(
                        (x, index) => {
                          return (
                            <>
                              {editParentNotificationOpen && (
                                <EditParentNotification />
                              )}
                              <Col span={8}>
                                <Row
                                  style={{
                                    padding: '10px',
                                    marginLeft: '70px'
                                  }}
                                >
                                  <Col span={24}>
                                    <div style={{ fontWeight: 'bold' }}>
                                      Parent Notification:
                                    </div>{' '}
                                    {index + 1}
                                  </Col>
                                  <Col span={24}>
                                    <div style={{ fontWeight: 'bold' }}>
                                      Parent Name:
                                    </div>{' '}
                                    {x?.parentName}
                                  </Col>
                                  <Col span={24}>
                                    <div style={{ fontWeight: 'bold' }}>
                                      Contact Method:
                                    </div>{' '}
                                    {x?.contactMethod}
                                  </Col>
                                  <Col span={24}>
                                    <div style={{ fontWeight: 'bold' }}>
                                      Contact Date:
                                    </div>{' '}
                                    {dayjs(x?.date).format('MM/DD/YYYY')}
                                  </Col>
                                  <Row>
                                    <Col span={12}>
                                      <Button
                                        style={{
                                          height: 'auto'
                                        }}
                                        size='small'
                                        onClick={() => {
                                          setNotificationItem(x)
                                          setEditParentNotificationOpen(true)
                                        }}
                                      >
                                        Edit
                                        <SettingOutlined />
                                      </Button>
                                    </Col>
                                    <Col span={12}>
                                      <Popconfirm
                                        title='Confirm Notification Removal'
                                        onConfirm={async () => {
                                          await deleteStudentMeetingParentNotification(
                                            {
                                              variables: {
                                                studentMeetingId:
                                                  studentMeetingId,
                                                parentNotification: {
                                                  id: notificationItem.id
                                                }
                                              }
                                            }
                                          )
                                          notificationSuccess(
                                            'Notification Removed'
                                          )
                                        }}
                                        okText='Yes'
                                        cancelText='No'
                                      >
                                        <Button
                                          style={{
                                            height: 'auto'
                                          }}
                                          size='small'
                                          onClick={() => setNotificationItem(x)}
                                        >
                                          Delete
                                          <DeleteOutlined />
                                        </Button>
                                      </Popconfirm>
                                    </Col>
                                  </Row>
                                </Row>
                              </Col>
                            </>
                          )
                        }
                      )}
                    </Row>
                  </Card>
                  <MeetingSISProvider>
                    <MeetingSISForm {...commonFormProps} {...studentData} />
                  </MeetingSISProvider>
                </>
              ) : (
                <>loading...</>
              )}
            </Panel>
          </Collapse>
        </>
      )}
    </div>
  )
}

EditMeetingInfo.propTypes = {
  setEditMode: PropTypes.func
}

export default EditMeetingInfo
