import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useQuery } from '@apollo/client'

// ant design
import { Form, Input, DatePicker, Radio, Alert, Typography, Card } from 'antd'

import FormContainer from '../../../FormContainer'
import FormItem from 'antd/lib/form/FormItem'
import Title from 'antd/lib/typography/Title'

import { PRINT_FORM3457E } from '../../../../operations/mutations/forms/3457E'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3457E_VERSION } from '../../../../operations/queries/forms/3457E'

import Editor from '../../../elements/common/Editor'
import FormCollaboration from '../../../FormCollaboration'

import { IepFormContext } from '../../../../utils/iepFormProvider'
import StudentFormActions from '../StudentFormActions'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'meetingDateAndTime':
        update[fieldName] = dayjs(fieldValue)
        break
      case 'parentSignatureDate':
        update[fieldName] = dayjs(fieldValue)
        break
      case 'formDate':
        update[fieldName] = dayjs(fieldValue)
        break
      case 'eligibility':
      case 'requiresSpecialEducationChange':
      case 'requiresSeparateEducationalSetting':
      case 'willReceiveSpecialEducation':
      case 'willReceiveFunctionalBehavioral':
      case 'willReceiveGoalsTransitions':
      case 'graduationRecommended':
      case 'other':
        console.log(fieldName, fieldValue)
        update[fieldName] =
          fieldValue === 'false' ? false : fieldValue === 'true'
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const radioFields = [
    {
      label: 'Eligibility',
      name: 'eligibility'
    },
    {
      label: 'Will receive the special education listed in the IEP',
      name: 'willReceiveSpecialEducation'
    },
    {
      label:
        'Requires and will receive the post-secondary goals and transition services',
      name: 'willReceiveGoalsTransitions'
    },
    {
      label: 'Requires and will receive a functional behavioral assessment',
      name: 'willReceiveFunctionalBehavioral'
    }
  ]

  const radioFields2 = [
    {
      label: 'Is recommended for graduation',
      name: 'graduationRecommended'
    },
    {
      label: 'Other',
      name: 'other'
    }
  ]

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457E'
            name='studentForm3457E'
            initialValues={data}
            layout='vertical'
            preserve
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              PARENT/GUARDIAN NOTIFICATION OF CONFERENCE RECOMMENDATIONS
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>
            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />

              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentParent1SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1}
                error={itemErrors.parent1}
              />
              <StudentParent2SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2}
                error={itemErrors.parent2}
              />
            </Card>

            <Card
              title='Conference Information'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Meeting Date and Time'
                name='meetingDateAndTime'
                validateStatus={itemErrors.meetingDateAndTime ? 'error' : ''}
                help={itemErrors.meetingDateAndTime}
                {...validationProps('meetingDateAndTime')}
              >
                <DatePicker
                  disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 20, 21, 22, 23]}
                  showTime={{
                    hideDisabledOptions: true
                  }}
                  use12Hours
                  minuteStep={5}
                  format='YYYY-MM-DD / hh:mm A'
                  {...handleFocusEvents}
                  disabled={formDisabledState.meetingDateAndTime}
                />
              </FormItem>
              <FormItem
                label='Meeting Location'
                name='meetingLocation'
                validateStatus={itemErrors.meetingLocation ? 'error' : ''}
                help={itemErrors.meetingLocation}
                {...validationProps('meetingLocation')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.meetingLocation}
                />
              </FormItem>
            </Card>

            <Card
              title='Details'
              style={{
                marginBottom: 30
              }}
            >
              {radioFields.map(({ name, label }, index) => (
                <div key={index}>
                  <FormItem
                    label={label}
                    name={name}
                    validateStatus={itemErrors[name] ? 'error' : ''}
                    help={itemErrors[name]}
                    {...validationProps(name)}
                  >
                    <Radio.Group
                      defaultValue
                      buttonStyle='solid'
                      {...handleMouseEvents}
                      disabled={formDisabledState[name]}
                      name={name}
                    >
                      <Radio.Button value>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </FormItem>
                  {name === 'eligibility' && (
                    <FormItem
                      label='Eligibility Category'
                      name='eligibilityCategory'
                      validateStatus={itemErrors.meetingLocation ? 'error' : ''}
                      help={itemErrors.meetingLocation}
                      {...validationProps('eligibilityCategory')}
                    >
                      <Input
                        {...handleFocusEvents}
                        disabled={formDisabledState.eligibilityCategory}
                      />
                    </FormItem>
                  )}
                  {name === 'eligibility' && (
                    <FormItem
                      label='Requires a change of special education and/or related services/educational placement'
                      name='requiresSpecialEducationChange'
                      validateStatus={
                        itemErrors.requiresSpecialEducationChange ? 'error' : ''
                      }
                      help={itemErrors.requiresSpecialEducationChange}
                      {...validationProps('requiresSpecialEducationChange')}
                    >
                      <Radio.Group
                        defaultValue
                        buttonStyle='solid'
                        {...handleMouseEvents}
                        disabled={
                          formDisabledState.requiresSpecialEducationChange
                        }
                        name='requiresSpecialEducationChange'
                      >
                        <Radio.Button value>Yes</Radio.Button>
                        <Radio.Button value={false}>No</Radio.Button>
                      </Radio.Group>
                    </FormItem>
                  )}
                  {name === 'eligibility' && (
                    <FormItem
                      label='Requires a placement in a separate educational setting'
                      name='requiresSeparateEducationalSetting'
                      validateStatus={
                        itemErrors.requiresSeparateEducationalSetting
                          ? 'error'
                          : ''
                      }
                      help={itemErrors.requiresSeparateEducationalSetting}
                      {...validationProps('requiresSeparateEducationalSetting')}
                    >
                      <Radio.Group
                        defaultValue
                        buttonStyle='solid'
                        {...handleMouseEvents}
                        disabled={
                          formDisabledState.requiresSeparateEducationalSetting
                        }
                        name='requiresSeparateEducationalSetting'
                      >
                        <Radio.Button value>Yes</Radio.Button>
                        <Radio.Button value={false}>No</Radio.Button>
                      </Radio.Group>
                    </FormItem>
                  )}
                </div>
              ))}
              <FormItem
                label='Disciplinary code violation(s) was related to your child’s disability'
                name='disciplinaryCodeViolation'
                {...validationProps('disciplinaryCodeViolation')}
              >
                <Radio.Group
                  defaultValue
                  defaultChecked='n/a'
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  disabled={formDisabledState.disciplinaryCodeViolation}
                  name='disciplinaryCodeViolation'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                  <Radio.Button value='n/a'>N/A</Radio.Button>
                </Radio.Group>
              </FormItem>
              {radioFields2.map(({ name, label }, index) => (
                <div key={index}>
                  <FormItem
                    label={label}
                    name={name}
                    validateStatus={itemErrors[name] ? 'error' : ''}
                    help={itemErrors[name]}
                    {...validationProps(name)}
                  >
                    <Radio.Group
                      defaultValue
                      buttonStyle='solid'
                      {...handleMouseEvents}
                      disabled={formDisabledState[name]}
                      name={name}
                    >
                      <Radio.Button value>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </FormItem>
                  {name === 'other' && (
                    <Form.Item
                      label='Other Description'
                      name='otherDescription'
                      {...validationProps('otherDescription')}
                    >
                      <Editor
                        {...handleEditorEvents}
                        formField='otherDescription'
                        disabled={formDisabledState.otherDescription}
                      />
                    </Form.Item>
                  )}
                </div>
              ))}
              <FormItem
                label='Agree to waive the requirement of a ten school days interval'
                name='waiveTenDaysRequirement'
                {...validationProps('waiveTenDaysRequirement')}
              >
                <Radio.Group
                  defaultValue
                  defaultChecked='n/a'
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  disabled={formDisabledState.waiveTenDaysRequirement}
                  name='waiveTenDaysRequirement'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                  <Radio.Button value='n/a'>N/A</Radio.Button>
                </Radio.Group>
              </FormItem>
              <Form.Item
                label='Parent / Guardian signature date'
                name='parentSignatureDate'
                {...validationProps('parentSignatureDate', formDisabledState)}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.parentSignatureDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>
            </Card>

            <Card
              title='Contact Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Name'
                name='documentContactName'
                {...validationProps('documentContactName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactName}
                />
              </Form.Item>
              <Form.Item
                label='Title'
                name='documentContactTitle'
                {...validationProps('documentContactTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactTitle}
                />
              </Form.Item>
              <Form.Item
                label='Phone'
                name='documentContactPhone'
                {...validationProps('documentContactPhone')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactPhone}
                />
              </Form.Item>
            </Card>
            <Card
              title='Signee Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Name'
                name='signatureName'
                {...validationProps('signatureName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.signatureName}
                />
              </Form.Item>
              <Form.Item
                label='Title'
                name='signatureTitle'
                {...validationProps('signatureTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.signatureTitle}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3457E}
              extractDataProp='printStudentForm3457E'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3457E = () => {
  // get current form information
  const {
    currentStudentInfo,
    currentEditableForm,
    studentMeetingId,
    meetingDate
  } = useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3457E_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    console.log(versionInfo)
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3457E: form } = versionInfo
    const { student } = studentInfo
    const { parent1FullName, parent2FullName, dateOfBirth } = student
    const {
      formDate,
      studentFullName,
      studentDob,
      parent1,
      parent2,
      parentSignatureDate,
      meetingDateAndTime
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? dayjs(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
          ? dayjs(dateOfBirth).format('YYYY-MM-DD')
          : '',
      meetingDateAndTime: meetingDateAndTime
        ? dayjs(meetingDateAndTime)
        : dayjs(meetingDate),
      parent1: parent1 || parent1FullName || '',
      parent2: parent2 || parent2FullName || '',
      parentSignatureDate:
        parentSignatureDate !== undefined
          ? dayjs(parentSignatureDate)
          : dayjs(Date.now())
    }

    return initialValues
  }

  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}

export default StudentForm3457E
