import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORMTAMES6_VERSION } from '../../../../operations/queries/forms/tames6'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Input,
  Form,
  Radio,
  Typography
} from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORMTAMES6 } from '../../../../operations/mutations/forms/tames6'
import { PlusOutlined } from '@ant-design/icons'

const { Paragraph, Text } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    const otherParticipants = formFieldValues.otherParticipants.map(
      ({ __typename, ...props }) => props
    )

    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      otherParticipants: otherParticipants,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      case 'studentAttendedConference':
      case 'parentAttended':
      case 'leaAttended':
      case 'specedAttended':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      case 'otherParticipants':
        update[fieldName] = JSON.parse(fieldValue)
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentFormTames6'
            name='studentFormTames6'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              MEETING PARTICIPANTS
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
            </Card>

            <Card
              title='Details of Main Participants'
              style={{
                marginBottom: 30
              }}
            >
              <p>
                <strong>
                  Enter names and titles of all meeting participants present at
                  the conference.
                </strong>
              </p>
              <Card
                title='Student Information'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Attended Eligibility Conference'
                  name='studentAttendedConference'
                  {...validationProps('studentAttendedConference')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.studentAttendedConference}
                    name='studentAttendedConference'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>

              <Card
                title='Parent/Guardian Information'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Parent/Guardian Name'
                  name='parentName'
                  {...validationProps('parentName')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.parentName}
                  />
                </Form.Item>
                <Form.Item
                  label='Attended Eligibility Conference'
                  name='parentAttended'
                  {...validationProps('parentAttended')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.parentAttended}
                    name='parentAttended'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>

              <Card
                title='LEA Information'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='LEA Name'
                  name='leaName'
                  {...validationProps('leaName')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.leaName}
                  />
                </Form.Item>
                <Form.Item
                  label='Attended Eligibility Conference'
                  name='leaAttended'
                  {...validationProps('leaAttended')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.leaAttended}
                    name='leaAttended'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>

              <Card
                title='Special Education Information'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Special Education Name'
                  name='specedName'
                  {...validationProps('specedName')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.specedName}
                  />
                </Form.Item>
                <Form.Item
                  label='Attended Eligibility Conference'
                  name='specedAttended'
                  {...validationProps('specedAttended')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.specedAttended}
                    name='specedAttended'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>
            </Card>

            <Card
              title='Other Participant(s) Information'
              style={{
                marginBottom: '30px'
              }}
            >
              <Input.Group
                onFocus={() => {
                  handleEditorEvents.onFocus('otherParticipants')
                }}
                onBlur={() => {
                  handleEditorEvents.onBlur(
                    'otherParticipants',
                    JSON.stringify(form.getFieldValue('otherParticipants'))
                  )
                }}
              >
                <Form.List
                  name='otherParticipants'
                  {...validationProps('otherParticipants')}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <Card
                            style={{
                              marginBottom: '30px',
                              borderStyle: 'dashed'
                            }}
                          >
                            <Form.Item
                              {...field}
                              label='Participant Name'
                              name={[field.name, 'otherParticipantName']}
                              key={[field.fieldKey, 'otherParticipantName']}
                            >
                              <Input
                                disabled={formDisabledState.otherParticipants}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label='Title'
                              name={[field.name, 'otherParticipantTitle']}
                              key={[field.fieldKey, 'otherParticipantTitle']}
                            >
                              <Input
                                disabled={formDisabledState.otherParticipants}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label='Attended Eligibility Conference'
                              name={[field.name, 'otherParticipantAttended']}
                              key={[field.fieldKey, 'otherParticipantAttended']}
                            >
                              <Radio.Group
                                buttonStyle='solid'
                                disabled={formDisabledState.otherParticipants}
                                onChange={() => {
                                  handleEditorEvents.onBlur(
                                    'otherParticipants',
                                    JSON.stringify(
                                      form.getFieldValue('otherParticipants')
                                    )
                                  )
                                }}
                              >
                                <Radio.Button value='yes'>Yes</Radio.Button>
                                <Radio.Button value='no'>No</Radio.Button>
                              </Radio.Group>
                            </Form.Item>

                            <Button
                              danger
                              disabled={formDisabledState.otherParticipants}
                              onClick={() => {
                                remove(field.name)
                                handleEditorEvents.onBlur(
                                  'otherParticipants',
                                  JSON.stringify(
                                    form.getFieldValue('otherParticipants')
                                  )
                                )
                              }}
                            >
                              Remove Other Participant
                            </Button>
                          </Card>
                        </div>
                      ))}

                      <Form.Item>
                        {
                          // Default value below prevents Quill from breaking the render
                        }
                        <Button
                          disabled={formDisabledState.otherParticipants}
                          type='dashed'
                          onClick={() =>
                            add({
                              otherParticipantName: '',
                              otherParticipantTitle: '',
                              otherParticipantAttended: 'no'
                            })
                          }
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Other Participant
                        </Button>
                      </Form.Item>
                      <Text type='warning'>
                        {validationProps('otherParticipants').help}
                      </Text>
                    </>
                  )}
                </Form.List>
              </Input.Group>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORMTAMES6}
              extractDataProp='printStudentFormTames6'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired
}

const StudentFormTames6 = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORMTAMES6_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentFormTames6: form } = versionInfo
    const { student } = studentInfo
    const { dateOfBirth } = student
    const { formDate, studentFullName, studentDob } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? dayjs(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
          ? dayjs(dateOfBirth).format('YYYY-MM-DD')
          : dayjs(Date.now()).format('YYYY-MM-DD')
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentFormTames6
