import React, { useContext } from 'react'
import Page from '../../components/Page'
import LoginForm from '../../components/sections/login/LoginForm'
import { Typography, Layout, Row, Col, Card, Image } from 'antd'
import Home from './Home'
import { Link } from 'react-router-dom'
// contexts
import AppContext from '../../utils/appProvider'
import './HomeStyles.css'
import itames from './iTAMES Logo.png'
import generic from './TAMES Simple Logo.png'
import tamesassist from '../../assets/images/tames_assist_logo.png'
const Landing = () => {

    const app = useContext(AppContext)
    const { Paragraph, Text } = Typography;
    const { Meta } = Card;
    const { Header, Footer, Sider, Content } = Layout;

    return (
        <Layout className='content-layout' style={{ minHeight: "100vh" }}>
            <Header style={{
                position: 'absolute',
                top: 0,
                zIndex: 1,
                width: '100%',
                display: 'flex',
                // alignItems: 'center',
            }} >

                <Image src={generic} preview={false} style={{ width: '10rem' }} />



                <Typography.Title style={{ color: 'white', margin: '0px', paddingTop: '1rem' }}>
                    Health Resource Services
                </Typography.Title>
            </Header>
            <Content style={{ paddingTop: '5rem', paddingBottom: '-10rem' }}>
                <Row justify={'center'} align={'middle'} >
                    <Col span={12} style={{ padding: '5rem', }} className="fade-in" >
                        <Card
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flex: 1,
                                height: '30rem'
                            }}
                        >
                            <Row>
                                <Col
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flex: 1,
                                    }}
                                >
                                    <Image src={itames} preview={false} />
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flex: 1,
                                    }}
                                >

                                    <Link to="https://www.itames.com/" component={Typography.Text} style={{ color: 'black', fontSize: '2.5em' }}>
                                        Sign In
                                    </Link>
                                </Col>
                                <Col
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flex: 1,
                                    }}
                                >

                                    <Link to="https://www.itames.com/" component={Typography.Text} style={{ color: 'black', fontSize: '2.5em' }}>
                                        More Info
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flex: 1,
                                }}>
                                    <Typography.Paragraph style={{ fontSize: '2em' }}>
                                        For medicaid billing, scheduling services, and more.
                                    </Typography.Paragraph>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={12} style={{ padding: '5rem' }} className="fade-in">
                        <Card
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flex: 1,
                                height: '30rem'
                            }}
                        >
                            <Row>
                                <Col
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flex: 1,
                                    }}
                                >
                                    <Image src={tamesassist} preview={false} />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flex: 1,
                                }}>

                                    <Link to="/signin" component={Typography.Text} style={{ color: 'black', fontSize: '2.5em' }}>
                                        Sign In
                                    </Link>
                                </Col>
                                <Col style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flex: 1,
                                }}
                                >

                                    <Link to="/" component={Typography.Text} style={{ color: 'black', fontSize: '2.5em' }}>
                                        More Info
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flex: 1,

                                    }}
                                >
                                    <Typography.Paragraph style={{ fontSize: '2em' }}>
                                        For IEPs, 504s, Health Plans, and more.
                                    </Typography.Paragraph>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Content>
            <Footer style={{ backgroundColor: '#001529' }}>
                <Row style={{ padding: '0px', margin: '0px' }}>
                    <Col
                        style={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            flex: 1,
                        }}
                        span={5}
                    >
                        <Typography.Title copyable style={{ color: 'white', fontSize: '2rem' }}>
                            info@tameshrs.com
                        </Typography.Title>
                    </Col>
                    <Col
                        style={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            flex: 1,
                        }}
                        span={6}

                    >
                        <Typography.Title h1 copyable style={{ color: 'white', fontSize: '2rem' }}>
                            800-848-0157
                        </Typography.Title>
                    </Col>
                </Row>

            </Footer>

        </Layout>
    )
}

export default Landing
