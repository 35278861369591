import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_NAME } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454K_VERSION } from '../../../../operations/queries/forms/3454K'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Radio, Typography } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454K } from '../../../../operations/mutations/forms/3454K'
import Editor from '../../../elements/common/Editor'
import FormItem from 'antd/lib/form/FormItem'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const formEditorFields = [
    {
      name: 'targetBehaviour',
      label: 'Target Behavior',
      description: (
        <div>
          <strong>Skill Deficit:</strong> The student does not know how to
          perform the desired behavior.
          <br />
          <strong>Performance Deficit:</strong> The student knows how to perform
          the desired behavior, but does not consistently do so.
        </div>
      )
    },
    {
      name: 'studentStrengths',
      label: 'Student’s Strengths',
      description: 'Describe student’s behavioral strengths.'
    },
    {
      name: 'behavioralFunction',
      label: 'Hypothesis of Behavioral Function',
      description:
        'Include hypothesis developed through the Functional Behavioral Assessment (attach completed form). What desired thing(s) is the student trying to get? OR What undesired thing(s) is the student trying to avoid?'
    },
    {
      name: 'previousInterventionsAttempted',
      label: 'Summary of Previous Interventions Attempted',
      description:
        'Describe any environmental changes made, evaluations conducted, instructional strategy or curriculum changes made or replacement behaviors taught.'
    },
    {
      name: 'replacementBehaviour',
      label: 'Replacement Behaviors',
      description:
        'Describe which new behaviors or skills will be taught to meet the identified function of the target behavior (e.g. student will slap his desk to replace striking out at others). Include description of how these behaviors/skills will be taught.'
    }
  ]

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454K'
            name='studentForm3454K'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              BEHAVIORAL INTERVENTION PLAN (AS APPROPRIATE)
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>

            <Card
              title='Details'
              style={{
                marginBottom: 30
              }}
            >
              {formEditorFields.map(({ name, label, description }, index) => (
                <div key={index}>
                  <Form.Item
                    label={label}
                    name={name}
                    {...validationProps(name)}
                    style={description ? { marginBottom: '0' } : {}}
                  >
                    <Editor
                      {...handleEditorEvents}
                      formField={name}
                      disabled={formDisabledState[name]}
                    />
                  </Form.Item>
                  {name === 'targetBehaviour' && (
                    <FormItem
                      label='Type of behavior'
                      name='behaviourType'
                      {...validationProps('behaviourType')}
                      style={{ marginBottom: '0' }}
                    >
                      <Radio.Group
                        {...handleMouseEvents}
                        buttonStyle='solid'
                        disabled={formDisabledState.behaviourType}
                        name='behaviourType'
                      >
                        <Radio.Button value='skill-deficit'>
                          Skill Deficit
                        </Radio.Button>
                        <Radio.Button value='performative-deficit'>
                          Performative Deficit
                        </Radio.Button>
                      </Radio.Group>
                    </FormItem>
                  )}
                  {!!description && (
                    <Alert
                      style={{ marginBottom: '24px' }}
                      message={description}
                      type='info'
                    />
                  )}
                </div>
              ))}
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454K}
              extractDataProp='printStudentForm3454K'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454K = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_NAME,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454K_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454K: form } = versionInfo
    const { student } = studentInfo
    const { formDate, studentFullName } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454K
