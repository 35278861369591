import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery, useLazyQuery } from '@apollo/client'
import { QUERY_STUDENT_NAME } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454T_VERSION } from '../../../../operations/queries/forms/3454T'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Menu,
  Radio,
  Typography,
  Select
} from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454T } from '../../../../operations/mutations/forms/3454T'
import Editor from '../../../elements/common/Editor'
import FormItem from 'antd/lib/form/FormItem'
import { PlusOutlined } from '@ant-design/icons'
import { QUERY_STUDENT } from '../../../../operations/queries/students'

const { Paragraph, Text } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { Option } = Select
  const { itemErrors, formError } = useContext(FormErrorContext)
  let allGoals = []
  const [getStudentData, { data: studentData, loadingStudent }] = useLazyQuery(
    QUERY_STUDENT,
    {
      variables: { id: studentId, includeGoals: true },
      skip: !studentId
    }
  )

  useEffect(() => {
    getStudentData()
  }, [])
  studentData?.student.currentGoals.map(x => {
    allGoals.push(x)
  })
  studentData?.student.priorGoals.map(x => {
    allGoals.push(x)
  })

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    const annualGoals = formFieldValues.annualGoals.map(
      ({ __typename, ...props }) => props
    )
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId,
      annualGoals: annualGoals
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      case 'parentReport':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      case 'reportCard':
      case 'progressReport':
        update[fieldName] = parseInt(fieldValue)
        break
      case 'annualGoals':
        update[fieldName] = JSON.parse(fieldValue)
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }
  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454T'
            name='studentForm3454T'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              REPORT OF PROGRESS ON ANNUAL GOALS (OPTION 1)
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>
            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>

            <Card
              title='Staff Information'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Name'
                name='staffName'
                validateStatus={itemErrors.staffName ? 'error' : ''}
                help={itemErrors.staffName}
                {...validationProps('staffName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.staffName}
                />
              </FormItem>
              <FormItem
                label='Title'
                name='title'
                validateStatus={itemErrors.title ? 'error' : ''}
                help={itemErrors.title}
                {...validationProps('title')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.title}
                />
              </FormItem>
            </Card>

            <Card
              title='Report Information'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Type of Report'
                name='typeOfReport'
                validateStatus={itemErrors.staffName ? 'typeOfReport' : ''}
                help={itemErrors.typeOfReport}
                {...validationProps('typeOfReport')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.typeOfReport}
                />
              </FormItem>
              <FormItem
                label='Report Card Quarter'
                name='reportCard'
                validateStatus={itemErrors.reportCard ? 'error' : ''}
                help={itemErrors.reportCard}
                {...validationProps('reportCard')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.reportCard}
                  name='reportCard'
                >
                  <Radio value={1}>1</Radio>
                  <Radio value={2}>2</Radio>
                  <Radio value={3}>3</Radio>
                  <Radio value={4}>4</Radio>
                </Radio.Group>
              </FormItem>
              <FormItem
                label='Progress Report Quarter'
                name='progressReport'
                validateStatus={itemErrors.progressReport ? 'error' : ''}
                help={itemErrors.progressReport}
                {...validationProps('progressReport')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.progressReport}
                  name='progressReport'
                >
                  <Radio value={1}>1</Radio>
                  <Radio value={2}>2</Radio>
                  <Radio value={3}>3</Radio>
                  <Radio value={4}>4</Radio>
                </Radio.Group>
              </FormItem>
              <FormItem
                label='Parent Conference'
                name='parentReport'
                validateStatus={itemErrors.parentReport ? 'error' : ''}
                help={itemErrors.parentReport}
                {...validationProps('parentReport')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.parentReport}
                  name='parentReport'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </FormItem>
            </Card>

            <Card
              title='Annual Goals'
              style={{
                marginBottom: 30
              }}
            >
              <Input.Group
                onFocus={() => {
                  handleEditorEvents.onFocus('annualGoals')
                }}
                onBlur={() => {
                  handleEditorEvents.onBlur(
                    'annualGoals',
                    JSON.stringify(form.getFieldValue('annualGoals'))
                  )
                }}
              >
                <Form.List
                  name='annualGoals'
                  {...validationProps('annualGoals')}
                >
                  {(fields, { add, remove }) => (
                    <>
                      <Card
                        style={{ marginBottom: '30px', borderStyle: 'dashed' }}
                      >
                        {/* not sure why this exists, leaving it commented out for now */}
                        {/* <Menu>
                          {allGoals?.map(x => (
                            <>
                              <Menu.Item>
                                {x?.submittedData.goalStatement === '' ||
                                        /[a-zA-Z]/.test(x?.submittedData.goalStatement) !=
                                          true ? (
                                          <>
                                            Goal does not have a goal statement
                                          </>
                                        ) : (
                                          <>
                                            {x?.submittedData.goalStatement.length > 50 ? (
                                              <>
                                                {' '}
                                                {x?.submittedData.goalStatement.slice(0, 50)}...
                                              </>
                                            ) : (
                                              <> {x?.submittedData.goalStatement}</>
                                            )}
                                          </>
                                        )}
                              </Menu.Item>
                            </>
                          ))}
                        </Menu> */}
                        {fields.map((field, index) => (
                          <>
                            <div key={field.key}>
                              <Form.Item
                                {...field}
                                label='Goal'
                                name={[field.name, 'goalId']}
                                key={[field.fieldKey, 'goalId']}
                              >
                                <Select placeholder='Select a Goal' allowClear>
                                  {allGoals.map(x => {
                                    let goalStatement =
                                      x.submittedData.goalStatement.replace(
                                        /<[^>]*>/g,
                                        ' '
                                      )
                                    return (
                                      <Option
                                        value={x.id}
                                        label={x.submittedData.goalStatement}
                                      >
                                        {goalStatement === '' ||
                                          /[a-zA-Z]/.test(goalStatement) !=
                                          true ? (
                                          <>
                                            Goal does not have a goal statement
                                          </>
                                        ) : (
                                          <>
                                            {goalStatement.length > 50 ? (
                                              <>
                                                {' '}
                                                {goalStatement.slice(0, 50)}...
                                              </>
                                            ) : (
                                              <> {goalStatement}</>
                                            )}
                                          </>
                                        )}
                                      </Option>
                                    )
                                  })}
                                </Select>

                                {/* <Input disabled={formDisabledState.annualGoals} /> */}
                              </Form.Item>
                              <Form.Item
                                {...field}
                                label='Goal Number'
                                name={[field.name, 'goalNumber']}
                                key={[field.fieldKey, 'goalNumber']}
                                rules={[
                                  {
                                    pattern: /^[1-9]\d*$/,
                                    message: 'Must be a positive number'
                                  }
                                ]}
                              >
                                <Input
                                  type='number'
                                  min='1'
                                  disabled={formDisabledState.annualGoals}
                                />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                label='Measurable Annual Goal'
                                name={[field.name, 'measurableAnnualGoal']}
                                key={[field.fieldKey, 'measurableAnnualGoal']}
                              >
                                <Input
                                  disabled={formDisabledState.annualGoals}
                                />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                label='Completed'
                                name={[field.name, 'completed']}
                                key={[field.fieldKey, 'completed']}
                              >
                                <Input
                                  disabled={formDisabledState.annualGoals}
                                />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                label='Making Expected Progress'
                                name={[field.name, 'makingExpectedProgress']}
                                key={[field.fieldKey, 'makingExpectedProgress']}
                              >
                                <Input
                                  disabled={formDisabledState.annualGoals}
                                />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                label='Not Making Expected Progress'
                                name={[field.name, 'notMakingExpectedProgress']}
                                key={[
                                  field.fieldKey,
                                  'notMakingExpectedProgress'
                                ]}
                              >
                                <Input
                                  disabled={formDisabledState.annualGoals}
                                />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                label='Additional Comments'
                                name={[field.name, 'additionalComments']}
                                key={[field.fieldKey, 'additionalComments']}
                              >
                                <Editor
                                  onFocus={() => { }}
                                  onBlur={() => { }}
                                  disabled={formDisabledState.annualGoals}
                                />
                              </Form.Item>

                              <Button
                                danger
                                disabled={formDisabledState.annualGoals}
                                onClick={() => {
                                  remove(field.name)
                                  handleEditorEvents.onBlur(
                                    'annualGoals',
                                    JSON.stringify(
                                      form.getFieldValue('annualGoals')
                                    )
                                  )
                                }}
                              >
                                Remove Goal
                              </Button>
                            </div>
                          </>
                        ))}
                      </Card>
                      <Form.Item>
                        {
                          // Default value below prevents Quill from breaking the render
                        }
                        <Button
                          disabled={formDisabledState.annualGoals}
                          type='dashed'
                          onClick={() =>
                            add({
                              goalNumber: '',
                              measurableAnnualGoal: '',
                              completed: '',
                              makingExpectedProgress: '',
                              notMakingExpectedProgress: '',
                              additionalComments:
                                'Please type your comments here....'
                            })
                          }
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Goal
                        </Button>
                      </Form.Item>
                      <Text type='warning'>
                        {validationProps('annualGoals').help}
                      </Text>
                    </>
                  )}
                </Form.List>
              </Input.Group>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454T}
              extractDataProp='printStudentForm3454T'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454T = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm
  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_NAME,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454T_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )
  console.log('StudenForm3454T-student/form data: ', studentData, formData)

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454T: form } = versionInfo
    const { student } = studentInfo
    const { formDate, studentFullName } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454T
