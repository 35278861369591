import { useContext, useState } from 'react'
import { Collapse, Row, Col, Button, Affix } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import AuthContext from '../../utils/authProvider'
import dayjs from 'dayjs'
import HistoryHelper from './HistoryHelper'
const FileHistory = ({ studentData }) => {
  const Auth = useContext(AuthContext)
  const desktopData = Auth?.desktopData
  const { Panel } = Collapse
  let studentMatch = {}

  if (desktopData === null || undefined) {
    Auth?.fetchDesktopData()
  }
  if (desktopData != null || undefined) {
    desktopData?.map(x => {
      if (x?.id === studentData?.studentId) {
        return (studentMatch = x)
      }
    })
  }
  const [activePanel, setActivePanel] = useState('')
  const offset = Math.floor(window.innerHeight * 0.25 * -1)
  const eleWid = document?.getElementById('potato')?.offsetWidth
  console.log(eleWid, 'what are you thanks')
  return (
    <>
      {studentMatch?.studentMeetingsGeneral != undefined ? (
        <>
          {studentMatch?.studentMeetingsGeneral.map(x => {
            const changeActivePanel = () => {
              let id = x.id
              if (activePanel === `${id}`) {
                setActivePanel('')
              } else {
                setActivePanel(`${id}`)
              }
            }
            return (
              <>
                {x !== undefined && (
                  <>
                    <Row>
                      <Col span={24}>
                        <div id='potato'>
                          <Collapse
                            activeKey={activePanel}
                            onChange={changeActivePanel}
                            style={{ overflow: 'hidden !important' }}
                            expandIcon={({ isActive }) => {
                              if (isActive === true) {
                                return (
                                  <Row>
                                    <Button type='primary'>
                                      Show All Meetings
                                    </Button>
                                  </Row>
                                )
                              } else {
                                return <CaretRightOutlined />
                              }
                            }}
                          >
                            <Panel
                              header={`Meeting Type:${' '}${x.meetingType
                                }${' '}Meeting Date:${' '}${dayjs(
                                  x.meetingDate
                                ).format('MM-DD-YYYY')}
                        `}
                              key={`${x.id}`}
                              style={
                                `${x.id}` !== activePanel && activePanel !== ''
                                  ? { display: 'none' }
                                  : { display: 'block' }
                              }
                            >
                              <div>
                                <HistoryHelper meetingData={x} />
                              </div>
                            </Panel>
                          </Collapse>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )
          })}
        </>
      ) : (
        <>Loading History...</>
      )}
    </>
  )
}

export default FileHistory
