import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORMTAMES1_VERSION } from '../../../../operations/queries/forms/tames1'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Space,
  Typography
} from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORMTAMES1 } from '../../../../operations/mutations/forms/tames1'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import LookupSelect from '../LookupSelect'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    const teamMemberNameAndTitle = formFieldValues.teamMemberNameAndTitle.map(
      ({ __typename, ...props }) => props
    )
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId,
      teamMemberName: teamMemberNameAndTitle.map(x => x.name),
      teamMemberTitle: teamMemberNameAndTitle.map(x => x.title),
      teamMemberNameAndTitle: teamMemberNameAndTitle
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    console.log(fieldName, fieldValue)
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
      case 'teamFormationDate':
      case 'teamInitialMeetingDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      case 'teamMemberNameAndTitle':
        update[fieldName] = JSON.parse(fieldValue)
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentFormTames1'
            name='studentFormTames1'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              BUILD TEAM
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
            </Card>

            <Card
              title='Details of Meeting'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Team Formation Date'
                name='teamFormationDate'
                {...validationProps('teamFormationDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.teamFormationDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>
              <Form.Item
                label='Team Initial Meeting Date'
                name='teamInitialMeetingDate'
                {...validationProps('teamInitialMeetingDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.teamInitialMeetingDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>
            </Card>

            <Card
              style={{
                marginBottom: 30
              }}
              title='Team Member Name(s)'
            >
              <Input.Group
                onFocus={() => {
                  handleEditorEvents.onFocus('teamMemberNameAndTitle')
                }}
                onBlur={() => {
                  handleEditorEvents.onBlur(
                    'teamMemberNameAndTitle',
                    JSON.stringify(form.getFieldValue('teamMemberNameAndTitle'))
                  )
                }}
              >
                <Form.List
                  name='teamMemberNameAndTitle'
                  rules={[
                    {
                      validator: async (_, teamMemberName) => {
                        if (!teamMemberName || teamMemberName.length < 1) {
                          return Promise.reject(
                            new Error(
                              'Team must have at at least 1 Team Member'
                            )
                          )
                        }
                      }
                    }
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item
                          required={false}
                          key={field.key}
                          label='Name & Title'
                        >
                          <Space align='start'>
                            <Form.Item
                              name={[field.name, 'name']}
                              fieldKey={[field.fieldKey, 'name']}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message:
                                    "Input team member's name or delete this field."
                                }
                              ]}
                            >
                              <Input
                                placeholder='Team member name'
                                size='large'
                                disabled={
                                  formDisabledState.teamMemberNameAndTitle
                                }
                              />
                            </Form.Item>
                            <LookupSelect
                              name={[field.name, 'title']}
                              fieldKey={[field.fieldKey, 'title']}
                              type='Position'
                              placeholder='Team member title'
                              singleMode
                              style={{
                                minWidth: '240px'
                              }}
                              disabled={
                                formDisabledState.teamMemberNameAndTitle
                              }
                            />
                            {fields.length > 0 ? (
                              <MinusCircleOutlined
                                //onClick={() => remove(field.name)}
                                danger
                                disabled={
                                  formDisabledState.teamMemberNameAndTitle
                                }
                                onClick={() => {
                                  remove(field.name)
                                  handleEditorEvents.onBlur(
                                    'teamMemberNameAndTitle',
                                    JSON.stringify(
                                      form.getFieldValue(
                                        'teamMemberNameAndTitle'
                                      )
                                    )
                                  )
                                }}
                              />
                            ) : null}
                          </Space>
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type='dashed'
                          //onClick={() => add()}
                          onClick={() =>
                            add({
                              name: '',
                              title: ''
                            })
                          }
                          style={{ width: '98%' }}
                          icon={<PlusOutlined />}
                        >
                          Add Team Member
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Input.Group>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORMTAMES1}
              extractDataProp='printStudentFormTames1'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired
}

const StudentFormTames1 = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORMTAMES1_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentFormTames1: form } = versionInfo
    const { student } = studentInfo
    const { dateOfBirth } = student
    const {
      formDate,
      studentFullName,
      studentDob,
      teamFormationDate,
      teamInitialMeetingDate,
      teamMemberName,
      teamMemberTitle
    } = form

    const teamMemberNameAndTitle = []

    // Map data
    if (
      teamMemberName &&
      teamMemberTitle &&
      teamMemberName.length === teamMemberTitle.length
    ) {
      teamMemberName.forEach((name, index) => {
        teamMemberNameAndTitle.push({ name, title: teamMemberTitle[index] })
      })
    }

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      teamFormationDate: teamFormationDate
        ? dayjs(teamFormationDate)
        : dayjs(Date.now()),
      teamInitialMeetingDate: teamInitialMeetingDate
        ? dayjs(teamInitialMeetingDate)
        : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? dayjs(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
          ? dayjs(dateOfBirth).format('YYYY-MM-DD')
          : dayjs(Date.now()).format('YYYY-MM-DD'),
      teamMemberNameAndTitle
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentFormTames1
