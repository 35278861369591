import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454C_VERSION } from '../../../../operations/queries/forms/3454C'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Radio, Typography } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentFormActions from '../StudentFormActions'
import FormItem from 'antd/lib/form/FormItem'
import { PRINT_FORM3454C } from '../../../../operations/mutations/forms/3454C'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)

  const [
    districtImplementedScientificProcess,
    setDistrictImplementedScientificProcess
  ] = useState(data?.districtImplementedScientificProcess)
  const [
    districtImplementedServiceDiscrepancyModel,
    setDistrictImplementedServiceDiscrepancyModel
  ] = useState(data?.districtImplementedServiceDiscrepancyModel)
  const [severeDiscrepancyExists, setSevereDiscrepancyExists] = useState(
    data?.severeDiscrepancyExists
  )

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,

          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454C'
            name='studentForm3454C'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              DOCUMENTATION OF INTERVENTION/EVALUATION RESULTS (SPECIFIC
              LEARNING DISABILITY)
            </Title>
            <Alert
              style={{ marginBottom: '24px' }}
              message="As part of the evaluation process, document relevant behavior noted during observation in the child's age-appropriate learning
                environment, including the general education classroom setting for school-age children and the relationship of that behavior to the 
                child's academic functioning and educationally relevant medical findings, if any."
              type='info'
            />
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>
            <Card
              title='CRITERIA'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                style={{ marginBottom: '24px' }}
                message='Please indicate which criteria was used in the evaluation process.'
                type='info'
              />
              <Card
                title='Criteria A'
                style={{
                  marginBottom: 30
                }}
              >
                <Title level={5}>
                  The district used scientific, research-based interventions or
                  multi-tiered systems of support.
                </Title>

                <FormItem
                  label='The district implemented the use of a process that determines how the child responds to scientific, research-based interventions or 
                  multi-tiered systems of support as part of the evaluation procedure.'
                  name='districtImplementedScientificProcess'
                  {...validationProps('districtImplementedScientificProcess')}
                  style={{ marginBottom: '0' }}
                >
                  <Radio.Group
                    onChange={e => {
                      setDistrictImplementedScientificProcess(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={
                      formDisabledState.districtImplementedScientificProcess
                    }
                    name='districtImplementedScientificProcess'
                  >
                    <Radio.Button value='yes'>Yes</Radio.Button>
                    <Radio.Button value='no'>No</Radio.Button>
                  </Radio.Group>
                </FormItem>
              </Card>
              <Card
                title='Criteria B (Optional)'
                style={{
                  marginBottom: 30
                }}
              >
                <Title level={5}>
                  The district chose to use an IQ-achievement discrepancy model
                  in addition to using Criteria A.
                </Title>
                <FormItem
                  label='The district implemented the use of a severe discrepancy model between intellectual ability and achievement for 
                  determining whether a child as a specific learning disability.'
                  name='districtImplementedServiceDiscrepancyModel'
                  {...validationProps(
                    'districtImplementedServiceDiscrepancyModel'
                  )}
                  style={{ marginBottom: '0' }}
                >
                  <Radio.Group
                    onChange={e => {
                      setDistrictImplementedServiceDiscrepancyModel(
                        e.target.value
                      )
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={
                      formDisabledState.districtImplementedServiceDiscrepancyModel
                    }
                    name='districtImplementedServiceDiscrepancyModel'
                  >
                    <Radio.Button value='yes'>Yes</Radio.Button>
                    <Radio.Button value='no'>No</Radio.Button>
                  </Radio.Group>
                </FormItem>
                <FormItem
                  label='Does severe discrepancy exist between achievement and ability that is not correctable without special education and
                  related services?  (Please refer to evidence in Documentation of Evaluation Results.)'
                  name='severeDiscrepancyExists'
                  {...validationProps('severeDiscrepancyExists')}
                  style={{ marginBottom: '0' }}
                >
                  <Radio.Group
                    onChange={e => {
                      setSevereDiscrepancyExists(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={formDisabledState.severeDiscrepancyExists}
                    name='severeDiscrepancyExists'
                  >
                    <Radio.Button value='n/a'>N/A</Radio.Button>
                    <Radio.Button value='no'>No</Radio.Button>
                    <Radio.Button value='yes'>Yes</Radio.Button>
                  </Radio.Group>
                </FormItem>
                {severeDiscrepancyExists === 'yes' && (
                  <Form.Item
                    label='If "Yes," in which area(s)?'
                    name='severeDiscrepancyAreas'
                    {...validationProps('severeDiscrepancyAreas')}
                  >
                    <Editor
                      formField='severeDiscrepancyAreas'
                      disabled={formDisabledState.severeDiscrepancyAreas}
                      {...handleEditorEvents}
                    />
                  </Form.Item>
                )}
              </Card>
            </Card>

            <Card
              title='DOCUMENTATION OF INTERVENTION/EVALUATION RESULTS'
              style={{
                marginBottom: 30
              }}
            >
              <Card
                title='PROBLEM IDENTIFICATION / STATEMENT OF PROBLEM'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Using baseline data, please provide an initial  discrepancy statement for each academic area of concern.  Attach evidence.'
                  name='problemDescription'
                  {...validationProps('problemDescription')}
                >
                  <Editor
                    formField='problemDescription'
                    disabled={formDisabledState.problemDescription}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>

              <Card
                title='PROBLEM ANALYSIS / STRENGTHS AND WEAKNESSES'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Describe student’s skill strengths and weaknesses in the identified area(s) of concern within the relevant domains. Attach evidence, including evidence
                  of skills deficit versus performance deficit.'
                  name='problemAnalysis'
                  {...validationProps('problemAnalysis')}
                >
                  <Editor
                    formField='problemAnalysis'
                    disabled={formDisabledState.problemAnalysis}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>

              <Card
                title='PLAN DEVELOPMENT / PROBLEM-SOLVING METHOD'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label="Describe the problem-solving method that was used to define the problem, analyze the problem using data to determine why there 
                  is a discrepancy between what is expected and what is occurring, establish one or more student performance goals, develop in intervention
                  plan to address the performance goals, and delineate how the student's progress will be monitored and how implementation integrity
                  will be ensured.  Attach plan/evidence."
                  name='problemSolving'
                  {...validationProps('problemSolving')}
                >
                  <Editor
                    formField='problemSolving'
                    disabled={formDisabledState.problemSolving}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>

              <Card
                title='PLAN DEVELOPMENT / INTERVENTION(S)'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label="Describe in detail the interventions that were put in place to address the student's performance goals.  Attach evidence."
                  name='interventions'
                  {...validationProps('interventions')}
                >
                  <Editor
                    formField='interventions'
                    disabled={formDisabledState.interventions}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>

              <Card
                title='PLAN EVALUATION / EDUCATIONAL PROGRESS'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Provide documentation of student progress over time as a result of the intervention. Attach evidence/graphs.'
                  name='educationalProgress'
                  {...validationProps('educationalProgress')}
                >
                  <Editor
                    formField='educationalProgress'
                    disabled={formDisabledState.educationalProgress}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>

              <Card
                title='PLAN EVALUATION / DISCREPANCY'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='State the current performance discrepancy after intervention (i.e., the difference between a student’s level of performance compared to the performance
                  of peers or scientifically based standards of expected performance. Attach evidence.'
                  name='discrepancy'
                  {...validationProps('discrepancy')}
                >
                  <Editor
                    formField='discrepancy'
                    disabled={formDisabledState.discrepancy}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>

              {/* "Plan Evaluation/Instructional Needs" and "Additional Information Necessary For Decision-Making" removed in ISBE version 1/22.
              <Card
                title="PLAN EVALUATION / INSTRUCTIONAL NEEDS"
                style={{
                  marginBottom: 30,
                }}
              >
                <Form.Item
                  label="Summarize the student’s needs in the areas of curriculum, instruction, and environment. Include a statement of whether the student’s needs in terms
                  of materials, planning, and personnel required for intervention implementation are significantly different from those of general education peers. Attach
                  evidence."
                  name="instructionalNeeds"
                  {...validationProps("instructionalNeeds")}
                >
                  <Editor
                    formField="instructionalNeeds"
                    disabled={formDisabledState.instructionalNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>

              <Card
                title="ADDITIONAL INFORMATION NECESSARY FOR DECISION-MAKING (INCLUDE AS APPROPRIATE)"
                style={{
                  marginBottom: 30,
                }}
              >
                <Form.Item
                  label="Report any educationally relevant information necessary for decision-making, including information regarding eligibility exclusionary and inclusionary
                  criteria. Attach evidence."
                  name="additionalInformation"
                  {...validationProps("additionalInformation")}
                >
                  <Editor
                    formField="additionalInformation"
                    disabled={formDisabledState.additionalInformation}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              </Card>
              */}
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454C}
              extractDataProp='printStudentForm3454C'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454C = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454C_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454C: form } = versionInfo
    const { student } = studentInfo
    const { formDate, studentFullName } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454C
