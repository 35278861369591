import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454H_VERSION } from '../../../../operations/queries/forms/3454H'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Button, Card, DatePicker, Form, Input, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import FormItem from 'antd/lib/form/FormItem'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454H } from '../../../../operations/mutations/forms/3454H'
import Editor from '../../../elements/common/Editor'

const { Paragraph, Text } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    const coursesOfStudy = formFieldValues.coursesOfStudy
      ? formFieldValues.coursesOfStudy.map(({ __typename, ...props }) => props)
      : []

    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId,
      coursesOfStudy: coursesOfStudy
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
      case 'employmentDateConducted':
      case 'educationDateConducted':
      case 'trainingDateConducted':
      case 'livingSkillsDateConducted':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      case 'coursesOfStudy':
        update[fieldName] = JSON.parse(fieldValue)
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454H'
            name='studentForm3454H'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              SECONDARY TRANSITION
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>

            <Card
              title='Age-Appropriate Transition Assessments'
              style={{
                marginBottom: 30
              }}
            >
              <Card
                title='Employment'
                style={{
                  marginBottom: 30
                }}
              >
                <FormItem
                  label='Assessment Type'
                  name='employmentAssessmentType'
                  validateStatus={
                    itemErrors.employmentAssessmentType ? 'error' : ''
                  }
                  help={itemErrors.employmentAssessmentType}
                  {...validationProps('employmentAssessmentType')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.employmentAssessmentType}
                  />
                </FormItem>
                <FormItem
                  label='Responsible Agency/Person'
                  name='employmentResponsibleAgency'
                  validateStatus={
                    itemErrors.employmentResponsibleAgency ? 'error' : ''
                  }
                  help={itemErrors.employmentResponsibleAgency}
                  {...validationProps('employmentResponsibleAgency')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.employmentResponsibleAgency}
                  />
                </FormItem>
                <FormItem
                  label='Date Conducted'
                  name='employmentDateConducted'
                  validateStatus={
                    itemErrors.employmentDateConducted ? 'error' : ''
                  }
                  help={itemErrors.employmentDateConducted}
                  {...validationProps('employmentDateConducted')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={formDisabledState.employmentDateConducted}
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
              </Card>

              <Card
                title='Education'
                style={{
                  marginBottom: 30
                }}
              >
                <FormItem
                  label='Assessment Type'
                  name='educationAssessmentType'
                  validateStatus={
                    itemErrors.educationAssessmentType ? 'error' : ''
                  }
                  help={itemErrors.educationAssessmentType}
                  {...validationProps('educationAssessmentType')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.educationAssessmentType}
                  />
                </FormItem>
                <FormItem
                  label='Responsible Agency/Person'
                  name='educationResponsibleAgency'
                  validateStatus={
                    itemErrors.educationResponsibleAgency ? 'error' : ''
                  }
                  help={itemErrors.educationResponsibleAgency}
                  {...validationProps('educationResponsibleAgency')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.educationResponsibleAgency}
                  />
                </FormItem>
                <FormItem
                  label='Date Conducted'
                  name='educationDateConducted'
                  validateStatus={
                    itemErrors.educationDateConducted ? 'error' : ''
                  }
                  help={itemErrors.educationDateConducted}
                  {...validationProps('educationDateConducted')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={formDisabledState.educationDateConducted}
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
              </Card>

              <Card
                title='Training'
                style={{
                  marginBottom: 30
                }}
              >
                <FormItem
                  label='Assessment Type'
                  name='trainingAssessmentType'
                  validateStatus={
                    itemErrors.trainingAssessmentType ? 'error' : ''
                  }
                  help={itemErrors.trainingAssessmentType}
                  {...validationProps('trainingAssessmentType')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.trainingAssessmentType}
                  />
                </FormItem>
                <FormItem
                  label='Responsible Agency/Person'
                  name='trainingResponsibleAgency'
                  validateStatus={
                    itemErrors.trainingResponsibleAgency ? 'error' : ''
                  }
                  help={itemErrors.trainingResponsibleAgency}
                  {...validationProps('trainingResponsibleAgency')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.trainingResponsibleAgency}
                  />
                </FormItem>
                <FormItem
                  label='Date Conducted'
                  name='trainingDateConducted'
                  validateStatus={
                    itemErrors.trainingDateConducted ? 'error' : ''
                  }
                  help={itemErrors.trainingDateConducted}
                  {...validationProps('trainingDateConducted')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={formDisabledState.trainingDateConducted}
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
              </Card>

              <Card
                title='Independent living skills'
                style={{
                  marginBottom: 30
                }}
              >
                <FormItem
                  label='Assessment Type'
                  name='livingSkillsAssessmentType'
                  validateStatus={
                    itemErrors.livingSkillsAssessmentType ? 'error' : ''
                  }
                  help={itemErrors.livingSkillsAssessmentType}
                  {...validationProps('livingSkillsAssessmentType')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.livingSkillsAssessmentType}
                  />
                </FormItem>
                <FormItem
                  label='Responsible Agency/Person'
                  name='livingSkillsResponsibleAgency'
                  validateStatus={
                    itemErrors.livingSkillsResponsibleAgency ? 'error' : ''
                  }
                  help={itemErrors.livingSkillsResponsibleAgency}
                  {...validationProps('livingSkillsResponsibleAgency')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.livingSkillsResponsibleAgency}
                  />
                </FormItem>
                <FormItem
                  label='Date Conducted'
                  name='livingSkillsDateConducted'
                  validateStatus={
                    itemErrors.livingSkillsDateConducted ? 'error' : ''
                  }
                  help={itemErrors.livingSkillsDateConducted}
                  {...validationProps('livingSkillsDateConducted')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={formDisabledState.livingSkillsDateConducted}
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
              </Card>
            </Card>

            <Card
              title='Postsecondary Outcomes (Address by Age 14 1/2)'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                style={{ marginBottom: '16px' }}
                message='Indicate and project the desired appropriate measurable post-secondary outcomes/goals as identified by the student, parent and IEP team. Goals are based upon age-appropriate transition assessments related to employment, education and/or training, and independent living skills.'
                type='info'
              />
              <Card
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Employment Outcomes/Goals (e.g., competitive, supported shelter, non-paid employment as a volunteer or training capacity, military)'
                  name='employmentOutcomes'
                  {...validationProps('employmentOutcomes')}
                >
                  <Editor
                    {...handleEditorEvents}
                    formField='employmentOutcomes'
                    disabled={formDisabledState.employmentOutcomes}
                  />
                </Form.Item>
              </Card>

              <Card
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Post-Secondary Education Outcomes/Goals (e.g., community college, 4-year university, technical/vocational/trade school)'
                  name='postSecondaryEducationOutcome'
                  {...validationProps('postSecondaryEducationOutcome')}
                >
                  <Editor
                    {...handleEditorEvents}
                    formField='postSecondaryEducationOutcome'
                    disabled={formDisabledState.postSecondaryEducationOutcome}
                  />
                </Form.Item>
              </Card>

              <Card
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Post-Secondary Training Outcomes/Goals (e.g., vocational or career field, vocational training program, independent living skills training, apprenticeship, on-the-job training, Job Corps)'
                  name='postSecondaryTrainingOutcome'
                  {...validationProps('postSecondaryTrainingOutcome')}
                >
                  <Editor
                    {...handleEditorEvents}
                    formField='postSecondaryTrainingOutcome'
                    disabled={formDisabledState.postSecondaryTrainingOutcome}
                  />
                </Form.Item>
              </Card>

              <Card
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Independent Living Outcomes/Goals (e.g., independent living, health/safety, self-advocacy/future planning, transportation/mobility, social relationships, recreation/leisure, financial/income needs)'
                  name='independentLivingOutcome'
                  {...validationProps('independentLivingOutcome')}
                >
                  <Editor
                    {...handleEditorEvents}
                    formField='independentLivingOutcome'
                    disabled={formDisabledState.independentLivingOutcome}
                  />
                </Form.Item>
              </Card>
            </Card>

            <Card
              title='COURSE OF STUDY (address by age 14 1/2)'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                style={{ marginBottom: '16px' }}
                message='Identify a course of study that is a long-range educational plan or multi-year description of the educational program that directly relates to the student’s anticipated post-school goals, preferences and interests as described above.'
                type='info'
              />
              <Input.Group
                onFocus={() => {
                  handleEditorEvents.onFocus('coursesOfStudy')
                }}
                onBlur={() => {
                  handleEditorEvents.onBlur(
                    'coursesOfStudy',
                    JSON.stringify(form.getFieldValue('coursesOfStudy'))
                  )
                }}
              >
                <Form.List
                  name='coursesOfStudy'
                  {...validationProps('coursesOfStudy')}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <Card
                            style={{
                              marginBottom: '30px',
                              borderStyle: 'dashed'
                            }}
                          >
                            <Form.Item
                              {...field}
                              label='Year 1'
                              name={[field.name, 'coursesOfStudyYear1']}
                              key={[field.fieldKey, 'coursesOfStudyYear1']}
                            >
                              <Input
                                disabled={formDisabledState.coursesOfStudy}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label='Year 2'
                              name={[field.name, 'coursesOfStudyYear2']}
                              key={[field.fieldKey, 'coursesOfStudyYear2']}
                            >
                              <Input
                                disabled={formDisabledState.coursesOfStudy}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label='Year 3'
                              name={[field.name, 'coursesOfStudyYear3']}
                              key={[field.fieldKey, 'coursesOfStudyYear3']}
                            >
                              <Input
                                disabled={formDisabledState.coursesOfStudy}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label='Year 4'
                              name={[field.name, 'coursesOfStudyYear4']}
                              key={[field.fieldKey, 'coursesOfStudyYear4']}
                            >
                              <Input
                                disabled={formDisabledState.coursesOfStudy}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label='Extended'
                              name={[field.name, 'coursesOfStudyExtended']}
                              key={[field.fieldKey, 'coursesOfStudyExtended']}
                            >
                              <Input
                                disabled={formDisabledState.coursesOfStudy}
                              />
                            </Form.Item>
                            <Button
                              danger
                              disabled={formDisabledState.coursesOfStudy}
                              onClick={() => {
                                remove(field.name)
                                handleEditorEvents.onBlur(
                                  'coursesOfStudy',
                                  JSON.stringify(
                                    form.getFieldValue('coursesOfStudy')
                                  )
                                )
                              }}
                            >
                              Remove Course of Study
                            </Button>
                          </Card>
                        </div>
                      ))}

                      <Form.Item>
                        {
                          // Default value below prevents Quill from breaking the render
                        }
                        <Button
                          disabled={formDisabledState.coursesOfStudy}
                          type='dashed'
                          onClick={() =>
                            add({
                              coursesOfStudyYear1: '',
                              coursesOfStudyYear2: '',
                              coursesOfStudyYear3: '',
                              coursesOfStudyYear4: '',
                              coursesOfStudyExtended: ''
                            })
                          }
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Course of Study
                        </Button>
                      </Form.Item>
                      <Text type='warning'>
                        {validationProps('coursesOfStudy').help}
                      </Text>
                    </>
                  )}
                </Form.List>
              </Input.Group>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454H}
              extractDataProp='printStudentForm3454H'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454H = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454H_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454H: form } = versionInfo
    const { student } = studentInfo
    const {
      formDate,
      studentFullName,
      employmentDateConducted,
      educationDateConducted,
      trainingDateConducted,
      livingSkillsDateConducted
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      employmentDateConducted: employmentDateConducted
        ? dayjs(employmentDateConducted)
        : '',
      educationDateConducted: educationDateConducted
        ? dayjs(educationDateConducted)
        : '',
      trainingDateConducted: trainingDateConducted
        ? dayjs(trainingDateConducted)
        : '',
      livingSkillsDateConducted: livingSkillsDateConducted
        ? dayjs(livingSkillsDateConducted)
        : '',
      studentFullName: studentFullName || student.fullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454H
