import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useQuery } from '@apollo/client'
import Editor from '../../../elements/common/Editor'

// ant design
import {
  Form,
  DatePicker,
  Radio,
  Alert,
  Typography,
  Card,
  Checkbox,
  Input
} from 'antd'

import FormContainer from '../../../FormContainer'
import FormItem from 'antd/lib/form/FormItem'
import Title from 'antd/lib/typography/Title'

import { PRINT_FORM504B } from '../../../../operations/mutations/forms/504B'
import { QUERY_FORM504B_VERSION } from '../../../../operations/queries/forms/504B'
import { QUERY_STUDENT_IEP_SIS_DATA } from '../../../../operations/queries/forms/SIS'
import { QUERY_STUDENT_MEETING_FORMS } from '../../../../operations/queries/meeting'

import FormCollaboration from '../../../FormCollaboration'

import { IepFormContext } from '../../../../utils/iepFormProvider'
import StudentFormActions from '../StudentFormActions'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentGenderSIS from '../sis/StudentGenderSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'
import StudentAddressSIS from '../sis/StudentAddressSIS'
import StudentCitySIS from '../sis/StudentCitySIS'
import StudentStateSIS from '../sis/StudentStateSIS'
import StudentZipSIS from '../sis/StudentZipSIS'

import StudentCountrySIS from '../sis/StudentCountrySIS'
import StudentEthnicitySIS from '../sis/StudentEthnicitySIS'
import StudentGradeSIS from '../sis/StudentGradeSIS'
import StudentCodeSIS from '../sis/StudentCodeSIS'
import StudentMedicaidNumberSIS from '../sis/StudentMedicaidNumberSIS'
import StudentLanguageSIS from '../sis/StudentLanguageSIS'

import StudentParent1AddressSIS from '../sis/StudentParent1AddressSIS'
import StudentParent1CitySIS from '../sis/StudentParent1CitySIS'
import StudentParent1StateSIS from '../sis/StudentParent1StateSIS'
import StudentParent1CountrySIS from '../sis/StudentParent1CountrySIS'
import StudentParent1ZipSIS from '../sis/StudentParent1ZipSIS'
import StudentParent1PhoneSIS from '../sis/StudentParent1PhoneSIS'
import StudentParent1EmailSIS from '../sis/StudentParent1EmailSIS'
import StudentParent1LanguageSIS from '../sis/StudentParent1LanguageSIS'

import StudentParent2AddressSIS from '../sis/StudentParent2AddressSIS'
import StudentParent2CitySIS from '../sis/StudentParent2CitySIS'
import StudentParent2StateSIS from '../sis/StudentParent2StateSIS'
import StudentParent2CountrySIS from '../sis/StudentParent2CountrySIS'
import StudentParent2ZipSIS from '../sis/StudentParent2ZipSIS'
import StudentParent2PhoneSIS from '../sis/StudentParent2PhoneSIS'
import StudentParent2EmailSIS from '../sis/StudentParent2EmailSIS'
import StudentParent2LanguageSIS from '../sis/StudentParent2LanguageSIS'

import MeetingSISInput from '../sis/MeetingSISInput'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId,
  meetingType
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    console.log(formFieldValues)
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
      case 'nextReviewDate':
      case 'highSchoolGraduationDate':
      case 'dateParentProvidedParentsRights':
        update[fieldName] = dayjs(fieldValue)
        break
      case 'educationSurrogateParent1':
      case 'educationSurrogateParent2':
      case 'placement':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const meetingAttendees = [
    {
      label: 'Parent 1',
      name: 'meetingAttendedParent1'
    },
    {
      label: 'Parent 2',
      name: 'meetingAttendedParent2'
    },
    {
      label: 'Student',
      name: 'meetingAttendedStudent'
    },
    {
      label: 'LEA Representative',
      name: 'meetingAttendedLEARepresentative'
    },
    {
      label: 'General Education Teacher',
      name: 'meetingAttendedGeneralEducationTeacher'
    },
    {
      label: 'Special Education Teacher',
      name: 'meetingAttendedSpecialEducationTeacher'
    },
    {
      label: 'School Psychologist',
      name: 'meetingAttendedSchoolPsychologist'
    },
    {
      label: 'Social Worker',
      name: 'meetingAttendedSchoolSocialWorker'
    },
    {
      label: 'Speech/Language Pathologist',
      name: 'meetingAttendedSpeechLanguagePathologist'
    },
    {
      label: 'Bilingual Specialist',
      name: 'meetingAttendedBilingualSpecialist'
    },
    {
      label: 'Interpreter',
      name: 'meetingAttendedInterpreter'
    },
    {
      label: 'School Nurse',
      name: 'meetingAttendedSchoolNurse'
    },
    {
      label: 'Other Attendee',
      name: 'meetingAttendedOther'
    }
  ]

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          handleActionEvents,

          formDisabledState,
          canSave,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457B'
            name='studentForm3457B'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              SECTION 504 ELIGIBILITY CONFERENCE SUMMARY
            </Title>

            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
              />
            </Form.Item>
            <Form.Item
              label='Next Review Date'
              name='nextReviewDate'
              {...validationProps('nextReviewDate')}
            >
              <DatePicker
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
                {...handleFocusEvents}
                disabled={formDisabledState.nextReviewDate}
              />
            </Form.Item>

            <Card
              title='Student Identification Information'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentAddressSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.address}
                error={itemErrors.address}
              />
              <StudentCitySIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.city}
                error={itemErrors.city}
              />
              <StudentStateSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.state}
                error={itemErrors.state}
              />
              <StudentZipSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.zip}
                error={itemErrors.zip}
              />
              <StudentCountrySIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.country}
                error={itemErrors.country}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentCodeSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentCode}
                error={itemErrors.studentCode}
              />
              <StudentLanguageSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentLanguage}
                error={itemErrors.studentLanguage}
              />
              <StudentGradeSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentGrade}
                error={itemErrors.studentGrade}
              />
              <StudentGenderSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentGender}
                error={itemErrors.studentGender}
              />
              <StudentEthnicitySIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentEthnicity}
                error={itemErrors.studentEthnicity}
              />
              <MeetingSISInput
                name='highSchoolGraduationDate'
                label='Student High School Completion date'
                type='date'
                disabled={formDisabledState.highSchoolGraduationDate}
              />
              <Form.Item
                label='Placement
                (YES OR NO)'
                name='placement'
                {...validationProps('placement')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.placement}
                  name='placement'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <FormItem
                label='Disability(s)'
                name='disabilities'
                {...validationProps('disabilities')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.disabilities}
                />
              </FormItem>
              <StudentMedicaidNumberSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.medicaidNumber}
                error={itemErrors.medicaidNumber}
              />
              <MeetingSISInput
                name='residentDistrict'
                label='Resident District'
                type='input'
                disabled={formDisabledState.residentDistrict}
              />
              <MeetingSISInput
                name='residentSchool'
                label='Resident School'
                type='input'
                disabled={formDisabledState.residentSchool}
              />
            </Card>

            <Card
              title='Placement'
              style={{
                marginBottom: 30
              }}
            >
              <MeetingSISInput
                name='servingDistrict'
                label='Serving District'
                type='input'
                disabled={formDisabledState.servingDistrict}
              />
              <MeetingSISInput
                name='servingSchool'
                label='Serving School'
                type='input'
                disabled={formDisabledState.servingSchool}
              />
            </Card>

            <Card
              title='Parent Information'
              style={{
                marginBottom: 30
              }}
            >
              <Card
                title='Parent 1  Information'
                style={{
                  marginBottom: 30
                }}
              >
                <StudentParent1SIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1}
                  error={itemErrors.parent1}
                //disabled={false}
                />
                <Form.Item
                  label='Educational surrogate parent (1)'
                  name='educationSurrogateParent1'
                  {...validationProps('educationSurrogateParent1')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.educationSurrogateParent1}
                    name='educationSurrogateParent1'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <StudentParent1AddressSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1Address}
                  error={itemErrors.parent1Address}
                  disabled={formDisabledState.parent1Address}
                //disabled={false}
                />
                <StudentParent1CitySIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1City}
                  error={itemErrors.parent1City}
                //disabled={false}
                />
                <StudentParent1StateSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1State}
                  error={itemErrors.parent1State}
                //disabled={false}
                />
                <StudentParent1ZipSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1Zip}
                  error={itemErrors.parent1Zip}
                //disabled={false}
                />
                <StudentParent1CountrySIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1Country}
                  error={itemErrors.parent1Country}
                //disabled={false}
                />
                <StudentParent1EmailSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1Email}
                  error={itemErrors.parent1Email}
                //disabled={false}
                />
                <StudentParent1PhoneSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1PhoneNumber}
                  error={itemErrors.parent1PhoneNumber}
                //disabled={false}
                />
                <StudentParent1LanguageSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent1PrimaryLanguage}
                  error={itemErrors.parent1PrimaryLanguage}
                //disabled={false}
                />
              </Card>

              <Card
                title='Parent 2  Information'
                style={{
                  marginBottom: 30
                }}
              >
                <StudentParent2SIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2}
                  error={itemErrors.parent2}
                //disabled={false}
                />
                <Form.Item
                  label='Educational surrogate parent (2)'
                  name='educationSurrogateParent2'
                  {...validationProps('educationSurrogateParent2')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.educationSurrogateParent2}
                    name='educationSurrogateParent2'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <StudentParent2AddressSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2Address}
                  error={itemErrors.parent2Address}
                //disabled={false}
                />
                <StudentParent2CitySIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2City}
                  error={itemErrors.parent2City}
                //disabled={false}
                />
                <StudentParent2StateSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2State}
                  error={itemErrors.parent2State}
                //disabled={false}
                />
                <StudentParent2ZipSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2Zip}
                  error={itemErrors.parent2Zip}
                //disabled={false}
                />
                <StudentParent2CountrySIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2Country}
                  error={itemErrors.parent2Country}
                //disabled={false}
                />
                <StudentParent2EmailSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2Email}
                  error={itemErrors.parent2Email}
                //disabled={false}
                />
                <StudentParent2PhoneSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2PhoneNumber}
                  error={itemErrors.parent2PhoneNumber}
                //disabled={false}
                />
                <StudentParent2LanguageSIS
                  form={form}
                  sis={sis}
                  id={studentId}
                  initialValue={data.parent2PrimaryLanguage}
                  error={itemErrors.parent2PrimaryLanguage}
                //disabled={false}
                />
              </Card>
            </Card>

            <Card
              title="Parent's Rights"
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Date Explanation of Parents Rights provided to/reviewed with the parent(s)'
                name='dateParentProvidedParentsRights'
                {...validationProps('dateParentProvidedParentsRights')}
              >
                <DatePicker
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                  {...handleFocusEvents}
                  disabled={formDisabledState.dateParentProvidedParentsRights}
                />
              </Form.Item>
              <FormItem
                label='Parent/Guardian Initial'
                name='parentInitials'
                {...validationProps('parentInitials')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.parentInitials}
                />
              </FormItem>
            </Card>

            <Card
              title="Participant's Information"
              style={{
                marginBottom: 30
              }}
            >
              {meetingAttendees.map(({ label, name }, index) => (
                <FormItem
                  key={index}
                  label={label}
                  name={name}
                  validateStatus={itemErrors[name] ? 'error' : ''}
                  help={itemErrors[name]}
                  {...validationProps(name)}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState[name]}
                  />
                </FormItem>
              ))}

              <FormItem
                label='Other Attendee Description'
                name='meetingAttendedOtherText'
                {...validationProps('meetingAttendedOtherText')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.meetingAttendedOtherText}
                />
              </FormItem>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM504B}
              extractDataProp='printStudentForm504B'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm504B = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId, meetingType } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_IEP_SIS_DATA,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM504B_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}
    if (!studentInfo) return initialValues
    //if (!studentInfo || !versionInfo) return initialValues

    const { student } = studentInfo
    const { studentForm504B: form } = versionInfo ? versionInfo : {}

    //Load values from form version if they exist or student docs where applicable.
    initialValues = {
      ...form,
      formDate: dayjs(form?.formDate) || dayjs(Date.now()),
      nextReviewDate: dayjs(form?.nextReviewDate) || dayjs(Date.now()),

      studentFullName: form?.studentFullName || student?.fullName || '',
      address: form?.address || student?.address || '',
      city: form?.city || student?.city || '',
      state: form?.state || student?.state || '',
      zip: form?.zip || student?.zip || '',
      country: form?.country || student.country || '',
      studentDob: form?.studentDob
        ? dayjs(form?.studentDob)
        : dayjs(student?.studentdateOfBirth),
      studentCode: form?.studentCode || student?.studentCode || '',
      studentLanguage: form?.studentLanguage || student?.primaryLanguage || '',
      studentGrade: form?.studentGrade || student?.grade || '',
      studentGender: form?.studentGender || student?.gender || '',
      studentEthnicity: form?.studentEthnicity || student?.ethnicity || '',
      highSchoolGraduationDate:
        dayjs(form?.highSchoolGraduationDate) ||
        dayjs(student?.highSchoolGraduationDate),
      placement: form?.placement || false,
      disabilities: form?.disabilities || '',
      medicaidNumber: form?.medicaidNumber || student?.medicaidNumber || '',
      residentDistrict:
        form?.residentDistrict || student?.residentDistrict || '',
      residentSchool: form?.residentSchool || student?.residentSchool || '',
      servingDistrict: form?.servingDistrict || student?.servingDistrict || '',
      servingSchool: form?.servingSchool || student?.servingSchool || '',

      parent1: form?.parent1 || student?.parent1FullName || '',
      educationSurrogateParent1: form?.educationSurrogateParent1 || false,
      parent1Email: form?.parent1Email || student?.parent1Email || '',
      parent1PhoneNumber:
        form?.parent1PhoneNumber || student?.parent1PhoneNumber || '',
      parent1Address: form?.parent1Address || student?.parent1Address || '',
      parent1City: form?.parent1City || student?.parent1City || '',
      parent1State: form?.parent1State || student?.parent1State || '',
      parent1Zip: form?.parent1Zip || student?.parent1Zip || '',
      parent1Country: form?.parent1Country || student?.parent1Country || '',
      parent1PrimaryLanguage:
        form?.parent1PrimaryLanguage || student?.parent1PrimaryLanguage || '',
      parent2: form?.parent2 || student?.parent2FullName || '',
      educationSurrogateParent2: form?.educationSurrogateParent2 || false,
      parent2Email: form?.parent2Email || student?.parent2Email || '',
      parent2PhoneNumber:
        form?.parent2PhoneNumber || student?.parent2PhoneNumber || '',
      parent2Address: form?.parent2Address || student?.parent2Address || '',
      parent2City: form?.parent2City || student?.parent2City || '',
      parent2State: form?.parent2State || student?.parent2State || '',
      parent2Zip: form?.parent2Zip || student?.parent2Zip || '',
      parent2Country: form?.parent2Country || student?.parent2Country || '',
      parent2PrimaryLanguage:
        form?.parent2PrimaryLanguage || student?.parent2PrimaryLanguage || '',

      dateParentProvidedParentsRights:
        dayjs(form?.dateParentProvidedParentsRights) || dayjs(Date.now()),
      parentInitials: form?.parentInitials || '',

      meetingAttendedParent1: form?.meetingAttendedParent1 || '',
      meetingAttendedParent2: form?.meetingAttendedParent2 || '',
      meetingAttendedStudent: form?.meetingAttendedStudent || '',
      meetingAttendedLEARepresentative:
        form?.meetingAttendedLEARepresentative || '',
      meetingAttendedGeneralEducationTeacher:
        form?.meetingAttendedGeneralEducationTeacher || '',
      meetingAttendedSpecialEducationTeacher:
        form?.meetingAttendedSpecialEducationTeacher || '',
      meetingAttendedSchoolPsychologist:
        form?.meetingAttendedSchoolPsychologist || '',
      meetingAttendedSchoolSocialWorker:
        form?.meetingAttendedSchoolSocialWorker || '',
      meetingAttendedSpeechLanguagePathologist:
        form?.meetingAttendedSpeechLanguagePathologist || '',
      meetingAttendedBilingualSpecialist:
        form?.meetingAttendedBilingualSpecialist || '',
      meetingAttendedInterpreter: form?.meetingAttendedInterpreter || '',
      meetingAttendedSchoolNurse: form?.meetingAttendedSchoolNurse || '',
      meetingAttendedOther: form?.meetingAttendedOther || '',
      meetingAttendedOtherText: form?.meetingAttendedOtherText || ''
    }
    return initialValues
  }

  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
            meetingType={meetingType}
          />
        }
      />
    </FormErrorProvider>
  )
}

export default StudentForm504B
